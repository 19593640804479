import { useMutation } from "@apollo/client";
import { useModal } from "@ebay/nice-modal-react";
import { useEffect, useMemo, useState } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import DescriptionDetails from "components/DescriptionDetails";
import FlashContext from "components/FlashMessages/FlashContext";
import FormFieldViewText from "components/FormFields/ViewText";
import WarningBarItem from "components/warning/Item";
import WarningBar from "components/warning/WarningBar";
import Banner from "ds/components/Banner";
import Box from "ds/components/Box";
import ButtonNew from "ds/components/Button/New";
import DrawerBody from "ds/components/Drawer/Body";
import DrawerFooter from "ds/components/Drawer/Footer";
import DrawerFooterActions from "ds/components/Drawer/FooterActions";
import DrawerHeader from "ds/components/Drawer/Header";
import DrawerCancelButton from "ds/components/DrawerNew/CancelButton";
import DrawerCloseIcon from "ds/components/DrawerNew/CloseIcon";
import DrawerHeaderTitle from "ds/components/DrawerNew/HeaderTitle";
import DrawerSimple from "ds/components/DrawerNew/Simple";
import { createDrawer, createDrawerTrigger } from "ds/components/DrawerNew/utils";
import { useShowFullDescriptionWithBackArrow } from "ds/components/FullDescriptionDrawer/useShowFullDescriptionWithBackArrow";
import Tooltip from "ds/components/Tooltip";
import useTypedContext from "hooks/useTypedContext";
import {
  BillingTierFeature,
  Blueprint,
  BlueprintInput,
  BlueprintInputType,
  BlueprintParseResult,
  BlueprintStackCreation,
} from "types/generated";
import { getDrawerFormFix } from "utils/css";
import { hasSpaceManageAccess } from "utils/user";
import useTierFeature from "views/Account/hooks/useTierFeature";
import { AccountContext } from "views/AccountWrapper";

import { getBlueprintFeatureGatingMessage } from "../FeatureGate/Tooltip";
import { BlueprintActions } from "../FeatureGate/types";
import { BLUEPRINT_CREATE_STACK } from "../gql";
import TemplatePreviewFormField from "./FormField";
import TemplatePreviewSkeleton from "./Skeleton";
import styles from "./styles.module.css";
import { TemplatePreviewFormFields } from "./types";

type TemplatePreviewProps = {
  item: Blueprint;
  isPublished: boolean;
  onEditMetadata: (item: Blueprint) => void;
  validate?: () => Promise<void | BlueprintParseResult>;
};

const TemplatePreview = createDrawer(
  ({ item, validate, isPublished, onEditMetadata }: TemplatePreviewProps) => {
    const drawer = useModal();
    const onOpenFullDescription = useShowFullDescriptionWithBackArrow(item.description);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState<string[]>([]);
    const [inputs, setInputs] = useState<BlueprintInput[]>(item.inputs);

    const isFeatureActive = useTierFeature(BillingTierFeature.Blueprints);
    const hasErrors = errors.length > 0;
    const hasPreview = inputs.length > 0;

    const navigate = useNavigate();
    const { onError, reportSuccess } = useTypedContext(FlashContext);
    const { viewer } = useTypedContext(AccountContext);

    useEffect(() => {
      if (!validate) {
        return;
      }

      setLoading(true);
      validate()
        .then((result) => {
          setErrors(result?.errors || []);
          setInputs(result?.inputs || []);
        })
        .catch(onError)
        .finally(() => setLoading(false));
    }, [onError, validate]);

    const [createStack, { loading: stackCreateLoading }] = useMutation<{
      blueprintCreateStack: BlueprintStackCreation;
    }>(BLUEPRINT_CREATE_STACK, {
      onError,
    });

    const defaultValues = useMemo(
      () =>
        inputs.reduce((acc, input) => {
          if (input.type === BlueprintInputType.Boolean) {
            acc[input.id] = input.default === "true";
          } else {
            acc[input.id] = input.default;
          }
          return acc;
        }, {} as TemplatePreviewFormFields),
      [inputs]
    );

    const builderForm = useForm<TemplatePreviewFormFields>({
      defaultValues,
      mode: "onChange",
    });

    const {
      handleSubmit,
      reset,
      formState: { isDirty },
    } = builderForm;

    const onSubmit: SubmitHandler<TemplatePreviewFormFields> = (formData) => {
      createStack({
        variables: {
          id: item.id,
          input: {
            templateInputs: Object.entries(formData).map(([key, value]) => ({
              id: key,
              // FYI: this is a workaround for the backend validation
              value: value?.toString(),
            })),
          },
        },
      })
        .then(({ data }) => {
          if (data?.blueprintCreateStack) {
            reportSuccess({ message: `Stack is successfully created` });
            navigate(`/stack/${data.blueprintCreateStack.stackID}`);
          }
        })
        .catch(onError);
    };

    const handleEditMetadata = () => {
      drawer.hide();
      onEditMetadata(item);
    };

    useEffect(() => {
      // update default values when inputs change but only if the form is not dirty
      if (!isDirty) {
        reset(defaultValues);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultValues, isDirty]);

    const canManageBlueprint = viewer.admin || hasSpaceManageAccess(item.space.accessLevel);

    return (
      <DrawerSimple>
        <FormProvider {...builderForm}>
          <form onSubmit={handleSubmit(onSubmit)} {...getDrawerFormFix()}>
            <DrawerHeader justify="between">
              <DrawerHeaderTitle title={isPublished ? "Create a stack" : "Template preview"} />
              <DrawerCloseIcon />
            </DrawerHeader>
            <DrawerBody fullHeight shouldFocusOnEnter={!loading}>
              <Box direction="column" fullWidth>
                {isPublished && (
                  <div className={styles.blueprintInfoSection}>
                    <FormFieldViewText label="Blueprint name" value={item.name} />

                    <DescriptionDetails
                      label="Blueprint description"
                      description={item.description}
                      onOpenFullDescription={onOpenFullDescription}
                      {...(canManageBlueprint && { onAddDescription: handleEditMetadata })}
                    />
                  </div>
                )}

                {!isPublished && !hasPreview && !hasErrors && (
                  <Banner variant="info" margin="0 0 x-large 0">
                    No preview available. Please add some inputs to the template.
                  </Banner>
                )}

                {!isPublished && hasPreview && !hasErrors && (
                  <Banner variant="info" margin="0 0 x-large 0">
                    This is just a template preview, you will not be able to create a stack from it.
                  </Banner>
                )}

                {!isPublished && hasErrors && (
                  <WarningBar>
                    {errors.map((error, index) => (
                      <WarningBarItem key={index}>{error}</WarningBarItem>
                    ))}
                  </WarningBar>
                )}

                {loading && <TemplatePreviewSkeleton />}
                {!loading && !hasErrors && (
                  <Box direction="column" grow="1" fullWidth gap="x-large">
                    {inputs.map((input) => (
                      <TemplatePreviewFormField key={input.id} input={input} />
                    ))}
                  </Box>
                )}
              </Box>

              {!hasErrors && (
                <DrawerFooter>
                  <DrawerFooterActions>
                    <DrawerCancelButton />
                    &nbsp;&nbsp;
                    <Tooltip
                      active={!isPublished || !isFeatureActive}
                      widthMode="maxWidthSm"
                      on={(props) => (
                        <span {...props}>
                          <ButtonNew
                            variant="primary"
                            type="submit"
                            disabled={!isPublished || !isFeatureActive}
                            loading={stackCreateLoading}
                          >
                            Create stack
                          </ButtonNew>
                        </span>
                      )}
                    >
                      {!isPublished &&
                        "This is just a template preview, you will not be able to create a stack from it."}
                      {isPublished &&
                        !isFeatureActive &&
                        getBlueprintFeatureGatingMessage(BlueprintActions.CreateStack)}
                    </Tooltip>
                  </DrawerFooterActions>
                </DrawerFooter>
              )}
            </DrawerBody>
          </form>
        </FormProvider>
      </DrawerSimple>
    );
  }
);

export const showTemplatePreviewDrawer = createDrawerTrigger(TemplatePreview);
