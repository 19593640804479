import Box from "../../Box";
import LoadingIndicator from "../../LoadingIndicator";
import Typography from "../../Typography";

type SelectEmptyCollectionProps = {
  isLoading?: boolean;
};

const SelectEmptyCollection = ({ isLoading }: SelectEmptyCollectionProps) => (
  <Box padding="small">
    {isLoading ? (
      <Box fullWidth justify="center">
        <LoadingIndicator size="large" loading />
      </Box>
    ) : (
      <Typography tag="span" variant="p-body3">
        No options found.
      </Typography>
    )}
  </Box>
);

SelectEmptyCollection.displayName = "DS.Select.EmptyCollection";

export default SelectEmptyCollection;
