import {
  URL_FILTER_KEYS_KEY,
  URL_FILTER_TYPES_KEY,
  URL_FILTER_VALUES_KEY,
  URL_SEARCH_KEY,
  URL_SORT_DIRECTION,
  URL_SORT_KEY,
} from "constants/url_query_keys";
import { SearchSuggestionsFieldType } from "types/generated";
import { isLegacySpace, isRootSpace } from "utils/space";

export const makeFilterUrlParams = (spacePath: string) => {
  const urlParams = new URLSearchParams();
  // FYI: "space" is a hardcoded FilterKey
  urlParams.set(URL_FILTER_KEYS_KEY, "space");
  urlParams.set(URL_FILTER_TYPES_KEY, SearchSuggestionsFieldType.Hierarchy);
  urlParams.set(URL_FILTER_VALUES_KEY, btoa(encodeURIComponent(JSON.stringify([[spacePath]]))));

  return urlParams;
};

export const isPredefinedSpace = (id: string) => isRootSpace(id) || isLegacySpace(id);

export const createNavigationLink = (
  link: string,
  newUrlParams: URLSearchParams = new URLSearchParams()
) => {
  const currentUrlParams = new URLSearchParams(window.location.search);

  const currentSearch = currentUrlParams.get(URL_SEARCH_KEY);
  const newSearch = newUrlParams.get(URL_SEARCH_KEY);

  const currentSort = currentUrlParams.get(URL_SORT_KEY);
  const newSort = newUrlParams.get(URL_SORT_KEY);

  const currentSortDirection = currentUrlParams.get(URL_SORT_DIRECTION);
  const newSortDirection = newUrlParams.get(URL_SORT_DIRECTION);

  if (currentSearch && !newSearch) {
    newUrlParams.set(URL_SEARCH_KEY, currentSearch ?? "");
  }

  if (currentSort && !newSort) {
    newUrlParams.set(URL_SORT_KEY, currentSort ?? "");
  }

  if (currentSortDirection && !newSortDirection) {
    newUrlParams.set(URL_SORT_DIRECTION, currentSortDirection ?? "");
  }

  return `${link}${newUrlParams ? `?${newUrlParams}` : ""}`;
};
