import { ErrorCircleColored } from "components/icons/generated";
import SystemMessage from "components/SystemMessage";
import ButtonNew from "ds/components/Button/New";

const ErrorBoundaryFallback = () => (
  <SystemMessage
    title="Something went wrong"
    message="Please try to reload the page"
    icon={ErrorCircleColored}
  >
    <ButtonNew variant="primary" onPress={() => window.location.reload()} fullWidth>
      Reload
    </ButtonNew>
  </SystemMessage>
);

export default ErrorBoundaryFallback;
