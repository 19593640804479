import { ReactNode } from "react";

import ListBoxItem from "../ListBox/Item";

type ComboBoxItemProps = {
  textValue: string;
  children: ReactNode;
};

const ComboBoxInputValueItem = ({ children, textValue }: ComboBoxItemProps) => {
  return (
    <ListBoxItem id={textValue} textValue={textValue}>
      {children}
    </ListBoxItem>
  );
};

ComboBoxInputValueItem.displayName = "DS.ComboBox.InputValueItem";

export default ComboBoxInputValueItem;
