import { useEffect, useRef } from "react";

import ButtonIcon from "ds/components/ButtonIcon";
import ListEntitiesItem from "components/ListEntitiesItem";
import Box from "ds/components/Box";
import Typography from "ds/components/Typography";
import { SecurityKey } from "types/generated";
import TextEllipsis from "ds/components/TextEllipsis";
import { Trash } from "components/icons/generated";
import Timestamp from "components/time/Timestamp";

import { COLUMN_ORDER, COLUMN_ORDER_WITH_OWNER } from "./constants";

type SecurityKeysListItemProps = {
  item: SecurityKey;
  showOwner?: boolean;
  onDelete?: () => void;
  setRowHeight?: (size: number) => void;
};

const SecurityKeysListItem = ({
  item,
  onDelete,
  showOwner = false,
  setRowHeight,
}: SecurityKeysListItemProps) => {
  const rowRef = useRef<HTMLDivElement>(null);

  const handleRowHeight = () => {
    if (setRowHeight && rowRef.current) {
      setRowHeight(rowRef.current.getBoundingClientRect().height);
    }
  };

  // on every render, update the row height
  useEffect(handleRowHeight);

  return (
    <ListEntitiesItem
      direction="row"
      align="center"
      justify="between"
      grid
      gridTemplate={showOwner ? COLUMN_ORDER_WITH_OWNER : COLUMN_ORDER}
      gap="0 large"
      ref={rowRef}
      role="row"
    >
      {showOwner && (
        <Box role="cell" direction="row" align="center">
          <TextEllipsis tooltip={item.owner} tooltipWidthMode="maxWidthSm">
            {(props) => (
              <Typography {...props} tag="span" variant="p-t6">
                {item.owner}
              </Typography>
            )}
          </TextEllipsis>
        </Box>
      )}

      <Box role="rowheader" direction="row" align="center">
        <TextEllipsis tooltip={item.name} tooltipWidthMode="maxWidthSm">
          {(props) => (
            <Typography {...props} tag="span" variant="p-t6">
              {item.name}
            </Typography>
          )}
        </TextEllipsis>
      </Box>

      <Box role="cell" direction="row" align="center">
        <TextEllipsis tooltip={item.id} tooltipWidthMode="maxWidthSm">
          {(props) => (
            <Typography {...props} tag="span" variant="p-body2">
              {item.id}
            </Typography>
          )}
        </TextEllipsis>
      </Box>

      <Box role="cell" direction="row" align="center">
        <TextEllipsis
          tooltip={<Timestamp timestamp={item.createdAt} />}
          tooltipWidthMode="maxWidthSm"
        >
          {(props) => (
            <Typography {...props} tag="span" variant="p-body2">
              <Timestamp timestamp={item.createdAt} />
            </Typography>
          )}
        </TextEllipsis>
      </Box>

      {onDelete && (
        <Box role="cell" direction="row" align="center" justify="end">
          <ButtonIcon icon={Trash} onClick={onDelete} variant="ghostDanger">
            Delete
          </ButtonIcon>
        </Box>
      )}
    </ListEntitiesItem>
  );
};

export default SecurityKeysListItem;
