import { Pages } from "./types";

export const enum AnalyticsPageAuditTrail {
  Logs = "AuditTrailLogs",
  Configuration = "AuditTrailConfiguration",
}

export const pagesAuditTrail: Pages<AnalyticsPageAuditTrail> = {
  [AnalyticsPageAuditTrail.Logs]: ["Audit trail logs"],
  [AnalyticsPageAuditTrail.Configuration]: ["Audit trail configuration"],
};
