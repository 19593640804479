import { useEffect, useRef } from "react";

import { Space } from "components/icons/generated";
import ListEntitiesItem from "components/ListEntitiesItem";
import ListEntitiesItemLink from "components/ListEntitiesItem/Link";
import MetaInfoListItem from "components/MetaInfoList/Item";
import ProjectTypeBadge, { ProjectType } from "components/ProjectTypeBadge";
import Box from "ds/components/Box";
import { VcsIntegrationUser } from "types/generated";

import { COLUMN_GAP, COLUMN_ORDER } from "../constants";

type VCSIntegrationUsedByListItemProps = {
  item: VcsIntegrationUser;
  setRowHeight?: (size: number) => void;
};

const VCSIntegrationUsedByListItem = ({
  item,
  setRowHeight,
}: VCSIntegrationUsedByListItemProps) => {
  const rowRef = useRef<HTMLDivElement>(null);

  const handleRowHeight = () => {
    if (setRowHeight && rowRef.current) {
      setRowHeight(rowRef.current.getBoundingClientRect().height);
    }
  };

  // on every render, update the row height
  useEffect(handleRowHeight);

  return (
    <ListEntitiesItem
      direction="row"
      align="center"
      justify="between"
      grid
      gridTemplate={COLUMN_ORDER}
      gap={`0 ${COLUMN_GAP}`}
      ref={rowRef}
    >
      <Box direction="row" align="center">
        <ProjectTypeBadge projectType={item.isModule ? ProjectType.Module : ProjectType.Stack} />
      </Box>

      <ListEntitiesItemLink
        title={item.stackName}
        href={`/${item.isModule ? "module" : "stack"}/${item.stackId}`}
      />

      <MetaInfoListItem
        icon={Space}
        linkText={item.spaceDetails.name}
        href={`/spaces/${item.spaceDetails.id}`}
      />
    </ListEntitiesItem>
  );
};

export default VCSIntegrationUsedByListItem;
