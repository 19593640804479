import InfiniteLoader from "react-window-infinite-loader";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

import useTitle from "hooks/useTitle";
import useErrorHandle from "hooks/useErrorHandle";
import NotFoundPage from "components/error/NotFoundPage";
import PageLoading from "components/loading/PageLoading";
import ListEntitiesNew from "components/ListEntitiesNew";
import { VcsIntegration, VcsProvider } from "types/generated";
import { hasSpaceManageAccess } from "utils/user";
import Box from "ds/components/Box";

import VCSIntegrationsVirtualizedListItem from "./Item/Virtualized";
import VCSIntegrationsPageLayout from "./PageLayout";
import VCSIntegrationsFiltersLayout from "./FiltersLayout";
import useSearchVCSIntegrations from "./useSearchVCSIntegrations";
import VCSIntegrationsEmpty from "./Empty";
import { GITHUB_INTEGRATION_DETAILS_DRAWER_ID, ITEMS_LIMIT } from "./constants";
import { showVCSIntegrationDetailsDrawer } from "../../components/Details";
import { getEditIntegrationURL } from "../../helpers";
import { getVCSIntegrationId } from "./helpers";
import VCSIntegrationsBuiltInGitHubListItem from "./BuiltInGitHubListItem";
import VCSIntegrationsHasNoResults from "./HasNoResults";
import { VCSIntegrationsListContextProvider } from "./Context";
import { showVCSIntegrationsBuiltInGitHubDetailsDrawer } from "./BuiltInGitHubDetailsDrawer";

const VCSIntegrationsList = () => {
  const navigate = useNavigate();

  const {
    hasBuiltInGitHubIntegration,
    githubIntegration,
    integrations,
    isPageEmpty,
    isPageLoading,
    isPageNotFound,
    hasNextPage,
    error,
    stopPolling,
    loadMoreItems,
    hasNoResults,
  } = useSearchVCSIntegrations();

  const isItemLoaded = (value: number) => !hasNextPage || value < integrations.length;

  const handleOpenDetailsDrawer = (integration: VcsIntegration) => {
    const canManageFocusedVCSIntegration =
      integration && hasSpaceManageAccess(integration.space.accessLevel);

    showVCSIntegrationDetailsDrawer({
      id: getVCSIntegrationId(integration),
      item: integration,
      canEdit: !!canManageFocusedVCSIntegration,
    });
  };

  const handleIntegrationEdit = useCallback(
    (item: VcsIntegration) => {
      if (item.id) {
        navigate(getEditIntegrationURL(item.provider, item.id));
      }
    },
    [navigate]
  );

  const handleBuiltInGitHubIntegrationEdit = useCallback(() => {
    navigate(getEditIntegrationURL(VcsProvider.Github, "builtin"));
  }, [navigate]);

  const handleBuiltInGitHubOpenDetailsDrawer = useCallback(() => {
    if (!githubIntegration) {
      return;
    }

    showVCSIntegrationsBuiltInGitHubDetailsDrawer({
      id: GITHUB_INTEGRATION_DETAILS_DRAWER_ID,
      details: githubIntegration,
    });
  }, [githubIntegration]);

  useTitle("Source code · Integrations");

  const ErrorContent = useErrorHandle(error);

  if (ErrorContent) {
    stopPolling();

    return ErrorContent;
  }

  if (isPageLoading) {
    return <PageLoading />;
  }

  if (isPageNotFound) {
    return <NotFoundPage />;
  }

  return (
    <VCSIntegrationsListContextProvider>
      <VCSIntegrationsPageLayout isPageEmpty={isPageEmpty}>
        <Box role="table" direction="column" grow={!isPageEmpty && !hasNoResults ? "1" : undefined}>
          {(!isPageEmpty || hasBuiltInGitHubIntegration) && <VCSIntegrationsFiltersLayout />}
          {hasBuiltInGitHubIntegration && githubIntegration && (
            <VCSIntegrationsBuiltInGitHubListItem
              onEdit={handleBuiltInGitHubIntegrationEdit}
              onShowDetails={handleBuiltInGitHubOpenDetailsDrawer}
            />
          )}

          {!isPageEmpty && !hasNoResults && (
            <InfiniteLoader
              isItemLoaded={isItemLoaded}
              itemCount={hasNextPage ? integrations.length + ITEMS_LIMIT : integrations.length}
              loadMoreItems={loadMoreItems}
            >
              {({ onItemsRendered }) => (
                <ListEntitiesNew
                  itemCount={integrations.length}
                  itemProps={{
                    items: integrations,
                    onEdit: handleIntegrationEdit,
                    onShowDetails: handleOpenDetailsDrawer,
                  }}
                  virtualizedItem={VCSIntegrationsVirtualizedListItem}
                  itemKey={(index) => getVCSIntegrationId(integrations[index])}
                  onItemsRendered={onItemsRendered}
                />
              )}
            </InfiniteLoader>
          )}
        </Box>
        {isPageEmpty && <VCSIntegrationsEmpty />}
        {hasNoResults && <VCSIntegrationsHasNoResults />}
      </VCSIntegrationsPageLayout>
    </VCSIntegrationsListContextProvider>
  );
};

export default VCSIntegrationsList;
