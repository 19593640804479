import { Controller, useFormContext } from "react-hook-form";
import { useCallback, useMemo, useState } from "react";
import { ApolloError, useQuery } from "@apollo/client";

import ComboBoxDefaultItem from "ds/components/ComboBox/DefaultItem";
import { SelectOption } from "ds/components/Select/types";
import { TooltipModalTitle } from "ds/components/TooltipModal/Title";
import TooltipModalBody from "ds/components/TooltipModal/Body";
import { checkWithMultipleVCSIntegrations } from "utils/vcs";
import { getDocsUrl } from "utils/getDocsUrl";
import ReadMoreDocsLink from "components/ReadMoreDocsLink";
import ComboBox from "ds/components/ComboBox";
import useComboBoxInputValueItem from "ds/components/ComboBox/useInputValueItem";

import { StackVcsFormFields } from "../../types";
import { GET_BRANCHES, GetBranchesGql } from "./gql";
import { getTooltipAnalyticsProps } from "../../utils";
import useStackCreationAnalyticsVersion from "../../useStackCreationAnalyticsVersion";

const INPUT_VALUE_ITEM_PROPS = {
  label: "Can't find your branch? Select this option to add it anyway.",
};

const VcsBranchesField = () => {
  const [notExistingBranchOption, setNotExistingBranchOption] = useState<SelectOption | undefined>(
    undefined
  );

  const { control, setValue, watch, setError, clearErrors } = useFormContext<StackVcsFormFields>();
  const analyticsVersion = useStackCreationAnalyticsVersion();

  const formValues = watch();

  const handleError = useCallback(
    (error: ApolloError) => {
      setError("branch", {
        type: "custom",
        message: error.message,
      });
    },
    [setError]
  );

  const withMultipleVCSIntegrations = checkWithMultipleVCSIntegrations(formValues.provider);

  const { loading, data } = useQuery<GetBranchesGql>(GET_BRANCHES, {
    onError: handleError,
    variables: {
      provider: formValues.provider,
      namespace: formValues.namespace,
      repository: formValues.repository,
      repositoryURL: formValues.repositoryURL,
      vcsIntegrationId: withMultipleVCSIntegrations ? formValues.vcsIntegrationId : null,
    },
    onCompleted: ({ branches }) => {
      const currentValue = formValues.branch;
      const hasCurrentValueInList = branches.includes(currentValue);

      if (currentValue && !hasCurrentValueInList) {
        setNotExistingBranchOption({
          value: currentValue,
          label: currentValue,
        });
      } else {
        setNotExistingBranchOption(undefined);
      }

      const selectedBranch = currentValue || branches[0];

      setValue("branch", selectedBranch, { shouldValidate: true });
      clearErrors("branch");
    },
  });

  const branchesOptions: SelectOption[] = useMemo(
    () => data?.branches.map((value) => ({ label: value, value })) ?? [],
    [data?.branches]
  );

  const options = useMemo(
    () => [...(notExistingBranchOption ? [notExistingBranchOption] : []), ...branchesOptions],
    [branchesOptions, notExistingBranchOption]
  );

  const onInputValueSelected = useCallback(
    (value: string) => {
      setNotExistingBranchOption({
        value,
        label: value,
      });
    },
    [setNotExistingBranchOption]
  );

  const { comboBoxProps } = useComboBoxInputValueItem({
    onInputValueSelected,
    options,
    inputValueItemProps: INPUT_VALUE_ITEM_PROPS,
  });

  return (
    <Controller
      name="branch"
      control={control}
      rules={{ required: "Branch is required" }}
      render={({ field, fieldState }) => (
        <ComboBox
          label="Branch"
          {...getTooltipAnalyticsProps("Source Code", "Branch", {
            provider: formValues.provider,
            version: analyticsVersion,
          })}
          tooltipInfo={
            <>
              <TooltipModalTitle>Branch</TooltipModalTitle>
              <TooltipModalBody align="start">
                Git branch from which this stack is built. Note that the list in the dropdown is not
                exhaustive because for performance reasons we don’t do pagination. If your desired
                branch is not on the list, you can still enter it manually.
                <ReadMoreDocsLink
                  docsUrl={getDocsUrl(
                    "/concepts/stack/stack-settings#vcs-integration-and-repository"
                  )}
                />
              </TooltipModalBody>
            </>
          }
          error={(!loading && fieldState.error?.message) || undefined}
          value={field.value}
          {...comboBoxProps}
          onChange={comboBoxProps.onChange(field.onChange)}
          isLoading={loading}
        >
          {(item) => <ComboBoxDefaultItem id={item.value} {...item} />}
        </ComboBox>
      )}
    />
  );
};

export default VcsBranchesField;
