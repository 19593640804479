import { ReactNode } from "react";

import Typography from "ds/components/Typography";

import { getDescriptionId, getErrorId } from "../helpers";
import { FormFieldColor } from "./types";
import { getColors } from "./utils";

type FormFieldHelperTextProps = {
  id: string; // connect to the input element via aria-describedby
  color?: FormFieldColor;
  helperText?: string | ReactNode;
  error?: string;
};

const FormFieldHelperText = ({
  id,
  helperText,
  error,
  color = "default",
}: FormFieldHelperTextProps) => {
  const { secondaryColor, dangerColor } = getColors(color);

  return (
    <>
      {helperText && !error && (
        <Typography id={getDescriptionId(id)} tag="span" variant="p-body3" color={secondaryColor}>
          {helperText}
        </Typography>
      )}

      {error && (
        <Typography
          id={getErrorId(id)}
          tag="span"
          variant="p-body3"
          color={dangerColor}
          role="alert"
          aria-live="assertive"
        >
          {error}
        </Typography>
      )}
    </>
  );
};

FormFieldHelperText.displayName = "DS.Form.Field.HelperText";

export default FormFieldHelperText;
