import { useCallback } from "react";
import { Controller, useFormContext } from "react-hook-form";

import { VcsProvider } from "types/generated";
import useTypedContext from "hooks/useTypedContext";
import useQueryAttachableVCSIntegrationsOptions from "components/AttachableVCSIntegrationsOptions/useQueryAttachableVCSIntegrationsOptions";
import { SelectedVcsIntegration } from "components/AttachableVCSIntegrationsOptions/types";
import ComboBox from "ds/components/ComboBox";

import { StackVcsFormFields } from "../../types";
import VcsIntegrationFieldComboBoxItem from "./ComboBoxItem";
import VcsIntegrationFieldTooltip from "./Tooltip";
import { getTooltipAnalyticsProps } from "../../utils";
import useStackCreationAnalyticsVersion from "../../useStackCreationAnalyticsVersion";
import { StackFormContext } from "../../context";

type VcsIntegrationFieldProps = {
  provider: VcsProvider;
  spaceId: string;
};

const VcsIntegrationField = ({ provider, spaceId }: VcsIntegrationFieldProps) => {
  const { control, setValue, watch } = useFormContext<StackVcsFormFields>();
  const { setInternalFormData } = useTypedContext(StackFormContext);

  const formValues = watch();

  const analyticsVersion = useStackCreationAnalyticsVersion();

  const handleSetInitialValue = useCallback(
    (integration: SelectedVcsIntegration) => {
      setValue("vcsIntegrationId", integration.id, { shouldValidate: true });
      setInternalFormData((state) => ({ ...state, vcsIntegrationLabel: integration.name }));
    },
    [setInternalFormData, setValue]
  );

  const { options, loading } = useQueryAttachableVCSIntegrationsOptions({
    provider,
    spaceId,
    onSelect: handleSetInitialValue,
    initialValue: formValues.vcsIntegrationId,
  });

  const handleChange = useCallback(
    (onChange: (key: string | null) => void) => (value: string | null) => {
      onChange(value);

      const option = options.find((option) => option.value === value);
      if (option) {
        setInternalFormData((state) => ({ ...state, vcsIntegrationLabel: option.label }));
      }

      setValue("repository", "");
      setValue("branch", "");
    },
    [options, setInternalFormData, setValue]
  );

  return (
    <Controller
      name="vcsIntegrationId"
      control={control}
      rules={{ required: "Integration is required" }}
      render={({ field, fieldState }) => (
        <ComboBox
          label="Integration"
          {...getTooltipAnalyticsProps("Source Code", "Integration", {
            provider: formValues.vcsIntegrationId,
            version: analyticsVersion,
          })}
          tooltipInfo={<VcsIntegrationFieldTooltip provider={provider} />}
          error={fieldState.error?.message}
          value={field.value}
          items={options}
          onChange={handleChange(field.onChange)}
          isLoading={loading}
        >
          {(item) => <VcsIntegrationFieldComboBoxItem {...item} id={item.value} />}
        </ComboBox>
      )}
    />
  );
};

export default VcsIntegrationField;
