import cx from "classnames";
import { useId } from "react";

import Box from "ds/components/Box";
import TileWrapper from "ds/components/Tile/Wrapper";
import TileTitle from "ds/components/Tile/Title";
import Radio from "ds/components/Radio";

import styles from "./styles.module.css";

type ThemeAppearanceTileProps = {
  previewSrc: string;
  title: string;
  selected: boolean;
  onClick: () => void;
  className?: string;
};

const ThemeAppearanceTile = ({
  previewSrc,
  title,
  selected,
  onClick,
  className,
}: ThemeAppearanceTileProps) => {
  const id = useId();

  return (
    <Box className={cx(styles.syntaxTile, className)}>
      <TileWrapper selected={selected} onClick={onClick}>
        <Box direction="column">
          <Box align="start" justify="between" grow="1">
            <Radio className={styles.radio} id={id} checked={selected}>
              <TileTitle>{title}</TileTitle>
            </Radio>
          </Box>
          <Box grow="1" margin="large 0 0 0">
            <img src={previewSrc} alt={title} title={title} className={styles.previewImage} />
          </Box>
        </Box>
      </TileWrapper>
    </Box>
  );
};

export default ThemeAppearanceTile;
