import { ReactNode } from "react";
import { Select as AriaSelect } from "react-aria-components";

import useEnsureId from "hooks/useEnsureId";
import { AnalyticsCommonProps } from "hooks/useAnalytics";
import Box from "ds/components/Box";
import FormFieldHelperText from "ds/components/Form/Field/HelperText";
import TooltipInfo from "ds/components/TooltipInfo";
import { getColors } from "ds/components/Form/Field/utils";
import { getAriaDescribedBy } from "ds/components/Form/helpers";
import ListBoxPopover from "ds/components/ListBox/Popover";
import ListBox from "ds/components/ListBox";
import ListBoxScrollableWrapper from "ds/components/ListBox/ScrollableWrapper";

import SelectInput, { SelectInputProps } from "./Input";
import SelectNewLabel from "./Label";
import styles from "./styles.module.css";
import SelectEmptyCollection from "./EmptyCollection";
import Item from "./Item";
import { BaseSelectItem } from "./types";

type SelectProps<Collection extends BaseSelectItem, Key extends string> = {
  id?: string;
  label?: string;
  helperText?: string;
  error?: string;
  placeholder?: string;
  value: string;
  items: Collection[];
  onChange: (value: Key) => void;
  renderInput?: (input: SelectInputProps) => ReactNode;
  children?: (Collection: Collection) => JSX.Element;
  tooltipInfo?: ReactNode;
  tooltipAnalyticsPage?: AnalyticsCommonProps["analyticsPage"];
  tooltipAnalyticsTitle?: AnalyticsCommonProps["analyticsTitle"];
  tooltipAnalyticsProps?: AnalyticsCommonProps["analyticsProps"];
  isDisabled?: boolean;
  isLoading?: boolean;
  size?: SelectInputProps["size"];
  color?: SelectInputProps["color"];
};

const Select = <Collection extends BaseSelectItem, Key extends string>({
  id: propsId,
  children = (item) => <Item {...item} id={item.value} />,
  label,
  helperText,
  error,
  size,
  color,
  value,
  renderInput = (props) => <SelectInput {...props} />,
  onChange,
  items,
  tooltipInfo,
  tooltipAnalyticsPage,
  tooltipAnalyticsTitle,
  tooltipAnalyticsProps,
  placeholder = "Select an option",
  isDisabled: propsIsDisabled,
  isLoading,
}: SelectProps<Collection, Key>) => {
  const id = useEnsureId(propsId);
  const { primaryIconColor } = getColors(color);
  const isDisabled = propsIsDisabled || !items?.length;
  return (
    <AriaSelect
      className={styles.select}
      selectedKey={value}
      onSelectionChange={(key) => onChange(key as Key)}
      isInvalid={!!error}
      isDisabled={isDisabled}
      placeholder={placeholder}
    >
      <Box gap="small" direction="column">
        {label && (
          <Box gap="small" align="center" fullWidth>
            <SelectNewLabel label={label} color={color} />
            {tooltipInfo && (
              <TooltipInfo
                variant="modal"
                iconColor={primaryIconColor}
                analyticsPage={tooltipAnalyticsPage}
                analyticsTitle={tooltipAnalyticsTitle}
                analyticsProps={tooltipAnalyticsProps}
              >
                {tooltipInfo}
              </TooltipInfo>
            )}
          </Box>
        )}
        {renderInput({
          id,
          "aria-describedby": getAriaDescribedBy(id, error, helperText),
          isError: !!error,
          isDisabled,
          isLoading,
          size,
          color,
        })}
        <FormFieldHelperText helperText={helperText} error={error} id={id} color={color} />
      </Box>
      <ListBoxPopover>
        <ListBoxScrollableWrapper>
          <ListBox<Collection>
            items={items}
            renderEmptyState={() => <SelectEmptyCollection isLoading={isLoading} />}
          >
            {children}
          </ListBox>
        </ListBoxScrollableWrapper>
      </ListBoxPopover>
    </AriaSelect>
  );
};

Select.displayName = "DS.Select";

export default Select;
