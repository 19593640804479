import Banner from "ds/components/Banner";
import Typography from "ds/components/Typography";
import { Space, SpaceAccessLevel } from "types/generated";
import DrawerHeader from "ds/components/Drawer/Header";
import DrawerBody from "ds/components/Drawer/Body";
import FormFieldViewText from "components/FormFields/ViewText";
import Toggle from "ds/components/Toggle";
import { isLegacySpace } from "utils/space";
import DrawerSimple from "ds/components/DrawerNew/Simple";
import DrawerCloseIcon from "ds/components/DrawerNew/CloseIcon";
import DrawerHeaderTitle from "ds/components/DrawerNew/HeaderTitle";

import styles from "./styles.module.css";
import { isOnOutsideClickElementInteractive } from "./helpers";

type SpaceDrawerViewProps = {
  space?: Space;
  isRootAdmin: boolean;
  onClose?: () => void;
};

const SpaceDrawerView = ({ space, isRootAdmin, onClose }: SpaceDrawerViewProps) => {
  return (
    <DrawerSimple
      onClose={onClose}
      shouldCloseOnInteractOutside={isOnOutsideClickElementInteractive}
    >
      <DrawerHeader justify="between">
        <DrawerHeaderTitle title="Space details" />
        <DrawerCloseIcon />
      </DrawerHeader>
      <DrawerBody noPadding>
        <div className={styles.viewList}>
          {isRootAdmin && space && (
            <Banner variant="info">This is your {space?.name} space. It’s not editable.</Banner>
          )}

          <FormFieldViewText label="ID" value={space?.id} withCopy />

          {!isRootAdmin && (
            <FormFieldViewText
              label="Permissions"
              value={
                space?.accessLevel === SpaceAccessLevel.None
                  ? "No access"
                  : space?.accessLevel.toLowerCase()
              }
              noTextEllipsis
              textTransform="uppercase-first"
            />
          )}

          <FormFieldViewText label="Name" value={space?.name} />

          {space?.description && (
            <FormFieldViewText label="Description" value={space.description} noTextEllipsis />
          )}
        </div>

        {!isRootAdmin ||
          (!!space?.id && isLegacySpace(space.id) && (
            <div className={styles.viewList}>
              <Typography tag="h3" variant="p-t5" className={styles.title}>
                Inherit entities
              </Typography>

              <Typography tag="p" variant="p-body2" className={styles.info}>
                Should read access and attachable entities be inherited from the parent space.
              </Typography>

              <Toggle
                variant="checkbox"
                id="inheritEntities"
                isSelected={space?.inheritEntities}
                isDisabled
                aria-label="Inheritance is enabled and cannot be disabled"
              >
                Inheritance enabled
              </Toggle>
            </div>
          ))}
      </DrawerBody>
    </DrawerSimple>
  );
};

export default SpaceDrawerView;
