import { useMemo } from "react";

import PageInfo from "components/PageWrapper/Info";
import useURLParams from "hooks/useURLParams";
import { URL_SEARCH_KEY } from "constants/url_query_keys";
import { fuzzySearch } from "utils/fuzzySearch";
import SearchInput from "components/SearchInput";
import SortableTable from "components/SortableTable";
import ListEntitiesItem from "components/ListEntitiesItem";
import ListEntitiesItemTitle from "components/ListEntitiesItem/Title";
import { SearchQueryOrderDirection } from "types/generated";
import EmptyState from "ds/components/EmptyState";
import { EmptystateSearchNoResultsColored } from "components/icons/generated";

import { columns, FILTERS_ORDER_SETTINGS_KEY_VERSION_RESOURCES } from "./constants";
import ModuleVersionScrollableWrapper from "../components/ScrollableWrapper";
import ModuleVersionPageIntoTitle from "../components/PageIntoTitle";

type ModuleVersionResourcesProps = {
  resources: Array<{
    name: string;
    type: string;
  }>;
};

const ModuleVersionResources = ({ resources }: ModuleVersionResourcesProps) => {
  const urlParams = useURLParams();
  const searchQuery = urlParams.get(URL_SEARCH_KEY);

  const filteredResources = useMemo(() => {
    const results = resources.map((item) => ({
      id: `${item.type}.${item.name}`,
    }));

    if (searchQuery) {
      return fuzzySearch(results, searchQuery.trim(), {
        keys: ["id"],
        scoreThreshold: -1000,
      });
    }

    return results;
  }, [resources, searchQuery]);

  return (
    <>
      <PageInfo
        title={
          <ModuleVersionPageIntoTitle title="Resources" tooltip="Resources the module creates." />
        }
      >
        <SearchInput
          placeholder="Search by name and type..."
          filtersOrderSettingsKey={FILTERS_ORDER_SETTINGS_KEY_VERSION_RESOURCES}
        />
      </PageInfo>
      <ModuleVersionScrollableWrapper padding="0">
        {filteredResources.length === 0 && (
          <EmptyState
            padding="x-large"
            icon={EmptystateSearchNoResultsColored}
            caption={
              searchQuery ? "No resources match your search query" : "This module has no resources"
            }
            fullHeight
            announce={!!searchQuery}
          />
        )}
        {filteredResources.length > 0 && (
          <SortableTable
            ariaLabel="Resources"
            items={filteredResources}
            columns={columns}
            columnOrder="1fr"
            stickyHeader
            renderItems={(sorted) =>
              sorted.map((item) => (
                <ListEntitiesItem role="row" key={item.id} gap="small">
                  <ListEntitiesItemTitle role="rowheader" title={item.id} titleVariant="p-body2" />
                </ListEntitiesItem>
              ))
            }
            initialSortBy="id"
            initialDirection={SearchQueryOrderDirection.Desc}
          />
        )}
      </ModuleVersionScrollableWrapper>
    </>
  );
};

export default ModuleVersionResources;
