import ListEntitiesItem from "components/ListEntitiesItem";
import Box from "ds/components/Box";
import { SearchQueryOrderDirection } from "types/generated";
import TextHighlight from "ds/components/TextHighlight";

import CollapsibleTable from "../../../components/CollapsibleTable";
import { OptionalInput } from "../../types";
import { COLUMN_ORDER, columns } from "./constants";
import InputName from "../InputName";
import { formatDefaultValue } from "./utils";

type OptionalInputsProps = {
  inputs: Array<OptionalInput>;
};

const OptionalInputs = ({ inputs }: OptionalInputsProps) => {
  return (
    <CollapsibleTable
      title="Optional inputs"
      items={inputs}
      columns={columns}
      columnOrder={COLUMN_ORDER}
      renderItems={(sorted) =>
        sorted.map((item, index) => (
          <ListEntitiesItem
            key={item.id}
            noSeparator={index === inputs.length - 1}
            grid
            gridTemplate={COLUMN_ORDER}
            gap="large"
            role="row"
          >
            <InputName
              role="rowheader"
              name={item.id}
              type={item.type}
              description={item.description}
            />
            <Box align="center" role="cell">
              {item.default && (
                <TextHighlight tag="pre">{formatDefaultValue(item.default)}</TextHighlight>
              )}
            </Box>
          </ListEntitiesItem>
        ))
      }
      initialSortBy="id"
      initialDirection={SearchQueryOrderDirection.Desc}
    />
  );
};

export default OptionalInputs;
