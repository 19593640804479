import moment from "moment";

import SelectNew from "ds/components/Select/New";
import { PolicyEvaluationRecord } from "types/generated";
import SelectItem from "ds/components/Select/New/Item";

import { BADGE_STATES } from "./constants";
import EvaluationRecordStatusBadge from "./EvaluationRecordStatusBadge";
import { EvaluationRecordOutcomeStatus } from "./types";

type PolicyDetailsInputSelectProps = {
  inputs: PolicyEvaluationRecord[];
  handleInputKeyChange: (value: string) => void;
  currentPolicyInputKey: string;
};

const PolicyDetailsInputSelect = ({
  inputs,
  handleInputKeyChange,
  currentPolicyInputKey,
}: PolicyDetailsInputSelectProps) => {
  const policyInputsOptions = inputs.map((value) => ({
    label: moment.unix(value.timestamp).format("YYYY-MM-DD HH:mm:ss"),
    value: value.key,
    result: value.outcome,
  }));

  const currentPolicyInput =
    currentPolicyInputKey &&
    policyInputsOptions.find(
      (policyInputsOption) => policyInputsOption.value === currentPolicyInputKey
    );

  const inputKeyValue = currentPolicyInput ? currentPolicyInput.value : "";

  return (
    <SelectNew
      label="Sampled previous inputs"
      items={policyInputsOptions}
      onChange={handleInputKeyChange}
      value={inputKeyValue}
      placeholder={policyInputsOptions.length === 0 ? "No previous inputs" : undefined}
    >
      {(item) => (
        <SelectItem
          id={item.value}
          label={item.label}
          suffix={
            <EvaluationRecordStatusBadge
              status={BADGE_STATES[item.result] || EvaluationRecordOutcomeStatus.Info}
              text={item.result.toLocaleUpperCase()}
            />
          }
        />
      )}
    </SelectNew>
  );
};

export default PolicyDetailsInputSelect;
