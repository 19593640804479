import { Label as AriaLabel } from "react-aria-components";

import Typography from "ds/components/Typography";
import { getColors } from "ds/components/Form/Field/utils";
import { FormFieldColor } from "ds/components/Form/Field/types";

type SelectNewLabelProps = {
  label: string;
  color?: FormFieldColor;
};

const SelectNewLabel = ({ color, label }: SelectNewLabelProps) => {
  const { primaryTextColor } = getColors(color);

  return (
    <AriaLabel>
      <Typography tag="span" variant="p-t7" color={primaryTextColor}>
        {label}
      </Typography>
    </AriaLabel>
  );
};

SelectNewLabel.displayName = "DS.Select.Label";

export default SelectNewLabel;
