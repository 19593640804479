import Box from "ds/components/Box";
import { SearchQueryOrderDirection } from "types/generated";
import EmptyState from "ds/components/EmptyState";
import { EmptystateLinkColored, EmptystateMagnetColored } from "components/icons/generated";
import SortableTable, { SortableTableColumn } from "components/SortableTable";
import CollapsibleSectionPanel from "components/CollapsibleSectionPanel";

import { COLUMN_ORDER_USED_BY } from "../../constants";
import { ContextUsedByAttachedProps } from "./types";
import ContextUsedByAttachedItem from "./Item";
import styles from "./styles.module.css";

const columnsAutoAttach: SortableTableColumn[] = [
  {
    value: "type",
    label: "Type",
    static: true,
  },
  {
    value: "stackName",
    label: "Name",
  },
  {
    value: "stackSpaceDetails.id",
    label: "Space",
  },
];

const columnsRegular: SortableTableColumn[] = [
  ...columnsAutoAttach,
  {
    value: "createdAt",
    label: "Created at",
  },
];

const ContextUsedByAttached = ({ items, name, hasAutoAttach }: ContextUsedByAttachedProps) => {
  const columns = hasAutoAttach ? columnsAutoAttach : columnsRegular;

  return (
    <Box fullWidth direction="column" grow="1">
      <CollapsibleSectionPanel
        ariaLevel={3}
        title={name}
        count={items.length}
        initialIsCollapsed={false}
        contentPadding="0"
      >
        <Box direction="column">
          <SortableTable
            ariaLabel={name}
            items={items}
            columns={columns}
            columnOrder={COLUMN_ORDER_USED_BY}
            renderItems={(sorted) => (
              <>
                {sorted.map((item) => (
                  <ContextUsedByAttachedItem key={item.id} item={item} className={styles.item} />
                ))}
              </>
            )}
            initialSortBy="createdAt"
            initialDirection={SearchQueryOrderDirection.Desc}
          />
        </Box>

        {items.length === 0 && (
          <EmptyState
            className={styles.emptyState}
            icon={hasAutoAttach ? EmptystateMagnetColored : EmptystateLinkColored}
            title={
              hasAutoAttach
                ? "Not auto-attached to any projects yet"
                : "Not attached to any projects yet"
            }
            caption={
              hasAutoAttach
                ? "This is where auto-attached contexts will appear. Use autoattach label to attach context to many stacks at the same time."
                : "This is where stacks and modules that you have manually attached your context to will appear."
            }
          />
        )}
      </CollapsibleSectionPanel>
    </Box>
  );
};

export default ContextUsedByAttached;
