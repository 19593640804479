import { createContext, ReactNode, useCallback, useEffect } from "react";
import { SkeletonTheme } from "react-loading-skeleton";
import useLocalStorage from "@rehooks/local-storage";

import { SyntaxTheme } from "types/SyntaxTheme";
import { Theme as ThemeType } from "types/Theme";

import {
  COLOR_THEME_LOCAL_STORAGE_KEY,
  SYNC_THEME_WITH_SYSTEM_LOCAL_STORAGE_KEY,
} from "./constants";

export type ThemePalette =
  | ThemeType.Light
  | ThemeType.Dark
  | ThemeType.HighContrastLight
  | ThemeType.HighContrastDark;

type ThemeContextProps = {
  changeTheme: (theme: ThemePalette) => void;
  currentTheme: ThemePalette;
  isDarkMode: boolean;
  syncThemeWithSystem: boolean;
  setSyncThemeWithSystem: (value: boolean) => void;
  currentLightModeSyntaxTheme: SyntaxTheme;
  currentDarkModeSyntaxTheme: SyntaxTheme;
  setCurrentLightModeSyntaxTheme: (value: SyntaxTheme) => void;
  setCurrentDarkModeSyntaxTheme: (value: SyntaxTheme) => void;
};

export const ThemeContext = createContext<ThemeContextProps | undefined>(undefined);
ThemeContext.displayName = "ThemeContext";

type ThemeProps = {
  children?: ReactNode;
  forcedTheme?: ThemePalette;
};

const Theme = ({ children, forcedTheme }: ThemeProps) => {
  const [storageTheme, setCurrentTheme] = useLocalStorage<ThemePalette>(
    COLOR_THEME_LOCAL_STORAGE_KEY
  );
  const [syncThemeWithSystem, setSyncThemeWithSystem] = useLocalStorage<boolean>(
    SYNC_THEME_WITH_SYSTEM_LOCAL_STORAGE_KEY
  );

  const [storageCurrentLightModeSyntaxTheme, setCurrentLightModeSyntaxTheme] =
    useLocalStorage<SyntaxTheme>("lightModeSyntaxTheme");
  const [storageCurrentDarkModeSyntaxTheme, setCurrentDarkModeSyntaxTheme] =
    useLocalStorage<SyntaxTheme>("darkModeSyntaxTheme");

  const currentTheme = forcedTheme || storageTheme || ThemeType.Light;

  const currentLightModeSyntaxTheme = storageCurrentLightModeSyntaxTheme || SyntaxTheme.Light;
  const currentDarkModeSyntaxTheme = storageCurrentDarkModeSyntaxTheme || SyntaxTheme.Dark;

  const toggleThemeByAutoMode = useCallback(
    (darkMode: boolean, highContrastMode: boolean) => {
      if (syncThemeWithSystem) {
        if (darkMode) {
          setCurrentTheme(highContrastMode ? ThemeType.HighContrastDark : ThemeType.Dark);
        } else {
          setCurrentTheme(highContrastMode ? ThemeType.HighContrastLight : ThemeType.Light);
        }
      }
    },
    [setCurrentTheme, syncThemeWithSystem]
  );

  const darkModeChangeHandler = useCallback(
    (e: MediaQueryListEvent) => {
      toggleThemeByAutoMode(
        e.matches,
        [ThemeType.HighContrastLight, ThemeType.HighContrastDark].includes(currentTheme)
      );
    },
    [toggleThemeByAutoMode, currentTheme]
  );

  const highContrastModeChangeHandler = useCallback(
    (e: MediaQueryListEvent) => {
      toggleThemeByAutoMode(
        [ThemeType.Dark, ThemeType.HighContrastDark].includes(currentTheme),
        e.matches
      );
    },
    [toggleThemeByAutoMode, currentTheme]
  );

  useEffect(() => {
    document.querySelector("html")?.setAttribute("data-theme", currentTheme);
  }, [currentTheme]);

  useEffect(() => {
    const darkModeMatch = window.matchMedia("(prefers-color-scheme:dark)");
    darkModeMatch.addEventListener("change", darkModeChangeHandler);

    const highContrastMatch = window.matchMedia("(prefers-contrast:more)");
    highContrastMatch.addEventListener("change", highContrastModeChangeHandler);

    toggleThemeByAutoMode(darkModeMatch.matches, highContrastMatch.matches);

    return () => {
      darkModeMatch.removeEventListener("change", darkModeChangeHandler);
      highContrastMatch.removeEventListener("change", highContrastModeChangeHandler);
    };
  }, [
    darkModeChangeHandler,
    highContrastModeChangeHandler,
    syncThemeWithSystem,
    toggleThemeByAutoMode,
  ]);

  return (
    <ThemeContext.Provider
      value={{
        changeTheme: setCurrentTheme,
        currentTheme,
        isDarkMode: [ThemeType.Dark, ThemeType.HighContrastDark].includes(currentTheme),
        syncThemeWithSystem: !!syncThemeWithSystem,
        setSyncThemeWithSystem,
        currentLightModeSyntaxTheme,
        currentDarkModeSyntaxTheme,
        setCurrentLightModeSyntaxTheme,
        setCurrentDarkModeSyntaxTheme,
      }}
    >
      <SkeletonTheme
        baseColor="var(--semantic-color-background-secondary)"
        highlightColor="var(--semantic-color-background-app-background)"
      >
        {children}
      </SkeletonTheme>
    </ThemeContext.Provider>
  );
};

export default Theme;
