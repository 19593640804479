import cx from "classnames";
import camelCase from "lodash-es/camelCase";

import { Minus, Tick } from "components/icons/generated";

import Icon from "../Icon";
import styles from "./styles.module.css";

type ChecboxIconProps = {
  className?: string;
  isIndeterminate?: boolean;
  isSelected?: boolean;
  isDisabled?: boolean;
  color?: "default" | "inversed";
};
const CheckboxIcon = ({
  className,
  isSelected,
  isIndeterminate,
  color,
  isDisabled,
  ...rest
}: ChecboxIconProps) => (
  <div
    className={cx(className, styles.toggle, styles[camelCase(`color-${color}`)], {
      [styles.checked]: isSelected || isIndeterminate,
      [styles.disabled]: isDisabled,
    })}
    {...rest}
  >
    <span className={styles.checkboxWrapper}>
      <Icon src={isIndeterminate ? Minus : Tick} className={styles.checkboxIcon} />
    </span>
  </div>
);

CheckboxIcon.displayName = "DS.Toggle.CheckboxIcon";

export default CheckboxIcon;
