import keyBy from "lodash-es/keyBy";

import { Space } from "types/generated";

/**
 * Get the full path of a space starting from the root space.
 */
const getParentsPath = (map: Record<string, Space>, space: Space): string => {
  let path = space.id;
  let currentSpace = space;

  while (currentSpace?.parentSpace) {
    path = `${map[currentSpace.parentSpace].id}/${path}`;
    currentSpace = map[currentSpace.parentSpace];
  }

  return path;
};

/**
 * Generate a map of space id to its full id path starting from the root space.
 *
 * Example:
 * - root: root
 * - legacy: root/legacy
 * - custom-space-id: root/legacy/custom-space-id
 * - another-space-id: root/another-space-id
 */
export const generateSpaceHierarchyMap = (spaces: Space[] = []) => {
  const spacesById = keyBy(spaces, "id");

  return Object.fromEntries(spaces.map((space) => [space.id, getParentsPath(spacesById, space)]));
};

// TODO: Remove this once backend returns space-hierarchy
export const getFullSpaceNamePath = (
  spaceId: string,
  spaceHierarchy: Record<string, string>,
  spaces: Space[]
) => {
  const spacesById = keyBy(spaces, "id");

  const idHierarchy = spaceHierarchy[spaceId];

  const hierarchyIds = idHierarchy.split("/");

  return hierarchyIds.map((id) => spacesById[id].name).join("/");
};

export const isLegacySpace = (id: string) => id === "legacy";

export const isRootSpace = (id: string) => id === "root";
