import { useEffect, useRef } from "react";
import fromUnixTime from "date-fns/fromUnixTime";
import classNames from "classnames";

import Box from "ds/components/Box";
import ListEntitiesItem from "components/ListEntitiesItem";
import { AuditTrailEntry } from "types/generated";
import Typography from "ds/components/Typography";
import { formatDateTimeByLocale } from "utils/date";
import useApplyFilter from "components/Filters/useApplyFilter";
import TextEllipsis from "ds/components/TextEllipsis";
import { NoteFind } from "components/icons/generated";
import ButtonIcon from "ds/components/ButtonIcon";
import DropdownMenu from "ds/components/DropdownMenu";
import LinkNew from "ds/components/LinkNew";
import DropdownMenuItem from "ds/components/DropdownMenu/Item";
import { useDrawerVisibilityForId } from "ds/components/DrawerNew/useDrawerVisibilityForId";
import { AnalyticsPageAuditTrail } from "hooks/useAnalytics/pages/auditTrail";
import useAnalytics from "hooks/useAnalytics";

import {
  COLUMN_GAP,
  COLUMN_ORDER,
  FILTER_ITEMS_DICTIONARY,
  AuditTrailEntrySuggestions,
} from "../../constants";
import EventTypeBadge from "./EventTypeBadge";
import CreatedBy from "./CreatedBy";
import { formatMetadata } from "../../helpers";
import styles from "./styles.module.css";

type AuditTrailListItemProps = {
  item: AuditTrailEntry;
  setRowHeight?: (size: number) => void;
  onOpenDetails: () => void;
};

function AuditTrailListItem({ item, setRowHeight, onOpenDetails }: AuditTrailListItemProps) {
  const rowRef = useRef<HTMLDivElement>(null);
  const trackSegmentAnalyticsEvent = useAnalytics({
    page: AnalyticsPageAuditTrail.Logs,
  });

  const isActive = useDrawerVisibilityForId(item.id);

  const handleRowHeight = () => {
    if (setRowHeight && rowRef.current) {
      setRowHeight(rowRef.current.getBoundingClientRect().height);
    }
  };

  useEffect(handleRowHeight);

  const { applyFilter } = useApplyFilter<AuditTrailEntrySuggestions>({
    searchSuggestionsDictionary: FILTER_ITEMS_DICTIONARY,
  });

  const {
    typeLabel: affectedResourceTypeLabel,
    detailLabel: affectedResourceDetailLabel,
    detailUrl: affectedResourceDetailUrl,
  } = formatMetadata(item.affectedResource);

  const {
    typeLabel: relatedResourceTypeLabel,
    detailLabel: relatedResourceDetailLabel,
    detailUrl: relatedResourceDetailUrl,
  } = formatMetadata(item.relatedResource);

  const onAffectedResourceLinkClicked = () => {
    trackSegmentAnalyticsEvent("Link clicked", {
      column: "Affected resource",
    });
  };

  const onRelatedResourceLinkClicked = () => {
    trackSegmentAnalyticsEvent("Link clicked", {
      column: "Related resource",
    });
  };

  return (
    <ListEntitiesItem
      align="center"
      grid
      gridTemplate={COLUMN_ORDER}
      gap={`0 ${COLUMN_GAP}`}
      ref={rowRef}
      isActive={isActive}
    >
      <Box align="start" justify="center" direction="column" gap="small" grow="0">
        <Typography tag="span" variant="p-body2">
          {affectedResourceTypeLabel}
        </Typography>
        {affectedResourceDetailLabel && (
          <DropdownMenu
            triggerComponent={
              <TextEllipsis tooltip={affectedResourceDetailLabel} tooltipWidthMode="maxWidthSm">
                {(ellipsisProps) => (
                  <LinkNew
                    {...ellipsisProps}
                    onPress={onAffectedResourceLinkClicked}
                    disabled={!item.affectedResource.resourceId}
                    className={classNames(ellipsisProps.className, styles.link)}
                  >
                    {affectedResourceDetailLabel}
                  </LinkNew>
                )}
              </TextEllipsis>
            }
          >
            {item.affectedResource.resourceId && (
              <DropdownMenuItem
                onAction={() => {
                  applyFilter(AuditTrailEntrySuggestions.AffectedResourceID)(
                    item.affectedResource.resourceId!
                  );

                  trackSegmentAnalyticsEvent("Dropdown action clicked", {
                    action: "Add affected resource to filters",
                  });
                }}
              >
                Add resource to filters
              </DropdownMenuItem>
            )}
            <DropdownMenuItem
              onAction={() => {
                applyFilter(AuditTrailEntrySuggestions.AffectedResourceType)(
                  item.affectedResource.resourceType
                );

                trackSegmentAnalyticsEvent("Dropdown action clicked", {
                  action: "Add affected resource type to filters",
                });
              }}
            >
              Add resource type to filters
            </DropdownMenuItem>
            {affectedResourceDetailUrl && (
              <DropdownMenuItem
                href={affectedResourceDetailUrl}
                onAction={() => {
                  trackSegmentAnalyticsEvent("Dropdown action clicked", {
                    action: "Go to affected resource",
                  });
                }}
              >
                Go to {affectedResourceTypeLabel}
              </DropdownMenuItem>
            )}
          </DropdownMenu>
        )}
      </Box>
      <Box align="start" justify="center" direction="column" gap="small">
        {item.relatedResource ? (
          <>
            <Typography tag="span" variant="p-body2">
              {relatedResourceTypeLabel}
            </Typography>
            {relatedResourceTypeLabel && (
              <DropdownMenu
                triggerComponent={
                  <TextEllipsis tooltip={relatedResourceDetailLabel} tooltipWidthMode="maxWidthSm">
                    {(ellipsisProps) => (
                      <LinkNew
                        {...ellipsisProps}
                        onPress={onRelatedResourceLinkClicked}
                        disabled={!item.relatedResource!.resourceId}
                        className={classNames(ellipsisProps.className, styles.link)}
                      >
                        {relatedResourceDetailLabel}
                      </LinkNew>
                    )}
                  </TextEllipsis>
                }
              >
                {item.relatedResource!.resourceId && (
                  <DropdownMenuItem
                    onAction={() => {
                      applyFilter(AuditTrailEntrySuggestions.RelatedResourceID)(
                        item.relatedResource!.resourceId!
                      );
                      trackSegmentAnalyticsEvent("Dropdown action clicked", {
                        action: "Add related resource to filters",
                      });
                    }}
                  >
                    Add resource to filters
                  </DropdownMenuItem>
                )}
                <DropdownMenuItem
                  onAction={() => {
                    applyFilter(AuditTrailEntrySuggestions.RelatedResourceType)(
                      item.relatedResource!.resourceType
                    );

                    trackSegmentAnalyticsEvent("Dropdown action clicked", {
                      action: "Add related resource type to filters",
                    });
                  }}
                >
                  Add resource type to filters
                </DropdownMenuItem>
                {relatedResourceDetailUrl && (
                  <DropdownMenuItem
                    href={relatedResourceDetailUrl}
                    onAction={() => {
                      trackSegmentAnalyticsEvent("Dropdown action clicked", {
                        action: "Go to related resource",
                      });
                    }}
                  >
                    Go to {relatedResourceTypeLabel}
                  </DropdownMenuItem>
                )}
              </DropdownMenu>
            )}
          </>
        ) : (
          <Typography tag="span" variant="p-body2">
            -
          </Typography>
        )}
      </Box>
      <Box align="center">
        <EventTypeBadge eventType={item.eventType} />
      </Box>
      <Box align="center">
        <Typography tag="span" variant="s-body3" className={styles.action}>
          {item.action}
        </Typography>
      </Box>
      <Box align="center">
        <CreatedBy applyFilter={applyFilter} value={item.actor} />
      </Box>
      <Box align="center">
        <Typography tag="span" variant="p-body2">
          {formatDateTimeByLocale({ date: fromUnixTime(item.createdAt), format: "dateTimeFull" })}
        </Typography>
      </Box>
      <Box align="center">
        <ButtonIcon icon={NoteFind} size="small" variant="secondary" onClick={onOpenDetails}>
          Show log details
        </ButtonIcon>
      </Box>
    </ListEntitiesItem>
  );
}

export default AuditTrailListItem;
