import cronsTrue from "cronstrue";
import { isBefore } from "date-fns";
import prettyBytes from "pretty-bytes";
import satisfies from "semver/functions/satisfies";
import maxSatisfying from "semver/ranges/max-satisfying";
import validRange from "semver/ranges/valid";
import z from "zod";

import { extractRepositoryDetails } from "components/Forms/SourceCode/RepositoryUrlField/utils";
import { TerraformWorkflowTool as WorkflowTool } from "types/generated";

const validateWorkspaceField = (value: string): string => {
  if (value && value.length > 90) {
    return "Workspace names need to be 90 characters or less";
  }

  if (value && !value.match(/^[a-z0-9-_]+$/i)) {
    return "Workspace names can only include letters, numbers, - and _";
  }

  return "";
};

const validateVersionRange = (
  range?: string | null,
  supportedVersions: string[] = [],
  vendor = "Terraform"
): string => {
  const stringIsRequiredValidation = stringIsRequired("Please select a version range.")(range);
  if (stringIsRequiredValidation !== true) {
    return stringIsRequiredValidation;
  }

  const validatedRange = validRange(range);

  if (validatedRange === null) {
    return "Please enter a valid version range.";
  }

  if (!supportedVersions.length) {
    return "";
  }

  for (const version of supportedVersions) {
    if (satisfies(version, range ?? "")) {
      return "";
    }
  }

  return `No supported ${vendor} version (${supportedVersions[supportedVersions.length - 1]} - ${
    supportedVersions[0]
  }) satisfies constraints ${range}`;
};

const validateUseSmartSanitizationField = (
  useSmartSanitization: boolean,
  terraformVersion?: string | null,
  versionsList?: string[],
  workflowTool = WorkflowTool.TerraformFoss
): string => {
  const stringIsRequiredValidation = stringIsRequired("Please select a version range.")(
    terraformVersion
  );
  if (stringIsRequiredValidation !== true) {
    return stringIsRequiredValidation;
  }

  if (!useSmartSanitization) {
    return "";
  }

  // Each OpenTofu version should support smart sanitization
  if (workflowTool == WorkflowTool.OpenTofu) {
    return "";
  }

  // Skip validation for custom workflow tool (we expect customer will know what they are doing)
  // TODO: add warning in the frontend when Custom workflow tool + smart sanitization is selected
  if (workflowTool == WorkflowTool.Custom) {
    return "";
  }

  // we looking for the max satisfying version in the range of versions (same as in the backend)
  let maxSatisfyingTFVersion;
  try {
    // smart sanitization is only supported on terraform version 1.0.1 and above
    // to check this, we need to parse the version string/range, then determine that the range
    // is a subset of the range `>=1.0.1
    maxSatisfyingTFVersion = maxSatisfying(versionsList ?? [], terraformVersion ?? "");
  } catch {
    //ignore the error
  }

  // Terraform version is validated by the validateVersionRange function
  // so we should ignore the error here
  if (!maxSatisfyingTFVersion) {
    return "";
  }

  if (!satisfies(maxSatisfyingTFVersion, ">=1.0.1", { includePrerelease: true })) {
    return "Smart sanitization is only supported on Terraform version 1.0.1 and above";
  }

  return "";
};

export const validateEmail = (email: string) => {
  const isValid =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      email
    );

  if (!isValid) {
    return "Email is not valid";
  }

  return undefined;
};

export const stackValidator = {
  validateWorkspaceField,
  validateUseSmartSanitizationField,
  validateVersionRange,
};

export const stringIsRequired =
  (message = "This field is required.") =>
  (value?: string | null) =>
    z.string().trim().min(1).safeParse(value).success || message;

export const validateFileSize = (file: File, maxSize: number) => {
  if (file && file.size > maxSize) {
    return `File is too large, max size is ${prettyBytes(maxSize)}`;
  }

  return true;
};

export const validateEnvVarName = (value: string) => {
  // https://github.com/spacelift-io/backend/blob/17556fce4b3417c60b9f778c96d8f3019315fc90/shared/config_element.go#L25
  const PATTERN = /^[a-zA-Z_]+[a-zA-Z0-9_]*$/;

  if (PATTERN.test(value)) {
    return true;
  }

  return "Name must be a valid UNIX environment variable name.";
};

type ValidateURLParams = {
  errorMessage?: string;
  https?: boolean;
  isOptional?: boolean;
};
export const validateURL =
  ({
    errorMessage = "The provided URL is not in a valid format. Example valid url: https://example.com",
    https,
    isOptional,
  }: ValidateURLParams) =>
  (url: string) => {
    try {
      if (url && !isOptional) {
        const urlData = new URL(url);

        if (https && urlData.protocol === "https:") {
          return true;
        } else if (https) {
          return errorMessage;
        }
      }

      return true;
    } catch {
      return errorMessage;
    }
  };

type ValidateRequiredURLParams = {
  requiredStringError?: string;
  urlError?: string;
  urlHttps?: boolean;
};
export const validateRequiredURL =
  ({ requiredStringError, urlError, urlHttps }: ValidateRequiredURLParams = {}) =>
  (url: string) => {
    const requiredValidation = stringIsRequired(requiredStringError)(url);
    const urlValidation = validateURL({ errorMessage: urlError, https: urlHttps })(url);

    if (requiredValidation === true && urlValidation === true) {
      return true;
    }

    if (requiredValidation === true) {
      return urlValidation;
    }

    return requiredValidation;
  };

export const validateRegExp =
  (errorMessage: string = "The provided value is not a valid RegExp") =>
  (value: string) => {
    try {
      new RegExp(value);
      return true;
    } catch {
      return errorMessage;
    }
  };

export const validateDateIsAfterNow = (value: Date) => {
  if (value && isBefore(value, new Date())) {
    return "You must select a future date";
  }

  return true;
};

const validateCronExpression =
  (errorMessage: string = "The provided value is not a valid cron expression") =>
  (value: string) => {
    try {
      return !!cronsTrue.toString(value.toString());
    } catch {
      return errorMessage;
    }
  };

type ValidateRequiredCronExpression = {
  requiredStringError?: string;
  cronExpressionError?: string;
};
export const validateRequiredCronExpression =
  ({ requiredStringError, cronExpressionError }: ValidateRequiredCronExpression = {}) =>
  (cronExpression: string) => {
    const requiredValidation = stringIsRequired(requiredStringError)(cronExpression);
    const cronExpressionValidation = validateCronExpression(cronExpressionError)(cronExpression);

    if (requiredValidation === true && cronExpressionValidation === true) {
      return true;
    }

    if (requiredValidation === true) {
      return cronExpressionValidation;
    }

    return requiredValidation;
  };

const validateAccountName =
  (accountNameError: string = "The provided value is not a valid account name") =>
  (accountName: string) => {
    // https://github.com/spacelift-io/backend/blob/c478ca5fb43ad6aa19f7d706e0101e0f6b7e4263/database/account.go#L391
    const ACCOUNT_NAME_VALIDATION_PATTERN = /^[A-Za-z0-9][A-Za-z0-9\-_]{2,38}[A-Za-z0-9]$/;

    if (ACCOUNT_NAME_VALIDATION_PATTERN.test(accountName)) {
      return true;
    }

    return accountNameError;
  };

type ValidateRequiredAccountName = {
  requiredStringError?: string;
  accountNameError?: string;
};
export const validateRequiredAccountName =
  ({ requiredStringError, accountNameError }: ValidateRequiredAccountName) =>
  (accountName: string) => {
    const requiredValidation = stringIsRequired(requiredStringError)(accountName);
    const accountNameValidation = validateAccountName(accountNameError)(accountName);

    if (requiredValidation !== true) {
      return requiredValidation;
    }

    if (accountNameValidation !== true) {
      return accountNameValidation;
    }

    return true;
  };

export const validatePolicyName = (namesToValidate: string[]) => (value: string) => {
  const trimValue = value.trim();
  const requiredValidation = stringIsRequired("Name field is required.")(trimValue);
  if (requiredValidation !== true) {
    return requiredValidation;
  }

  const name = trimValue.toLowerCase();
  if (namesToValidate.includes(name)) {
    return "A policy with this name already exists in the account.";
  }
  return true;
};

export const validateUrlField =
  (requiredErrorMessage: string = "URL is required") =>
  (value: string = "") => {
    const trimValue = value.trim();
    const requiredValue = stringIsRequired(requiredErrorMessage)(trimValue);
    if (requiredValue !== true) {
      return requiredValue;
    }

    const { err } = extractRepositoryDetails(trimValue);
    return err || undefined;
  };
