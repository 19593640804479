import ListBoxItem from "../ListBox/Item";
import ComboBoxHighlightText from "./HighlightText";
import ComboBoxInputValueItem from "./InputValueItem";
import Typography from "../Typography";

type ComboBoxItemProps = {
  id: string;
  label: string;
  isInputValueItem?: boolean;
};

const ComboBoxDefaultItem = ({ id, label, isInputValueItem }: ComboBoxItemProps) => {
  if (isInputValueItem) {
    return (
      <ComboBoxInputValueItem textValue={id}>
        <Typography tag="span" variant="p-body3" color="primary">
          {label}
        </Typography>
      </ComboBoxInputValueItem>
    );
  }

  return (
    <ListBoxItem id={id} textValue={label}>
      <ComboBoxHighlightText text={label} />
    </ListBoxItem>
  );
};

ComboBoxDefaultItem.displayName = "DS.ComboBox.DefaultItem";

export default ComboBoxDefaultItem;
