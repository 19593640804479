import { Space } from "types/generated";

import { INTERACTIVE_ATTRIBUTE_FOR_DRAWER } from "../constants";

export const getDefaultValues = (isEditMode: boolean, space?: Space) =>
  isEditMode
    ? {
        name: space?.name || "",
        description: space?.description || "",
        inheritEntities: !!space?.inheritEntities,
        parentSpace: space?.parentSpace || "",
        labels: space?.labels.map((value) => ({ value })) || [],
      }
    : {
        name: "",
        description: "",
        inheritEntities: true,
        parentSpace: space?.id,
        labels: [],
      };

export const isOnOutsideClickElementInteractive = (el: Element) => {
  const dataTreeInteractiveElements = document.querySelectorAll(
    `[${INTERACTIVE_ATTRIBUTE_FOR_DRAWER}]`
  );

  if (Array.from(dataTreeInteractiveElements).find((parent) => parent.contains(el))) {
    return false;
  }

  // TODO: refactor this approach
  const hrefElem =
    el.getAttribute("href") ||
    el.parentElement?.getAttribute("href") ||
    el.parentElement?.parentElement?.getAttribute("href");
  if (hrefElem?.includes("/spaces/") || hrefElem?.includes("/spaces?")) {
    return false;
  }

  return true;
};
