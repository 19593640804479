import InfiniteLoader from "react-window-infinite-loader";

import NotFoundPage from "components/error/NotFoundPage";
import PageLayoutSkeleton from "components/PageLayoutSkeleton";
import useErrorHandle from "hooks/useErrorHandle";
import ListEntitiesNew from "components/ListEntitiesNew";
import { isSaasDistribution } from "utils/distribution";
import { WorkerPool } from "types/generated";
import EmptyState from "ds/components/EmptyState";
import { EmptystateSearchNoResultsColored } from "components/icons/generated";
import Box from "ds/components/Box";

import WorkerPoolVirtualizedListItem from "./ListItem/Virtualized";
import WorkerPoolsEmpty from "./Empty";
import WorkerPoolsFiltersLayout from "./FiltersLayout";
import WorkerPoolsPageLayout from "./PageLayout";
import useSearchWorkerPools from "./useSearchWorkerPools";
import { ITEMS_LIMIT, PRIVATE_WORKER_POOL_CALLOUT_STORAGE_KEY } from "./constants";
import { showWorkerPoolFormDrawer } from "./CreateWorkerPool";
import PublicWorkerPoolListItem from "./ListItem/PublicWorkerPoolListItem";
import PrivateWorkersTierInfo from "../PrivateWorkersTierInfo";

const isSaas = isSaasDistribution();

const WorkerPools = () => {
  const {
    publicWorkerPool,
    entities,
    isPageEmpty,
    isPageLoading,
    notFound,
    error,
    stopPolling,
    loadMoreItems,
    hasNextPage,
    isSearchActive,
  } = useSearchWorkerPools();

  const handleCreateWorkerPool = () => {
    showWorkerPoolFormDrawer();
  };

  const handleEditWorkerPool = (workerPool: WorkerPool) => {
    showWorkerPoolFormDrawer({
      id: workerPool.id,
      workerPool,
      refetchQuery: "SearchWorkerPools",
    });
  };

  const isItemLoaded = (value: number) => !hasNextPage || value < entities.length;

  const ErrorContent = useErrorHandle(error);

  if (ErrorContent) {
    stopPolling();

    return ErrorContent;
  }

  if (isPageLoading) {
    return (
      <WorkerPoolsPageLayout>
        <PageLayoutSkeleton />
      </WorkerPoolsPageLayout>
    );
  }

  if (notFound) {
    return <NotFoundPage />;
  }

  return (
    <WorkerPoolsPageLayout onCreate={handleCreateWorkerPool}>
      <PrivateWorkersTierInfo storageKey={PRIVATE_WORKER_POOL_CALLOUT_STORAGE_KEY} />

      <Box role="table" direction="column" grow={!isPageEmpty ? "1" : undefined}>
        <WorkerPoolsFiltersLayout />
        {isSaas && publicWorkerPool && (
          <PublicWorkerPoolListItem publicWorkerPool={publicWorkerPool} />
        )}

        {!isPageEmpty && (
          <InfiniteLoader
            isItemLoaded={isItemLoaded}
            itemCount={hasNextPage ? entities.length + ITEMS_LIMIT : entities.length}
            loadMoreItems={loadMoreItems}
          >
            {({ onItemsRendered }) => (
              <ListEntitiesNew
                itemCount={entities.length}
                itemProps={{
                  items: entities,
                  onEdit: handleEditWorkerPool,
                }}
                virtualizedItem={WorkerPoolVirtualizedListItem}
                itemKey={(index) => entities[index].id}
                onItemsRendered={onItemsRendered}
              />
            )}
          </InfiniteLoader>
        )}
      </Box>

      {isPageEmpty && !isSearchActive && <WorkerPoolsEmpty />}
      {isPageEmpty && isSearchActive && (
        <EmptyState title="No results" icon={EmptystateSearchNoResultsColored} announce />
      )}
    </WorkerPoolsPageLayout>
  );
};

export default WorkerPools;
