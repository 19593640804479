import FiltersSortHeaderWrapper from "components/Filters/SortHeader/Wrapper";
import FiltersSortHeaderSortableColumn from "components/Filters/SortHeader/SortableColumn";
import useFiltersSortHeader from "components/Filters/SortHeader/useFiltersSortHeader";
import FiltersSortHeaderStaticColumn from "components/Filters/SortHeader/StaticColumn";

import { COLUMN_GAP, COLUMN_ORDER, SortableColumns } from "../constants";

const VCSIntegrationsFiltersLayoutHeader = () => {
  const { querySortOption, querySortDirection, onItemClick } = useFiltersSortHeader();

  return (
    <FiltersSortHeaderWrapper ariaRole="row" columnOrder={COLUMN_ORDER} columnGap={COLUMN_GAP}>
      <FiltersSortHeaderSortableColumn
        option={{
          label: SortableColumns.NAME,
          value: SortableColumns.NAME,
        }}
        wrapperAriaRole="columnheader"
        querySortOption={querySortOption}
        querySortDirection={querySortDirection}
        onClick={onItemClick}
      />
      <FiltersSortHeaderSortableColumn
        wrapperAriaRole="columnheader"
        option={{
          label: SortableColumns.SPACE,
          value: SortableColumns.SPACE,
        }}
        querySortOption={querySortOption}
        querySortDirection={querySortDirection}
        onClick={onItemClick}
      />
      <FiltersSortHeaderStaticColumn>&nbsp;</FiltersSortHeaderStaticColumn>
    </FiltersSortHeaderWrapper>
  );
};

export default VCSIntegrationsFiltersLayoutHeader;
