import {
  ListBoxItem as AriaListBoxItem,
  ListBoxItemProps as AriaListBoxItemProps,
} from "react-aria-components";
import cx from "classnames";

import styles from "./styles.module.css";

type ListBoxItemProps = AriaListBoxItemProps & {
  highlightSelection?: boolean;
};

const ListBoxItem = ({ highlightSelection = true, ...props }: ListBoxItemProps) => (
  <AriaListBoxItem
    className={cx(styles.listBoxItem, { [styles.highlightSelection]: highlightSelection })}
    {...props}
  />
);

ListBoxItem.displayName = "DS.ListBox.Item";

export default ListBoxItem;
