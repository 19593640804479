import { ReactNode } from "react";

import TextEllipsis from "ds/components/TextEllipsis";
import Typography from "ds/components/Typography";
import ListBoxItem from "ds/components/ListBox/Item";

type SelectItemProps = {
  id: string;
  label: string;
  suffix?: ReactNode;
};

const SelectItem = ({ id, label, suffix }: SelectItemProps) => {
  return (
    <ListBoxItem id={id} textValue={label}>
      <TextEllipsis tooltip={label} tooltipWidthMode="maxWidthSm">
        {(props) => (
          <Typography tag="span" variant="p-body3" color="primary" {...props}>
            {label}
          </Typography>
        )}
      </TextEllipsis>
      {suffix}
    </ListBoxItem>
  );
};

SelectItem.displayName = "DS.Select.Item";

export default SelectItem;
