import { ReactNode } from "react";
import classNames from "classnames";

import Typography, { TypographyProps } from "../Typography";
import styles from "./styles.module.css";
import TextEllipsis from "../TextEllipsis";

type TextHighlightProps = {
  tag?: TypographyProps["tag"];
  children: ReactNode;
  className?: string;
};

const TextHighlight = ({ children, className, tag = "span" }: TextHighlightProps) => {
  return (
    <TextEllipsis tooltip={children}>
      {(props) => (
        <Typography
          {...props}
          tag={tag}
          className={classNames(styles.wrapper, props.className, className)}
        >
          {children}
        </Typography>
      )}
    </TextEllipsis>
  );
};

TextHighlight.displayName = "DS.TextHighlight";

export default TextHighlight;
