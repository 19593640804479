import { Controller, useFormContext } from "react-hook-form";

import FormField from "ds/components/Form/Field";
import Input from "ds/components/Input";
import Textarea from "ds/components/Textarea";
import { BlueprintInput, BlueprintInputType } from "types/generated";
import ComboBox from "ds/components/ComboBox";
import ComboBoxDefaultItem from "ds/components/ComboBox/DefaultItem";
import FormToggleField from "ds/components/Form/ToggleField";

import { TemplatePreviewFormFields } from "./types";

type TemplatePreviewFormFieldProps = {
  input: BlueprintInput;
};

const TemplatePreviewFormField = ({ input }: TemplatePreviewFormFieldProps) => {
  const { register, control } = useFormContext<TemplatePreviewFormFields>();

  switch (input.type) {
    case BlueprintInputType.ShortText: {
      return (
        <FormField key={input.id} label={input.name} helperText={input.description} noMargin>
          {({ ariaInputProps }) => <Input {...register(input.id)} {...ariaInputProps} />}
        </FormField>
      );
    }
    case BlueprintInputType.LongText: {
      return (
        <FormField key={input.id} label={input.name} helperText={input.description} noMargin>
          {({ ariaInputProps }) => <Textarea {...register(input.id)} {...ariaInputProps} />}
        </FormField>
      );
    }
    case BlueprintInputType.Secret: {
      return (
        <FormField key={input.id} label={input.name} helperText={input.description} noMargin>
          {({ ariaInputProps }) => (
            <Input type="password" {...register(input.id)} {...ariaInputProps} />
          )}
        </FormField>
      );
    }
    case BlueprintInputType.Number: {
      return (
        <FormField key={input.id} label={input.name} helperText={input.description} noMargin>
          {({ ariaInputProps }) => (
            <Input type="number" {...register(input.id)} {...ariaInputProps} />
          )}
        </FormField>
      );
    }
    case BlueprintInputType.Select: {
      return (
        <Controller
          name={input.id}
          control={control}
          render={({ field }) => (
            <ComboBox
              key={input.id}
              label={input.name}
              helperText={input.description}
              items={input.options.map((option) => ({ value: option, label: option }))}
              onChange={field.onChange}
              value={field.value as string}
            >
              {(item) => <ComboBoxDefaultItem id={item.value} label={item.label} />}
            </ComboBox>
          )}
        />
      );
    }
    case BlueprintInputType.Float: {
      return (
        <FormField key={input.id} label={input.name} helperText={input.description} noMargin>
          {({ ariaInputProps }) => (
            <Input type="number" {...register(input.id)} {...ariaInputProps} />
          )}
        </FormField>
      );
    }
    case BlueprintInputType.Boolean: {
      return (
        <Controller
          name={input.id}
          control={control}
          render={({ field }) => (
            <FormToggleField
              variant="switch"
              id={input.id}
              onChange={field.onChange}
              checked={field.value as boolean}
              aria-label={
                field.value
                  ? `Unselect ${input.description || input.name}`
                  : `Select ${input.description || input.name}`
              }
              title={input.name}
              description={input.description}
            />
          )}
        />
      );
    }
  }
};

export default TemplatePreviewFormField;
