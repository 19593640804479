import { useCallback } from "react";
import { useBlocker } from "react-router-dom";
import { useModal } from "@ebay/nice-modal-react";

import { showSimpleLeaveConfirmation } from "../LeaveConfirmationModal/Simple";
import DrawerSimple, { DrawerSimpleProps } from "./Simple";

type DrawerFormProps = {
  isDirty: boolean;
  blockNavigation?: boolean;
} & DrawerSimpleProps;

const DrawerForm = ({
  children,
  onClose,
  isDirty,
  blockNavigation,
  ...restProps
}: DrawerFormProps) => {
  const drawer = useModal();

  const blocker = useBlocker(Boolean(blockNavigation && isDirty && drawer.visible));

  const handleClose = async () => {
    if (isDirty) {
      await showSimpleLeaveConfirmation();
    }

    if (!blockNavigation) {
      onClose?.();
    }
  };

  const onExit = useCallback(() => {
    if (blockNavigation) {
      if (blocker.state === "unblocked") {
        onClose?.();
      } else {
        blocker.proceed?.();
      }
    }

    drawer.remove();
  }, [drawer, blocker, blockNavigation, onClose]);

  return (
    <DrawerSimple onClose={handleClose} onExit={onExit} {...restProps}>
      {children}
    </DrawerSimple>
  );
};

DrawerForm.displayName = "DS.DrawerNew.Form";

export default DrawerForm;
