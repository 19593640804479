import cx from "classnames";

import Box from "components/box/Box";
import DeltaStrip from "components/DeltaStrip";
import State from "components/state/State";
import PerformTaskButton from "views/shared/run/ActionButtons/PerformTaskButton";

import Icons from "./Icons";
import ListBoxLink from "./Link";
import styles from "./styles.module.css";
import { ListBoxProps, ListBoxState } from "./types";

const ListBox = (props: ListBoxProps) => {
  const {
    state,
    headerLink,
    children,
    delta,
    headerIcon,
    performAgain,
    highlightMatches,
    className,
    innerRef,
    compact = false,
  } = props;

  const isCustomState = state && !Object.prototype.hasOwnProperty.call(state, "content");

  const showPerformAgain =
    state &&
    !isCustomState &&
    ((state as ListBoxState).content === "FAILED" ||
      (state as ListBoxState).content === "FINISHED");

  return (
    <Box
      className={cx(styles.listBox, className, {
        [styles.listBoxCompact]: compact,
      })}
      innerRef={innerRef}
    >
      {state && (
        <div className={styles.listBoxState}>
          {isCustomState ? (
            (state as JSX.Element)
          ) : (
            <State
              type={(state as ListBoxState).content}
              full={(state as ListBoxState).showTitle}
              text={(state as ListBoxState).text}
              link={(state as ListBoxState).link}
              isModuleVersion={(state as ListBoxState).isModuleVersion}
            />
          )}
        </div>
      )}
      <div className={styles.listBoxContent}>
        <h3
          className={cx(styles.listBoxName, {
            [styles.listBoxNameNoMargin]: headerLink.styleProps,
          })}
        >
          {headerLink.extraTitle && (
            <div className={styles.listBoxExtraTitle}>{headerLink.extraTitle}</div>
          )}
          <ListBoxLink
            url={headerLink.to}
            text={headerLink.name}
            routerLink={headerLink.routerLink}
            renderAsCode={headerLink.renderAsCode}
            headerLink
            highlightMatches={highlightMatches}
          />
          <Icons headerIcon={headerIcon} />
        </h3>
        {children}
      </div>
      {delta && <DeltaStrip {...delta} />}
      {showPerformAgain && performAgain && (
        <PerformTaskButton
          className={cx(styles.listBoxCta, styles.listBoxLink, styles.listBoxPerformAgain)}
          stackId={performAgain.stackId}
          command={performAgain.command}
        >
          perform again
        </PerformTaskButton>
      )}
    </Box>
  );
};

export default ListBox;
