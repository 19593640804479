import { Controller, useFormContext } from "react-hook-form";
import { useCallback, useMemo, useState } from "react";
import { ApolloError, useQuery } from "@apollo/client";

import ComboBoxDefaultItem from "ds/components/ComboBox/DefaultItem";
import { VCS_PROVIDERS } from "constants/vcs_providers";
import { SelectOption } from "ds/components/Select/types";
import Banner from "ds/components/Banner";
import Box from "ds/components/Box";
import { VcsProvider } from "types/generated";
import { checkWithMultipleVCSIntegrations } from "utils/vcs";
import ComboBox from "ds/components/ComboBox";
import useComboBoxInputValueItem from "ds/components/ComboBox/useInputValueItem";

import RepositoryTooltip from "../RepositoryTooltip";
import { StackVcsFormFields } from "../../types";
import { GET_REPOSITORIES, GetRepositoriesGql } from "./gql";
import { getTooltipAnalyticsProps } from "../../utils";
import useStackCreationAnalyticsVersion from "../../useStackCreationAnalyticsVersion";
import { parseRepository } from "./helpers";

const isValidValue = (value: string) => {
  const trimmedValue = value.trim();

  const [namespace, repository] = trimmedValue.split("/");

  return !!namespace && !!repository;
};

const INPUT_VALUE_OPTION_PROPS = {
  label: "Can't find your repository? Select this option to add it anyway.",
};

const VcsRepositoryField = () => {
  const { control, setValue, watch, setError, clearErrors } = useFormContext<StackVcsFormFields>();
  const analyticsVersion = useStackCreationAnalyticsVersion();
  const [notExistingRepoOption, setNotExistingRepoOption] = useState<SelectOption | undefined>(
    undefined
  );

  const formValues = watch();

  const currentValue =
    formValues.namespace && formValues.repository
      ? `${formValues.namespace}/${formValues.repository}`
      : undefined;

  const handleError = useCallback(
    (error: ApolloError) => {
      setError("repository", {
        type: "custom",
        message: error.message,
      });
      setValue("branch", "");
    },
    [setError, setValue]
  );

  const withMultipleVCSIntegrations = checkWithMultipleVCSIntegrations(formValues.provider);

  const { loading, data, error } = useQuery<GetRepositoriesGql>(GET_REPOSITORIES, {
    onError: handleError,
    variables: {
      provider: formValues.provider,
      vcsIntegrationId: withMultipleVCSIntegrations ? formValues.vcsIntegrationId : null,
    },
    onCompleted: ({ repositories }) => {
      clearErrors("repository");

      if (repositories) {
        const hasCurrentValueInList = repositoriesOptions.some(
          (option) => option.value === currentValue
        );

        if (currentValue && !hasCurrentValueInList) {
          setNotExistingRepoOption({
            value: currentValue,
            label: currentValue,
          });
        }

        if (!currentValue && repositories.length) {
          const { namespace, name } = repositories[0] ?? { name: null, namespace: null };

          setValue("repository", name, { shouldValidate: true });
          setValue("namespace", namespace);
        }
      }
    },
  });

  const repositoriesOptions = useMemo(() => {
    if (error || !data?.repositories) {
      return [];
    }

    const repositoriesOptions = data?.repositories.map((repository) => ({
      value: `${repository.namespace}/${repository.name}`,
      label: `${repository.namespace}/${repository.name}`,
    }));

    return repositoriesOptions;
  }, [data?.repositories, error]);

  const options = useMemo(
    () => [...(notExistingRepoOption ? [notExistingRepoOption] : []), ...repositoriesOptions],
    [notExistingRepoOption, repositoriesOptions]
  );

  const handleChange = useCallback(
    (value: string | null) => {
      const [namespace, repository] = parseRepository(value || "");

      if (namespace !== formValues.namespace || repository !== formValues.repository) {
        setValue("namespace", namespace);
        setValue("repository", repository, { shouldValidate: true });
        setValue("branch", "");
      }
    },
    [setValue, formValues]
  );

  const onInputValueSelected = useCallback(
    (value: string) => {
      setNotExistingRepoOption({
        value,
        label: value,
      });
    },
    [setNotExistingRepoOption]
  );
  const { comboBoxProps } = useComboBoxInputValueItem({
    onInputValueSelected: onInputValueSelected,
    shouldIncludeInputValueItem: isValidValue,
    options,
    inputValueItemProps: INPUT_VALUE_OPTION_PROPS,
  });

  const gitlabWarning =
    !loading && formValues.provider === VCS_PROVIDERS.Gitlab && !repositoriesOptions.length;

  if (formValues.provider === undefined) {
    return null;
  }

  return (
    <Controller
      name="repository"
      control={control}
      rules={{ required: "Repository is required" }}
      render={({ fieldState }) => (
        <Box direction="column" gap="medium">
          <ComboBox
            label="Repository"
            {...getTooltipAnalyticsProps("Source Code", "Repository", {
              provider: formValues.provider,
              version: analyticsVersion,
            })}
            tooltipInfo={
              <RepositoryTooltip vcsProvider={formValues.provider as VcsProvider} whitelistable />
            }
            error={(!loading && fieldState.error?.message) || undefined}
            value={currentValue}
            isLoading={loading}
            {...comboBoxProps}
            onChange={comboBoxProps.onChange(handleChange)}
          >
            {(item) => <ComboBoxDefaultItem id={item.value} {...item} />}
          </ComboBox>
          {gitlabWarning && (
            <Banner variant="warning">
              We are only able show repositories that you have maintainer-level access to.
            </Banner>
          )}
        </Box>
      )}
    />
  );
};

export default VcsRepositoryField;
