import Box from "ds/components/Box";
import Typography from "ds/components/Typography";
import { Spinner } from "components/Spinner";
import {
  ChevronDoubleLeft,
  ChevronDoubleRight,
  ArrowRight,
  ArrowLeft,
  ChevronDown,
} from "components/icons/generated";
import ButtonNew from "ds/components/Button/New";
import ButtonIconNew from "ds/components/ButtonIcon/New";
import Tooltip from "ds/components/Tooltip";
import DropdownMenu from "ds/components/DropdownMenu";
import DropdownMenuItem from "ds/components/DropdownMenu/Item";
import Icon from "ds/components/Icon";

import styles from "./styles.module.css";
import { PAGINATION_OPTIONS, TABLE_PAGINATION_ROWS_PER_PAGE_SELECT_ID } from "./constants";

type TablePaginationProps = {
  page: number;
  rowsPerPage: number;
  setRowsPerPage: (rows: number) => void;
  goToNextPage: () => void;
  goToPrevPage: () => void;
  goToLastPage: () => void;
  goToFirstPage: () => void;
  from: number;
  to: number;
  lastPage: number;
  itemsCount: number;
  loading: boolean;
  paginationOptions?: number[];
};

const TablePagination = ({
  page,
  rowsPerPage,
  setRowsPerPage,
  goToNextPage,
  goToPrevPage,
  goToLastPage,
  goToFirstPage,
  from,
  to,
  lastPage,
  itemsCount,
  loading,
  paginationOptions = PAGINATION_OPTIONS,
}: TablePaginationProps) => {
  const isFirstPage = page === 1;
  const isLastPage = page === lastPage;

  return (
    <Box
      className={styles.paginationContainer}
      align="center"
      justify="between"
      padding="medium large"
    >
      <Box align="center" gap="medium">
        <Typography variant="p-t7" tag="span" color="secondary">
          Rows per page:
        </Typography>
        <DropdownMenu
          triggerComponent={
            <ButtonNew
              id={TABLE_PAGINATION_ROWS_PER_PAGE_SELECT_ID}
              size="small"
              variant="secondary"
              endIcon={ChevronDown}
            >
              {String(rowsPerPage)}
            </ButtonNew>
          }
        >
          {paginationOptions.map((value, i) => (
            <DropdownMenuItem key={i} onAction={() => (setRowsPerPage(value), close())}>
              {value}
            </DropdownMenuItem>
          ))}
        </DropdownMenu>
      </Box>

      <Box align="center" gap="large">
        {loading && <Icon src={Spinner} />}
        {from && to && itemsCount ? (
          <Typography variant="p-t7" tag="span" color="secondary">
            {from} - {to} of {itemsCount}
          </Typography>
        ) : undefined}
        <Box align="center" gap="medium">
          <ButtonIconNew
            onPress={goToFirstPage}
            disabled={isFirstPage || loading}
            className={styles.paginationButton}
            variant="secondary"
            icon={ChevronDoubleLeft}
          >
            Go to first page
          </ButtonIconNew>

          <Tooltip
            on={(tooltipProps) => (
              <ButtonNew
                {...tooltipProps}
                onPress={goToPrevPage}
                disabled={isFirstPage || loading}
                size="small"
                variant="secondary"
                aria-label="Go to previous page"
                startIcon={ArrowLeft}
              >
                Prev
              </ButtonNew>
            )}
            placement="top"
          >
            Go to previous page
          </Tooltip>
          <Tooltip
            on={(tooltipProps) => (
              <ButtonNew
                {...tooltipProps}
                onPress={goToNextPage}
                disabled={isLastPage || loading}
                size="small"
                variant="secondary"
                aria-label="Go to next page"
                endIcon={ArrowRight}
              >
                Next
              </ButtonNew>
            )}
            placement="top"
          >
            Go to next page
          </Tooltip>
          {/* TODO: implement this button when backend provided */}
          <ButtonIconNew
            onPress={goToLastPage}
            disabled={isLastPage || loading}
            className={styles.paginationButton}
            variant="secondary"
            icon={ChevronDoubleRight}
          >
            Go to last page
          </ButtonIconNew>
        </Box>
      </Box>
    </Box>
  );
};

export default TablePagination;
