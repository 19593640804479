import { dropdownEllipsisWidth } from "ds/components/DropdownMenu/constants";
import { Spacing } from "types/Spacing";
import { SearchQueryOrderDirection } from "types/generated";
import { getDocsUrl } from "utils/getDocsUrl";

export const ITEMS_LIMIT = 50;
export const POLL_INTERVAL = 10000;

export enum SortableColumns {
  NAME = "name",
  SPACE = "space",
}

export const INITIAL_SORT_OPTION = SortableColumns.NAME;
export const INITIAL_SORT_DIRECTION = SearchQueryOrderDirection.Desc;

export const COLUMN_ORDER = `minmax(160px, 4.5fr) minmax(90px, 1fr) ${dropdownEllipsisWidth}`;
export const COLUMN_GAP: Spacing = "large";

export const VCS_INTEGRATIONS_DOCUMENTATION_LINK = getDocsUrl("/integrations/source-control");

export const GITHUB_INTEGRATION_DETAILS_DRAWER_ID = "github-integration-details-drawer";

export const BUILT_IN_GITHUB_INTEGRATION_NAME = "GitHub built-in integration";
export const BUILT_IN_GITHUB_INTEGRATION_DESCRIPTION =
  "This account was created from a GitHub account/organization, which is available for Stacks or Modules to use.";
