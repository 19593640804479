import { useCallback, useEffect, useRef } from "react";

import ListEntitiesItem from "components/ListEntitiesItem";
import ListEntitiesItemDescription from "components/ListEntitiesItem/Description";
import ListEntitiesItemLink from "components/ListEntitiesItem/Link";
import MetaInfoListItem from "components/MetaInfoList/Item";
import TagsList from "components/TagsList";
import { ExclamationMarkTriangleFilled, Incognito, Space } from "components/icons/generated";
import BadgeNext from "ds/components/BadgeNext";
import Box from "ds/components/Box";
import { useDrawerVisibilityForId } from "ds/components/DrawerNew/useDrawerVisibilityForId";
import Icon from "ds/components/Icon";
import Tooltip from "ds/components/Tooltip";
import { WorkerPool } from "types/generated";
import { hasSpaceManageAccess } from "utils/user";
import { NO_PRIVATE_WORKERS_AVAILABLE_MESSAGE } from "views/Account/WorkerPool/constants";

import WorkerPoolsListItemDropdown from "../ListItemDropdown";
import { COLUMN_GAP, COLUMN_ORDER } from "../constants";
import styles from "./styles.module.css";

type WorkerPoolListItemProps = {
  item: WorkerPool;
  onEdit: (workerPool: WorkerPool) => void;
  setRowHeight?: (size: number) => void;
};

const WorkerPoolListItem = ({ item, onEdit, setRowHeight }: WorkerPoolListItemProps) => {
  const workerPoolLink = `/worker-pool/${item.id}`;

  const rowRef = useRef<HTMLDivElement>(null);

  const handleEdit = useCallback(() => {
    onEdit(item);
  }, [onEdit, item]);

  const handleRowHeight = () => {
    if (setRowHeight && rowRef.current) {
      setRowHeight(rowRef.current.getBoundingClientRect().height);
    }
  };

  const hasUsers = item.usersCount > 0;
  const hasBusyWorkers = item.busyWorkers > 0;
  const runsCount = item.schedulableRunsCount;
  const hasWarning = item.workersCount === 0 && runsCount > 0;
  const canManageWorkerPools = hasSpaceManageAccess(item.spaceDetails.accessLevel);

  // on every render, update the row height
  useEffect(handleRowHeight);

  const isActive = useDrawerVisibilityForId(item.id);

  return (
    <ListEntitiesItem
      ref={rowRef}
      direction="row"
      justify="between"
      align="center"
      grid
      gridTemplate={COLUMN_ORDER}
      gap={`0 ${COLUMN_GAP}`}
      isActive={isActive}
      role="row"
    >
      <Box role="rowheader" gap="large" align="center">
        <Box direction="column" align="center" justify="center" className={styles.workerTypeIcon}>
          <Icon size="large" src={Incognito} />
        </Box>

        <Box grow="1">
          <Box direction="column" align="stretch" justify="center" fullWidth>
            <Box direction="row" align="start">
              <ListEntitiesItemLink href={workerPoolLink} title={item.name} />
            </Box>

            {item.description && (
              <Box direction="row" padding="small 0 0 0" align="start" fullWidth>
                <ListEntitiesItemDescription
                  noPadding
                  id={item.id}
                  description={item.description}
                />
              </Box>
            )}

            <TagsList tags={item.labels} onExpand={handleRowHeight} />
          </Box>
        </Box>
      </Box>

      {item.spaceDetails && (
        <MetaInfoListItem
          role="cell"
          icon={Space}
          linkText={item.spaceDetails.name}
          href={`/spaces/${item.spaceDetails.id}`}
        />
      )}

      <MetaInfoListItem role="cell">{item.workersCount}</MetaInfoListItem>

      <MetaInfoListItem role="cell">{item.busyWorkers}</MetaInfoListItem>

      <MetaInfoListItem role="cell">
        {hasWarning ? (
          <Tooltip
            on={(props) => (
              <BadgeNext
                variant="red"
                startIcon={ExclamationMarkTriangleFilled}
                text={`${runsCount}`}
                type="weak"
                {...props}
              />
            )}
          >
            {NO_PRIVATE_WORKERS_AVAILABLE_MESSAGE}
          </Tooltip>
        ) : (
          runsCount
        )}
      </MetaInfoListItem>

      <Box role="cell" direction="row" shrink="0" grow="0">
        <WorkerPoolsListItemDropdown
          workerPoolId={item.id}
          workerPoolName={item.name}
          canManageWorkerPools={canManageWorkerPools}
          hasBusyWorkers={hasBusyWorkers}
          hasUsers={hasUsers}
          onEdit={handleEdit}
        />
      </Box>
    </ListEntitiesItem>
  );
};

export default WorkerPoolListItem;
