import Typography from "ds/components/Typography";
import Box from "ds/components/Box";
import Code from "ds/components/Code";
import TextEllipsis from "ds/components/TextEllipsis";
import Timestamp from "components/time/Timestamp";
import ListEntitiesItem from "components/ListEntitiesItem";
import { useDrawerVisibilityForId } from "ds/components/DrawerNew/useDrawerVisibilityForId";
import ListEntitiesItemDescription from "components/ListEntitiesItem/Description";

import ContextVariablesListItemDropdown from "./Dropdown";
import { ContextVariablesListItemProps } from "./types";
import { COLUMN_ORDER_VARIABLES } from "../../constants";

const ContextVariablesListItem = ({ item, onEdit }: ContextVariablesListItemProps) => {
  const isActive = useDrawerVisibilityForId(item.id);

  const itemValue = item.value || "*****";

  return (
    <ListEntitiesItem
      isActive={isActive}
      grid
      gridTemplate={COLUMN_ORDER_VARIABLES}
      gap="0 large"
      role="row"
    >
      <Box role="rowheader" direction="column" justify="center" __deprecatedGap="0.2rem">
        <Box align="center" __deprecatedGap="1.2rem">
          <TextEllipsis tooltip={item.id} tooltipWidthMode="maxWidthSm">
            {(props) => (
              <Typography {...props} variant="p-t5" tag="h3">
                {item.id}
              </Typography>
            )}
          </TextEllipsis>
        </Box>
        {item.description && (
          <ListEntitiesItemDescription id={item.id} description={item.description} />
        )}
      </Box>
      <Box role="cell" align="center">
        <TextEllipsis tooltip={itemValue} tooltipWidthMode="maxWidthSm">
          {(props) => (
            <Code {...props} whiteSpaceVisible>
              {itemValue}
            </Code>
          )}
        </TextEllipsis>
      </Box>
      <Box role="cell" align="center">
        {item.createdAt && (
          <Typography tag="div" variant="p-body2">
            <Timestamp timestamp={item.createdAt} />
          </Typography>
        )}
      </Box>
      <Box role="cell" align="center" justify="end">
        <ContextVariablesListItemDropdown item={item} onEdit={onEdit} />
      </Box>
    </ListEntitiesItem>
  );
};

export default ContextVariablesListItem;
