import Link from "ds/components/LinkNew";
import TextEllipsis from "ds/components/TextEllipsis";
import Typography from "ds/components/Typography";
import { AuditActor } from "types/generated";
import { AnalyticsPageAuditTrail } from "hooks/useAnalytics/pages/auditTrail";
import useAnalytics from "hooks/useAnalytics";

import { AuditTrailEntrySuggestions } from "../../constants";

type AuditTrailListItemCreatedByProps = {
  value: AuditActor;
  applyFilter: (filterName: AuditTrailEntrySuggestions) => (value: string) => void;
};

const AuditTrailListItemCreatedBy = ({ value, applyFilter }: AuditTrailListItemCreatedByProps) => {
  const trackSegmentAnalyticsEvent = useAnalytics({
    page: AnalyticsPageAuditTrail.Logs,
  });

  const onLinkClicked = () => {
    trackSegmentAnalyticsEvent("Link clicked", {
      column: "Created by",
    });
  };
  return (
    <>
      {value.run === null && (
        <TextEllipsis tooltip={value.username} tooltipWidthMode="maxWidthSm">
          {(props) => (
            <Link
              {...props}
              onPress={() => {
                applyFilter(AuditTrailEntrySuggestions.Username)(value.username);
                onLinkClicked();
              }}
            >
              {value.username}
            </Link>
          )}
        </TextEllipsis>
      )}

      {value.run && (
        <Typography tag="span" variant="p-body2">
          Run&nbsp;
          <Link href={`/stack()/${value.run.stackId}/run/${value.run.id}`} onPress={onLinkClicked}>
            {value.run.id}
          </Link>
        </Typography>
      )}
    </>
  );
};

export default AuditTrailListItemCreatedBy;
