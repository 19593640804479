import {
  DocumentNode,
  FetchResult,
  MutationResult,
  TypedDocumentNode,
  useMutation,
} from "@apollo/client";
import { useCallback } from "react";

import FlashContext from "components/FlashMessages/FlashContext";
import { MutationHookCallback, MutationHandlerOptions } from "types/Mutation";
import { handleMutation } from "utils/mutations";

import useTypedContext from "./useTypedContext";

// * types are partially duplicated from apollo library
type UseMutationHandlerProps<TData, TVariables> = {
  gql: DocumentNode | TypedDocumentNode<TData, TVariables>;
  options?: MutationHandlerOptions<TData, TVariables>;
};

const useMutationHandler = <FetchData, MutationVariables>({
  gql,
  options,
}: UseMutationHandlerProps<FetchData, MutationVariables>): [
  (
    variables: MutationVariables,
    mutationCallback?: MutationHookCallback<FetchData>
  ) => Promise<void | FetchResult<FetchData>>,
  MutationResult<FetchData>,
] => {
  const { onError } = useTypedContext(FlashContext);
  const errorCallback = options?.onError || onError;

  const [mutation, mutationResult] = useMutation<FetchData, MutationVariables>(gql, {
    ...options,
    ...(!options?.propagateError && { onError: errorCallback }),
  });

  const handleMutationCallback = useCallback(
    (variables: MutationVariables, mutationCallback?: MutationHookCallback<FetchData>) => {
      return handleMutation({
        mutation,
        variables,
        mutationCallback,
        propagateError: options?.propagateError,
        errorCallback,
      });
    },
    [mutation, errorCallback, options?.propagateError]
  );

  return [handleMutationCallback, mutationResult];
};

/**
 * Create a mutation hook for use mostly on View Pages, Details, etc
 * * It UPDATES Apollo cache with the mutation result by default
 */
export const createDefaultMutationHook =
  <TData, TVariables>(gql: TypedDocumentNode<TData, TVariables>) =>
  (options: MutationHandlerOptions<TData, TVariables> = {}) => {
    return useMutationHandler({
      gql,
      options: {
        propagateError: false,
        awaitRefetchQueries: true,
        ...options,
      },
    });
  };

/**
 * Create a mutation hook for use in Bulk Actions
 * * It DOESN'T UPDATE Apollo cache with the mutation result
 */
export const createBulkMutationHook =
  <TData, TVariables>(gql: TypedDocumentNode<TData, TVariables>) =>
  (options: MutationHandlerOptions<TData, TVariables> = {}) => {
    return useMutationHandler({
      gql,
      options: {
        propagateError: true,
        fetchPolicy: "no-cache",
        ...options,
      },
    });
  };
