import Box from "ds/components/Box";
import LinkNew from "ds/components/LinkNew";
import TextEllipsis from "ds/components/TextEllipsis";

import styles from "./styles.module.css";

type DependencyLinkProps = {
  href: string;
  stackName: string;
  onPress: () => void;
};

export const DependencyLinkItem = ({ href, stackName, onPress }: DependencyLinkProps) => {
  return (
    <li>
      <Box fullWidth className={styles.stackName}>
        <TextEllipsis tooltip={stackName} tooltipWidthMode="maxWidthSm">
          {(tooltipReferenceProps) => (
            <LinkNew variant="secondary" href={href} onPress={onPress} {...tooltipReferenceProps}>
              <b>{stackName}</b>
            </LinkNew>
          )}
        </TextEllipsis>
      </Box>
    </li>
  );
};
