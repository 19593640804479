import { ReactNode, useRef } from "react";
import { Popover } from "react-aria-components";

import styles from "./styles.module.css";
type ListBoxPopoverProps = {
  children: ReactNode;
};

const ListBoxPopover = ({ children }: ListBoxPopoverProps) => {
  const scrollRef = useRef<HTMLDivElement>(null);

  return (
    <Popover className={styles.popover} maxHeight={300} scrollRef={scrollRef}>
      <div
        /** react-aria calculates the flip position including the scroll container. It doesn't make sense when we use the maxHeight since that's the maximum height we want it to flip. This way we prevent aria from flipping the popover prematurely. */
        ref={scrollRef}
      >
        {children}
      </div>
    </Popover>
  );
};

ListBoxPopover.displayName = "DS.ListBox.Popover";

export default ListBoxPopover;
