import { Incognito, Network, Space } from "components/icons/generated";
import ListEntitiesItem from "components/ListEntitiesItem";
import ListEntitiesItemDescription from "components/ListEntitiesItem/Description";
import ListEntitiesItemLink from "components/ListEntitiesItem/Link";
import MetaInfoListItem from "components/MetaInfoList/Item";
import TagsList from "components/TagsList";
import Box from "ds/components/Box";
import { useDrawerVisibilityForId } from "ds/components/DrawerNew/useDrawerVisibilityForId";
import Icon from "ds/components/Icon";
import TextEllipsis from "ds/components/TextEllipsis";
import Tooltip from "ds/components/Tooltip";
import Typography from "ds/components/Typography";

import { COLUMN_ORDER_PROVIDERS } from "../constants";
import ProviderListItemDropdown from "../ProviderListItemDropdown";
import { ProviderListItemProps } from "./types";

function ProviderListItem({ item, onEdit }: ProviderListItemProps) {
  const isDrawerVisible = useDrawerVisibilityForId(item.id);

  return (
    <ListEntitiesItem
      grid
      gridTemplate={COLUMN_ORDER_PROVIDERS}
      gap="0 large"
      isActive={isDrawerVisible}
      role="row"
    >
      <Box role="rowheader" direction="column" justify="center" gap="small">
        <Box align="center" gap="large">
          <ListEntitiesItemLink title={item.id} href={`/provider/${item.id}`} />

          <Tooltip
            on={(props) => <Icon {...props} src={item.public ? Network : Incognito} noShrink />}
          >
            {item.public ? "Public provider" : "Private provider"}
          </Tooltip>
        </Box>

        <Box direction="column">
          {item.description && (
            <ListEntitiesItemDescription id={item.id} description={item.description} noPadding />
          )}

          <TagsList tags={item.labels} key={item.labels.join()} />
        </Box>
      </Box>

      <Box role="cell" direction="column" justify="center">
        {item.space && (
          <MetaInfoListItem
            icon={Space}
            linkText={item.spaceDetails.name}
            href={`/spaces/${item.space}`}
          />
        )}
      </Box>

      <Box role="cell" direction="column" justify="center">
        <TextEllipsis tooltip={item.latestVersionNumber || "-"} tooltipWidthMode="maxWidthSm">
          {(props) => (
            <Typography {...props} tag="div" variant="p-body2">
              {item.latestVersionNumber || "-"}
            </Typography>
          )}
        </TextEllipsis>
      </Box>

      <Box role="cell" align="center">
        <ProviderListItemDropdown item={item} onEdit={onEdit} />
      </Box>
    </ListEntitiesItem>
  );
}

export default ProviderListItem;
