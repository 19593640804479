import { Controller, useFormContext } from "react-hook-form";
import { useCallback, useMemo, useState } from "react";
import { ApolloError, useQuery } from "@apollo/client";

import { SelectOption } from "ds/components/Select/types";
import { checkWithMultipleVCSIntegrations } from "utils/vcs";
import useComboBoxInputValueItem from "ds/components/ComboBox/useInputValueItem";
import ComboBox from "ds/components/ComboBox";
import ComboBoxDefaultItem from "ds/components/ComboBox/DefaultItem";

import { GET_BRANCHES, GetBranchesGql } from "./gql";
import { getTooltipAnalyticsProps } from "../utils";
import { SourceCodeSettingsField, SourceCodeProjects } from "../types";
import BranchesTooltip from "../BranchesTooltip";

type SourceCodeBranchesFieldProps = {
  analyticsVersion?: string;
  projectType: SourceCodeProjects;
};

const INPUT_VALUE_ITEM_PROPS = {
  label: "Can't find your branch? Select this option to add it anyway.",
};
const SourceCodeBranchesField = ({
  analyticsVersion,
  projectType,
}: SourceCodeBranchesFieldProps) => {
  const [notExistingBranchOption, setNotExistingBranchOption] = useState<SelectOption | undefined>(
    undefined
  );

  const { control, setValue, watch, setError, clearErrors } =
    useFormContext<SourceCodeSettingsField>();

  const formValues = watch();

  const handleError = useCallback(
    (error: ApolloError) => {
      setError("branch", {
        type: "custom",
        message: error.message,
      });
    },
    [setError]
  );

  const withMultipleVCSIntegrations = checkWithMultipleVCSIntegrations(formValues.provider);

  const { loading, data } = useQuery<GetBranchesGql>(GET_BRANCHES, {
    onError: handleError,
    variables: {
      provider: formValues.provider,
      namespace: formValues.namespace,
      repository: formValues.repository,
      repositoryURL: formValues.repositoryURL,
      vcsIntegrationId: withMultipleVCSIntegrations ? formValues.vcsIntegrationId : null,
    },
    onCompleted: ({ branches }) => {
      const currentValue = formValues.branch;
      const hasCurrentValueInList = branches.includes(currentValue);

      if (currentValue && !hasCurrentValueInList) {
        setNotExistingBranchOption({
          value: currentValue,
          label: currentValue,
        });
      } else {
        setNotExistingBranchOption(undefined);
      }

      const selectedBranch = currentValue || branches[0];

      setValue("branch", selectedBranch, { shouldValidate: true, shouldDirty: true });
      clearErrors("branch");
    },
  });

  const branchesOptions: SelectOption[] = useMemo(
    () => data?.branches.map((value) => ({ label: value, value })) ?? [],
    [data?.branches]
  );

  const options = useMemo(
    () => [...(notExistingBranchOption ? [notExistingBranchOption] : []), ...branchesOptions],
    [branchesOptions, notExistingBranchOption]
  );

  const onInputValueSelected = useCallback(
    (value: string) => {
      setNotExistingBranchOption({
        value,
        label: value,
      });
    },
    [setNotExistingBranchOption]
  );

  const { comboBoxProps } = useComboBoxInputValueItem({
    onInputValueSelected,
    options,
    inputValueItemProps: INPUT_VALUE_ITEM_PROPS,
  });

  return (
    <Controller
      name="branch"
      control={control}
      rules={{ required: "Branch is required" }}
      render={({ field, fieldState }) => (
        <ComboBox
          label="Branch"
          {...getTooltipAnalyticsProps("Source Code", "Branch", projectType, {
            provider: formValues.provider,
            version: analyticsVersion,
          })}
          tooltipInfo={<BranchesTooltip projectType={projectType} />}
          error={(!loading && fieldState.error?.message) || undefined}
          value={field.value}
          {...comboBoxProps}
          onChange={comboBoxProps.onChange(field.onChange)}
          isLoading={loading}
        >
          {(item) => <ComboBoxDefaultItem id={item.value} {...item} />}
        </ComboBox>
      )}
    />
  );
};

export default SourceCodeBranchesField;
