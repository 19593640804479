import { SpacesLayoutMode } from "./types";

export const SPACES_LAYOUT_MODE_LOCAL_STORAGE_KEY = "spaces-layout-mode";
export const DEFAULT_SPACES_LAYOUT_MODE = SpacesLayoutMode.Diagram;
export const POLL_INTERVAL = 10000;

export const CREATE_NEW_URL_PARAM = "create-new";
export const PARENT_ID_URL_PARAM = "parentId";
export const FILTERS_ORDER_SETTINGS_KEY = "spacesFiltersOrder";

export const INTERACTIVE_ATTRIBUTE_FOR_DRAWER = "data-space-node-element-interactive";
