import { useQuery } from "@apollo/client";
import { useMemo } from "react";
import { decodeTime } from "ulid";

import FlashContext from "components/FlashMessages/FlashContext";
import useTypedContext from "hooks/useTypedContext";
import ButtonNew from "ds/components/Button/New";
import Box from "ds/components/Box";
import CardWrapper from "components/CardWrapper";
import EmptyState from "ds/components/EmptyState";
import { EmptystateWebhooksColored } from "components/icons/generated";
import DocumentationButton from "components/DocumentationButton";
import { getDocsUrl } from "utils/getDocsUrl";
import SortableTable, { SortableTableColumn } from "components/SortableTable";
import ListEntitiesNew from "components/ListEntitiesNew";
import { SearchQueryOrderDirection, WebhooksIntegration } from "types/generated";
import PageLoading from "components/loading/PageLoading";
import { WebhooksTierInfo } from "components/TierInfo/WebhooksTierInfo";

import { ModuleSettingsContext } from "../Context";
import { GET_MODULE_WEBHOOKS } from "./gql";
import ModuleSettingsIntegrationsPageLayout from "./components/PageLayout";
import { COLUMN_ORDER } from "./components/WebhookListItem/constants";
import WebhookListItemVirtualized from "./components/WebhookListItem/Virtualized";
import { GetModuleWebhooksGql } from "./types";
import { showWebhookDrawerForm } from "./components/WebhookFormDrawer";
import { showWebhookDetailsDrawer } from "./components/WebhookDetailsDrawer";

const COLUMNS: SortableTableColumn[] = [
  {
    value: "endpoint",
    label: "Endpoint",
  },
  {
    value: "enabled",
    label: "Enabled",
  },
  {
    value: "createdAt",
    label: "Created at",
  },
];

const ModuleSettingsIntegrationsWebhooks = () => {
  const { onError } = useTypedContext(FlashContext);
  const { module, canManageModule } = useTypedContext(ModuleSettingsContext);

  const { loading, data } = useQuery<GetModuleWebhooksGql>(GET_MODULE_WEBHOOKS, {
    onError,
    variables: {
      moduleId: module.id,
    },
    fetchPolicy: "no-cache",
  });

  const webhooks = useMemo(() => {
    const items = data?.module?.integrations?.webhooks || [];

    return items.map((item) => ({
      ...item,
      createdAt: decodeTime(item.id) / 1000,
    }));
  }, [data?.module?.integrations?.webhooks]);

  const handleOnEditClick = (item: WebhooksIntegration) => {
    showWebhookDrawerForm({
      id: item.id,
      webhook: item,
      moduleId: module.id,
    });
  };

  const handleOnDetailsClick = (item: WebhooksIntegration) => {
    showWebhookDetailsDrawer({
      id: item.id,
      webhook: item,
    });
  };

  const handleOnCreateClick = () => {
    showWebhookDrawerForm({
      moduleId: module.id,
    });
  };

  return (
    <>
      <ModuleSettingsIntegrationsPageLayout
        actions={
          <ButtonNew
            variant="primary"
            onPress={handleOnCreateClick}
            disabled={!canManageModule || loading}
          >
            Create webhook
          </ButtonNew>
        }
      >
        <WebhooksTierInfo />

        {loading && !data && <PageLoading />}

        {webhooks.length > 0 && (
          <SortableTable
            ariaLabel="Webhooks"
            items={webhooks}
            columns={COLUMNS}
            columnOrder={COLUMN_ORDER}
            renderItems={(items) => (
              <ListEntitiesNew
                itemCount={items.length}
                itemProps={{
                  items,
                  moduleId: module.id,
                  onEditClick: handleOnEditClick,
                  onDetailsClick: handleOnDetailsClick,
                  canManageModule,
                }}
                virtualizedItem={WebhookListItemVirtualized}
                itemKey={(index) => items[index].id}
              />
            )}
            initialSortBy="id"
            initialDirection={SearchQueryOrderDirection.Desc}
          />
        )}

        {webhooks.length === 0 && !loading && (
          <Box align="center" justify="center" grow="1" fullWidth>
            <CardWrapper variant="filled" direction="column">
              <EmptyState
                padding="large"
                icon={EmptystateWebhooksColored}
                title="You do not have any webhooks yet"
                caption="Set up a webhook for your current module to send events to a http endpoint of your choice."
              >
                <Box gap="medium">
                  {canManageModule && (
                    <ButtonNew variant="primary" onPress={handleOnCreateClick}>
                      Create webhook
                    </ButtonNew>
                  )}
                  <DocumentationButton
                    to={getDocsUrl("/integrations/webhooks")}
                    label="Documentation"
                  />
                </Box>
              </EmptyState>
            </CardWrapper>
          </Box>
        )}
      </ModuleSettingsIntegrationsPageLayout>
    </>
  );
};

export default ModuleSettingsIntegrationsWebhooks;
