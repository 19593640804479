import React, { forwardRef } from "react";
import cx from "classnames";

import styles from "./styles.module.css";
import Typography from "../Typography";

type CodeProps = {
  children: React.ReactNode;
  className?: string;
  whiteSpaceVisible?: boolean;
};

const Code = forwardRef<HTMLDivElement, CodeProps>(function Code(
  { children, className, whiteSpaceVisible },
  ref
) {
  return (
    <div
      ref={ref}
      className={cx(styles.code, whiteSpaceVisible && styles.whiteSpaceVisible, className)}
    >
      <Typography variant="s-body3" tag="code">
        {children}
      </Typography>
    </div>
  );
});

Code.displayName = "DS.Code";

export default Code;
