import BadgeNext from "ds/components/BadgeNext";
import { BadgeVariant } from "ds/components/BadgeNext/types";
import { VersionState } from "types/generated";

type ModuleVersionStateBadgeProps = {
  state: VersionState | null | undefined;
  yanked?: boolean | null;
  number: string | null | undefined;
  className?: string;
};

const ModuleVersionStateBadge = ({
  state,
  className,
  number,
  yanked,
}: ModuleVersionStateBadgeProps) => {
  let variant = BadgeVariant.blue;

  if (yanked || state === VersionState.Failed) {
    variant = BadgeVariant.red;
  } else if (state === VersionState.Pending) {
    variant = BadgeVariant.yellow;
  } else if (state === VersionState.Active) {
    variant = BadgeVariant.green;
  }

  let text = "NEW";

  if (number) {
    text = number;
  }

  return (
    <BadgeNext textEllipsis variant={variant} type="strong" className={className} text={text} />
  );
};

export default ModuleVersionStateBadge;
