import { useCallback, useRef } from "react";
import { Link } from "react-router-dom";
import cx from "classnames";

import Box from "ds/components/Box";
import { Run, RunReviewDecision, Stack, StackConfigVendor } from "types/generated";
import DeltaCounts from "components/DeltaCounts";
import ListEntitiesItem from "components/ListEntitiesItem";
import VendorVersion from "components/VendorVersion";
import useTypedContext from "hooks/useTypedContext";
import RunStateBadge from "views/shared/RunNext/components/StateBadge";
import { getRuntimeConfigVendorInfo, getVendorName } from "utils/vendor";
import Button from "ds/components/Button";
import FlashContext from "components/FlashMessages/FlashContext";
import { AnalyticsPageStack } from "hooks/useAnalytics/pages/stack";
import useAnalytics from "hooks/useAnalytics";
import useReviewRun from "shared/Run/useReviewRun";
import { isRunReviewable } from "shared/Run/useReviewRun/accessValidation";
import { StackContext } from "views/Stack/Context";
import Toggle from "ds/components/Toggle";

import styles from "./styles.module.css";
import { COLUMN_GAP, COLUMN_ORDER_APPROVAL, COLUMN_ORDER_USER } from "../constants";
import RunMetaInfoListItem from "../MetaInfoListItem";

type RunElementProps = {
  stackId: Stack["id"];
  run: Run;
  checked: boolean;
  onCheck: (item: Run, checked: boolean, hash: string) => void;
  vendorConfig?: StackConfigVendor;
  hash: string;
  isAnsible?: boolean;
};

const RunElement = (props: RunElementProps) => {
  const { stackId, vendorConfig, run, checked, onCheck, hash, isAnsible } = props;

  const trackSegmentAnalyticsEvent = useAnalytics({
    page: AnalyticsPageStack.StacksRunList,
    defaultCallbackTrackProperties: {
      stackVendor: getVendorName(vendorConfig?.__typename),
    },
  });

  const { reportSuccess } = useTypedContext(FlashContext);
  const { hasAtLeastWriteAccess } = useTypedContext(StackContext);

  const rowRef = useRef<HTMLDivElement>(null);

  const link = `/stack/${stackId}/run/${run.id}`;

  const vendorDetails =
    (vendorConfig &&
      run.runtimeConfig &&
      getRuntimeConfigVendorInfo(vendorConfig, run.runtimeConfig)) ||
    undefined;

  const vendorIcons = vendorDetails && (
    <>
      <VendorVersion icon={vendorDetails.vendor.icon} text={vendorDetails.vendor.text} />
      {vendorDetails.vendorTool && (
        <VendorVersion icon={vendorDetails.vendorTool.icon} text={vendorDetails.vendorTool.text} />
      )}
    </>
  );

  const handleOnChange = useCallback(
    () => onCheck(run, !checked, hash),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onCheck, checked]
  );
  const [addRunReview, { loading: approveRunLoading }] = useReviewRun({
    refetchQueries: ["ListRuns"],
  });

  const onApproveRun = () =>
    addRunReview(
      { stack: stackId, run: run.id, decision: RunReviewDecision.Approve, note: "" },
      (data) => {
        if (data?.runReview.id) {
          trackSegmentAnalyticsEvent("Run approved");
          reportSuccess({ message: "Run has been approved" });
        }
      }
    );
  const runNeedsApproval = isRunReviewable(run);
  const runCanBeApproved = runNeedsApproval && hasAtLeastWriteAccess;

  return (
    <ListEntitiesItem
      ref={rowRef}
      direction="row"
      align="center"
      grid
      gridTemplate={hasAtLeastWriteAccess ? COLUMN_ORDER_APPROVAL : COLUMN_ORDER_USER}
      gap={COLUMN_GAP}
      className={cx(styles.item, {
        [styles.needsApproval]: runNeedsApproval,
      })}
      role="row"
    >
      {hasAtLeastWriteAccess && (
        <Box role="cell">
          <Toggle
            variant="checkbox"
            id={run.id}
            onChange={handleOnChange}
            isSelected={checked}
            aria-label={checked ? "Unselect this run" : "Select this run"}
          />
        </Box>
      )}
      <Box role="cell" direction="column">
        <Link className={styles.focusOutline} to={link}>
          <Box>
            <RunStateBadge state={run.state} />
          </Box>
        </Link>
      </Box>

      <Box role="rowheader" direction="column">
        <Link className={styles.focusOutline} to={link}>
          <RunMetaInfoListItem run={run} stackId={stackId} />
        </Link>
      </Box>

      <Box role="cell" direction="column">
        <Link
          to={link}
          className={cx(styles.link, styles.focusOutline)}
          aria-label="Go to this run"
        >
          {vendorIcons}
        </Link>
      </Box>

      <Box align="center" role="cell">
        {run.delta && (
          <DeltaCounts
            compact
            link={link}
            linkAriaLabel="Go to this run"
            className={styles.delta}
            delta={run.delta}
            isAnsible={isAnsible}
          />
        )}
      </Box>
      <Box role="cell" justify="end">
        {runCanBeApproved && (
          <Button
            onClick={onApproveRun}
            variant="secondary"
            size="small"
            disabled={approveRunLoading}
            loading={approveRunLoading}
          >
            Approve
          </Button>
        )}
      </Box>
    </ListEntitiesItem>
  );
};

export default RunElement;
