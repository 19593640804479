import React, { useCallback, useEffect } from "react";
import { Location } from "react-router-dom";

import ViewHeader from "components/ViewHeader";
import ViewHeaderTitle from "components/ViewHeader/Title";
import useTypedContext from "hooks/useTypedContext";
import { AccountContext } from "views/AccountWrapper";
import useTitle from "hooks/useTitle";
import ViewHeaderWrapper from "components/ViewHeader/Wrapper";
import DocumentationIconButton from "components/DocumentationIconButton";
import SegmentedControl from "ds/components/SegmentedControl";
import { Diagram, List } from "components/icons/generated";
import Icon from "ds/components/Icon";
import Typography from "ds/components/Typography";
import Box from "ds/components/Box";
import SearchInput from "components/SearchInput";
import { getDocsUrl } from "utils/getDocsUrl";
import ButtonNew from "ds/components/Button/New";
import { isLegacySpace, isRootSpace } from "utils/space";

import styles from "./styles.module.css";
import { SpacesLayoutMode } from "./types";
import { FILTERS_ORDER_SETTINGS_KEY } from "./constants";
import { SpacesViewActionsContext } from "./ActionsContext";
import { SpacesViewContext } from "./Context";
import { showSpaceDrawer } from "./SpacesDrawer";

const layoutModeOptions = [
  {
    label: (
      <Box gap="medium" align="center">
        <Icon src={List} />
        <Typography tag="span" variant="p-t7">
          List
        </Typography>
      </Box>
    ),
    value: SpacesLayoutMode.List,
    tooltip: "Switch to list view",
  },
  {
    label: (
      <Box gap="medium" align="center">
        <Icon src={Diagram} />
        <Typography tag="span" variant="p-t7">
          Diagram
        </Typography>
      </Box>
    ),
    value: SpacesLayoutMode.Diagram,
    tooltip: "Switch to diagram view",
  },
];

type SpacesPageLayoutProps = {
  children: React.ReactNode;
  setLayoutMode: (mode: SpacesLayoutMode) => void;
  layoutMode: SpacesLayoutMode;
};

const SpacesPageLayout = ({ children, setLayoutMode, layoutMode }: SpacesPageLayoutProps) => {
  const { accountName } = useTypedContext(AccountContext);

  const { isDrawerVisible, currentSpace, drawerMode, isRootAdmin } =
    useTypedContext(SpacesViewContext);
  const { onCreate: onSpaceCreate } = useTypedContext(SpacesViewActionsContext);

  useTitle(`Spaces · ${accountName}`);

  const { onCloseDrawer } = useTypedContext(SpacesViewActionsContext);

  const isCreateMode = drawerMode === "create";

  const isPredefinedSpace =
    !!currentSpace?.id && (isRootSpace(currentSpace.id) || isLegacySpace(currentSpace.id));

  const shouldCloseOnLocationChange = useCallback(({ current }: { current: Location }) => {
    return !current.pathname.includes("/spaces");
  }, []);

  useEffect(() => {
    if (isDrawerVisible) {
      showSpaceDrawer({
        drawerMode:
          (isRootAdmin && (!isPredefinedSpace || isCreateMode) && drawerMode) || undefined,
        space: currentSpace,
        isRootAdmin: isRootAdmin,
        layoutMode,
        onClose: onCloseDrawer,
        shouldCloseOnLocationChange,
      });
    }
  }, [
    isDrawerVisible,
    currentSpace,
    isCreateMode,
    drawerMode,
    isPredefinedSpace,
    isRootAdmin,
    layoutMode,
    onCloseDrawer,
    shouldCloseOnLocationChange,
  ]);

  return (
    <>
      <ViewHeader firstLevel>
        <ViewHeaderTitle titleSize="p-t3">Spaces</ViewHeaderTitle>

        <ViewHeaderWrapper direction="row">
          {layoutMode === SpacesLayoutMode.List && (
            <SearchInput
              placeholder="Search spaces by name or id"
              filtersOrderSettingsKey={FILTERS_ORDER_SETTINGS_KEY}
            />
          )}

          <SegmentedControl
            optionClassName={styles.layoutSwitchOption}
            onChange={setLayoutMode}
            value={layoutMode}
            options={layoutModeOptions}
          />

          <DocumentationIconButton
            href={getDocsUrl("/concepts/spaces")}
            tooltipText="Go to spaces documentation"
          />

          {isRootAdmin && (
            <ButtonNew onPress={() => onSpaceCreate()} variant="primary">
              Create space
            </ButtonNew>
          )}
        </ViewHeaderWrapper>
      </ViewHeader>

      {children}
    </>
  );
};

export default SpacesPageLayout;
