import { useMemo } from "react";
import { useQuery } from "@apollo/client";

import useTypedContext from "hooks/useTypedContext";
import SearchInput from "components/SearchInput";
import EmptyState from "ds/components/EmptyState";
import { EmptystateKeyColored, EmptystateSearchNoResultsColored } from "components/icons/generated";
import FlashContext from "components/FlashMessages/FlashContext";
import useErrorHandle from "hooks/useErrorHandle";
import PageLoading from "components/loading/PageLoading";
import { SearchQueryOrderDirection } from "types/generated";
import Box from "ds/components/Box";
import { fuzzySearch } from "utils/fuzzySearch";
import useURLParams from "hooks/useURLParams";
import { URL_SEARCH_KEY } from "constants/url_query_keys";
import useTitle from "hooks/useTitle";
import { AccountContext } from "views/AccountWrapper";
import DocumentationButton from "components/DocumentationButton";
import SortableTable, { SortableTableColumn } from "components/SortableTable";
import PageInfo from "components/PageWrapper/Info";
import { getDocsUrl } from "utils/getDocsUrl";
import ButtonNew from "ds/components/Button/New";
import CollapsibleSectionPanel from "components/CollapsibleSectionPanel";

import TerraformRegistryViewHeader from "./ViewHeader";
import { showCreateGpgKeyDrawer } from "./CreateGpgKeyDrawer";
import { GET_GPG_KEYS } from "./gql";
import GpgKeyListItem from "./GpgKeyListItem";
import { COLUMN_ORDER } from "./constants";
import { GetGpgKeysGql } from "./types";

const activeGpgKeysColumns: SortableTableColumn[] = [
  {
    value: "name",
    label: "Name",
  },
  {
    value: "id",
    label: "ID",
  },
  {
    value: "createdBy",
    label: "Created by",
  },
  {
    value: "createdAt",
    label: "Created",
  },
];

const revokedGpgKeysColumns: SortableTableColumn[] = [
  {
    value: "name",
    label: "Name",
  },
  {
    value: "id",
    label: "ID",
  },
  {
    value: "revokedBy",
    label: "Revoked by",
  },
  {
    value: "revokedAt",
    label: "Revoked",
  },
];

function GpgKeys() {
  const { onError } = useTypedContext(FlashContext);
  const { accountName, viewer } = useTypedContext(AccountContext);

  const { error, loading, data } = useQuery<GetGpgKeysGql>(GET_GPG_KEYS, { onError });
  const urlParams = useURLParams();
  const searchQuery = urlParams.get(URL_SEARCH_KEY);

  const [activeGpgKeys, revokedGpgKeys] = useMemo(() => {
    if (!data?.gpgKeys.length) {
      return [[], []];
    }

    let filteredGpgKeys = data.gpgKeys;

    if (searchQuery) {
      filteredGpgKeys = fuzzySearch(filteredGpgKeys, searchQuery.trim(), {
        keys: ["description", "name", "id"],
        scoreThreshold: -1000,
      });
    }

    return [
      filteredGpgKeys.filter((gpgKey) => !gpgKey.revokedAt),
      filteredGpgKeys.filter((gpgKey) => gpgKey.revokedAt),
    ];
  }, [data, searchQuery]);

  useTitle(`GPG Keys · ${accountName}`);

  const ErrorContent = useErrorHandle(error);

  if (ErrorContent) {
    return ErrorContent;
  }

  const isLoading = loading;
  const isPageEmpty = !isLoading && !error && data?.gpgKeys.length === 0;
  const hasNoQueryResults =
    activeGpgKeys.length === 0 && revokedGpgKeys.length === 0 && searchQuery;

  return (
    <>
      <TerraformRegistryViewHeader />
      <PageInfo title="GPG Keys">
        <SearchInput
          placeholder="Search by ID, name and description..."
          filtersOrderSettingsKey="gpgKeysFiltersOrder"
          disabled={isLoading}
        />
        {viewer.admin && (
          <ButtonNew onPress={showCreateGpgKeyDrawer} variant="primary" disabled={isLoading}>
            Register GPG key
          </ButtonNew>
        )}
      </PageInfo>
      {isLoading && <PageLoading />}

      {!isLoading && isPageEmpty && !hasNoQueryResults && (
        <EmptyState
          title="Register your first GPG key"
          icon={EmptystateKeyColored}
          caption="GPG keys are used to sign provider archives."
        >
          <Box gap="medium">
            <DocumentationButton
              to={getDocsUrl("/vendors/terraform/provider-registry#register-a-gpg-key")}
              label="Documentation"
            />
            {viewer.admin && (
              <ButtonNew variant="primary" onPress={showCreateGpgKeyDrawer}>
                Register GPG key
              </ButtonNew>
            )}
          </Box>
        </EmptyState>
      )}
      {!isLoading && hasNoQueryResults && (
        <EmptyState title="No results" icon={EmptystateSearchNoResultsColored} announce />
      )}
      {!isLoading && !isPageEmpty && (
        <Box __deprecatedGap="1rem" direction="column">
          {activeGpgKeys.length > 0 && (
            <CollapsibleSectionPanel
              contentPadding="0"
              title="Active"
              initialIsCollapsed={false}
              ariaLevel={3}
              count={activeGpgKeys.length}
            >
              <SortableTable
                items={activeGpgKeys}
                renderItems={(sorted) =>
                  sorted.map((item) => <GpgKeyListItem key={item.id} item={item} />)
                }
                columnOrder={COLUMN_ORDER}
                columns={activeGpgKeysColumns}
                initialSortBy="createdAt"
                initialDirection={SearchQueryOrderDirection.Desc}
                ariaLabel="Active"
              />
            </CollapsibleSectionPanel>
          )}

          {revokedGpgKeys.length > 0 && (
            <CollapsibleSectionPanel
              contentPadding="0"
              title="Revoked"
              initialIsCollapsed={false}
              ariaLevel={3}
              count={revokedGpgKeys.length}
            >
              <SortableTable
                items={revokedGpgKeys}
                renderItems={(sorted) =>
                  sorted.map((item) => <GpgKeyListItem key={item.id} item={item} />)
                }
                columnOrder={COLUMN_ORDER}
                columns={revokedGpgKeysColumns}
                initialSortBy="revokedAt"
                initialDirection={SearchQueryOrderDirection.Desc}
                ariaLabel="Revoked"
              />
            </CollapsibleSectionPanel>
          )}
        </Box>
      )}
    </>
  );
}

export default GpgKeys;
