import { Controller, useFormContext } from "react-hook-form";
import { useCallback, useMemo, useState } from "react";
import { ApolloError, useQuery } from "@apollo/client";

import ComboBoxDefaultItem from "ds/components/ComboBox/DefaultItem";
import { VCS_PROVIDERS } from "constants/vcs_providers";
import { SelectOption } from "ds/components/Select/types";
import Banner from "ds/components/Banner";
import Box from "ds/components/Box";
import { VcsProvider } from "types/generated";
import { checkWithMultipleVCSIntegrations } from "utils/vcs";
import ComboBox from "ds/components/ComboBox";
import useComboBoxInputValueItem from "ds/components/ComboBox/useInputValueItem";

import RepositoryTooltip from "../RepositoryTooltip";
import { GET_REPOSITORIES, GetRepositoriesGql } from "./gql";
import { getTooltipAnalyticsProps } from "../utils";
import { parseRepository } from "./helpers";
import { SourceCodeSettingsField, SourceCodeProjects } from "../types";

const isValidValue = (value: string) => {
  const trimmedValue = value.trim();

  const [namespace, repository] = trimmedValue.split("/");

  return !!namespace && !!repository;
};

const INPUT_VALUE_ITEM_PROPS = {
  label: "Can't find your repository? Select this option to add it anyway.",
};

type SourceCodeRepositoryFieldProps = {
  analyticsVersion?: string;
  projectType: SourceCodeProjects;
};

const SourceCodeRepositoryField = ({
  analyticsVersion,
  projectType,
}: SourceCodeRepositoryFieldProps) => {
  const { control, setValue, watch, setError, clearErrors } =
    useFormContext<SourceCodeSettingsField>();
  const [notExistingRepoOption, setNotExistingRepoOption] = useState<SelectOption | undefined>(
    undefined
  );

  const formValues = watch();

  const currentValue =
    formValues.namespace && formValues.repository
      ? `${formValues.namespace}/${formValues.repository}`
      : null;

  const handleError = useCallback(
    (error: ApolloError) => {
      setError("repository", {
        type: "custom",
        message: error.message,
      });
      setValue("branch", "", { shouldDirty: true });
    },
    [setError, setValue]
  );

  const withMultipleVCSIntegrations = checkWithMultipleVCSIntegrations(formValues.provider);

  const { loading, data, error } = useQuery<GetRepositoriesGql>(GET_REPOSITORIES, {
    onError: handleError,
    variables: {
      provider: formValues.provider,
      vcsIntegrationId: withMultipleVCSIntegrations ? formValues.vcsIntegrationId : null,
    },
    onCompleted: ({ repositories }) => {
      clearErrors("repository");

      if (repositories && repositories.length) {
        const hasCurrentValueInList = repositories.some(
          (repo) => `${repo.namespace}/${repo.name}` === currentValue
        );

        if (currentValue && !hasCurrentValueInList) {
          setNotExistingRepoOption({
            value: currentValue,
            label: currentValue,
          });
        }

        if (!currentValue && repositories.length) {
          const { namespace, name } = repositories[0] ?? { name: null, namespace: null };

          setValue("repository", name, { shouldValidate: true, shouldDirty: true });
          setValue("namespace", namespace, { shouldValidate: true, shouldDirty: true });
        }
      }
    },
  });

  const repositoriesOptions = useMemo(() => {
    if (error || !data?.repositories) {
      return [];
    }

    const repositoriesOptions = data?.repositories.map((repository) => ({
      value: `${repository.namespace}/${repository.name}`,
      label: `${repository.namespace}/${repository.name}`,
    }));

    return repositoriesOptions;
  }, [data?.repositories, error]);

  const options = useMemo(
    () => [...(notExistingRepoOption ? [notExistingRepoOption] : []), ...repositoriesOptions],
    [notExistingRepoOption, repositoriesOptions]
  );

  const handleChange = useCallback(
    (value: string | null) => {
      const [namespace, repository] = parseRepository(value || "");

      if (namespace !== formValues.namespace || repository !== formValues.repository) {
        setValue("namespace", namespace, { shouldValidate: true, shouldDirty: true });
        setValue("repository", repository, { shouldValidate: true, shouldDirty: true });
        setValue("branch", "", { shouldDirty: true });
      }
    },
    [setValue, formValues]
  );

  const gitlabWarning =
    !loading && formValues.provider === VCS_PROVIDERS.Gitlab && !repositoriesOptions.length;

  const onInputValueSelected = useCallback(
    (value: string) => {
      setNotExistingRepoOption({
        value,
        label: value,
      });
    },
    [setNotExistingRepoOption]
  );

  const { comboBoxProps } = useComboBoxInputValueItem({
    onInputValueSelected,
    shouldIncludeInputValueItem: isValidValue,
    options,
    inputValueItemProps: INPUT_VALUE_ITEM_PROPS,
  });

  if (formValues.provider === undefined) {
    return null;
  }

  return (
    <Controller
      name="repository"
      control={control}
      rules={{ required: "Repository is required" }}
      render={({ fieldState }) => (
        <Box direction="column" gap="medium">
          <ComboBox
            label="Repository"
            {...getTooltipAnalyticsProps("Source Code", "Repository", projectType, {
              provider: formValues.provider,
              version: analyticsVersion,
            })}
            tooltipInfo={
              <RepositoryTooltip
                vcsProvider={formValues.provider as VcsProvider}
                whitelistable
                projectType={projectType}
              />
            }
            error={(!loading && fieldState.error?.message) || undefined}
            value={currentValue}
            isLoading={loading}
            {...comboBoxProps}
            onChange={comboBoxProps.onChange(handleChange)}
          >
            {(item) => <ComboBoxDefaultItem id={item.value} {...item} />}
          </ComboBox>
          {gitlabWarning && (
            <Banner variant="warning">
              We are only able show repositories that you have maintainer-level access to.
            </Banner>
          )}
        </Box>
      )}
    />
  );
};

export default SourceCodeRepositoryField;
