import { gql, TypedDocumentNode } from "@apollo/client";

import {
  Maybe,
  Module,
  Scalars,
  WebhookDeliveryDetails,
  WebhooksIntegration,
} from "types/generated";

export const GET_MODULE_WEBHOOKS = gql`
  query GetModuleWebhooks($moduleId: ID!) {
    module(id: $moduleId) {
      integrations {
        webhooks {
          deleted
          enabled
          endpoint
          id
        }
      }
    }
  }
`;

type GetModuleWebhookDeliveryDetailsArgs = {
  deliveryId: Scalars["String"]["input"];
  webhookId: Scalars["ID"]["input"];
  moduleId: Scalars["ID"]["input"];
};

type GetModuleWebhookDeliveryDetailsData = {
  module: Maybe<{
    id: Module["id"];
    integrations: Maybe<{
      webhook: Maybe<{
        id: WebhooksIntegration["id"];
        deliveryDetails: Maybe<WebhookDeliveryDetails>;
      }>;
    }>;
  }>;
};

export const GET_MODULE_WEBHOOK_DELIVERY_DETAILS: TypedDocumentNode<
  GetModuleWebhookDeliveryDetailsData,
  GetModuleWebhookDeliveryDetailsArgs
> = gql`
  query GetModuleWebhookDeliveryDetails($deliveryId: String!, $webhookId: ID!, $moduleId: ID!) {
    module(id: $moduleId) {
      id
      integrations {
        webhook(id: $webhookId) {
          id
          deliveryDetails(key: $deliveryId) {
            requestHeaders {
              name
              values
            }
            requestBody
            responseHeaders {
              name
              values
            }
            responseError
            responseBody
          }
        }
      }
    }
  }
`;

export const GET_MODULE_WEBHOOK = gql`
  query GetModuleWebhook($moduleId: ID!, $id: ID!, $nextToken: String) {
    module(id: $moduleId) {
      id
      integrations {
        webhook(id: $id) {
          id
          deliveries(token: $nextToken) {
            nextToken
            nodes {
              id
              timestamp
              status
            }
          }
        }
      }
    }
  }
`;

export const GET_MODULE_ATTACHED_GCP_INTEGRATION = gql`
  query GetModuleAttachedGcpIntegration($moduleId: ID!) {
    module(id: $moduleId) {
      integrations {
        gcp {
          activated
          serviceAccountEmail
          tokenScopes
        }
      }
    }
  }
`;
