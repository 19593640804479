import { ReactNode, useContext, useMemo } from "react";
import { SelectStateContext, SelectValue } from "react-aria-components";
import cx from "classnames";

import AriaButtonInput, { AriaButtonInputProps } from "ds/components/Input/AriaButtonInput";
import Icon from "ds/components/Icon";
import { ChevronDown } from "components/icons/generated";

import Box from "../../Box";
import styles from "./styles.module.css";
import LoadingIndicator from "../../LoadingIndicator";

export type SelectInputProps = {
  id: string;
  size: AriaButtonInputProps["size"];
  color: AriaButtonInputProps["color"];
  renderValue?: (item: string) => ReactNode;
  isDisabled?: boolean;
  isError?: boolean;
  isLoading?: boolean;
  "aria-labelledby"?: string;
  "aria-describedby"?: string;
};

const SelectInput = ({
  id,
  isDisabled,
  isError,
  renderValue: renderValueProp,
  isLoading,
  size,
  ["aria-labelledby"]: ariaLabelledBy,
  ["aria-describedby"]: ariaDescribedBy,
}: SelectInputProps) => {
  const state = useContext(SelectStateContext);

  const renderValue = useMemo(
    () =>
      renderValueProp ||
      (() => (
        <SelectValue className={cx(styles.selectedValue, { [styles.isDisabled]: isDisabled })} />
      )),
    [renderValueProp, isDisabled]
  );
  return (
    <AriaButtonInput
      id={id}
      className={styles.inputWrapper}
      disabled={isDisabled}
      error={isError}
      size={size}
      aria-labelledby={ariaLabelledBy}
      aria-describedby={ariaDescribedBy}
    >
      <Box gap="small" align="center" justify="center" fullWidth>
        {isLoading && <LoadingIndicator loading />}
        {renderValue?.(state?.selectedKey as string)}
      </Box>
      <Icon src={ChevronDown} />
    </AriaButtonInput>
  );
};

SelectInput.displayName = "DS.Select.Input";

export default SelectInput;
