import Box from "../Box";
import LoadingIndicator from "../LoadingIndicator";
import Typography from "../Typography";

type ComboBoxEmptyCollectionProps = {
  isLoading?: boolean;
};

const ComboBoxEmptyCollection = ({ isLoading }: ComboBoxEmptyCollectionProps) =>
  isLoading ? (
    <Box fullWidth justify="center">
      <LoadingIndicator size="large" loading />
    </Box>
  ) : (
    <Box padding="small">
      <Typography tag="span" variant="p-body4">
        No options found.
      </Typography>
    </Box>
  );

ComboBoxEmptyCollection.displayName = "DS.ComboBox.EmptyCollection";

export default ComboBoxEmptyCollection;
