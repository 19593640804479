import { useMemo } from "react";
import { decodeTime } from "ulid";

import ButtonNew from "ds/components/Button/New";
import useTypedContext from "hooks/useTypedContext";
import { SearchQueryOrderDirection, WebhooksIntegration } from "types/generated";
import SortableTable from "components/SortableTable";
import ListEntitiesNew from "components/ListEntitiesNew";
import Box from "ds/components/Box";
import CardWrapper from "components/CardWrapper";
import EmptyState from "ds/components/EmptyState";
import { EmptystateWebhooksColored } from "components/icons/generated";
import DocumentationButton from "components/DocumentationButton";
import { getDocsUrl } from "utils/getDocsUrl";
import { WebhooksTierInfo } from "components/TierInfo/WebhooksTierInfo";

import StackSettingsIntegrationsWrapper from "../components/Wrapper";
import StackSettingsFormWrapper from "../../components/FormWrapper";
import { StackSettingsContextData } from "../../Context";
import StackSettingsIntegrationsWebhooksListItemVirtualized from "./ListItem/Virtualized";
import { SORTABLE_TABLE_COLUMNS, SORTABLE_TABLE_COLUMNS_ORDER } from "./constants";
import { showStackSettingsIntegrationsWebhooksDetails } from "./Details";
import { showStackSettingsIntegrationsWebhooksCreateFormDrawer } from "./CreateFormDrawer";

const StackSettingsIntegrationsWebhooks = () => {
  const { stackSettings, canManageStack } = useTypedContext(StackSettingsContextData);

  const webhooks = useMemo(() => {
    const items = stackSettings?.integrations?.webhooks || [];

    return items.map((item) => ({
      ...item,
      createdAt: decodeTime(item.id) / 1000,
    }));
  }, [stackSettings?.integrations?.webhooks]);

  const handleOnDetailsClick = (item: WebhooksIntegration) => {
    showStackSettingsIntegrationsWebhooksDetails({
      id: item.id,
      webhook: item,
    });
  };

  const handleOnCreateClick = () => {
    showStackSettingsIntegrationsWebhooksCreateFormDrawer({
      stackId: stackSettings.id,
    });
  };

  const handleOnEditClick = (item: WebhooksIntegration) => {
    showStackSettingsIntegrationsWebhooksCreateFormDrawer({
      id: item.id,
      webhook: item,
      stackId: stackSettings.id,
    });
  };

  return (
    <StackSettingsIntegrationsWrapper
      actions={
        <ButtonNew variant="primary" disabled={!canManageStack} onPress={handleOnCreateClick}>
          Create webhook
        </ButtonNew>
      }
    >
      <WebhooksTierInfo />
      <StackSettingsFormWrapper padding="0" gap="0" withLimitWidth={false}>
        {webhooks.length > 0 && (
          <SortableTable
            ariaLabel="Webhooks"
            items={webhooks}
            columns={SORTABLE_TABLE_COLUMNS}
            columnOrder={SORTABLE_TABLE_COLUMNS_ORDER}
            renderItems={(items) => (
              <ListEntitiesNew
                itemCount={items.length}
                itemProps={{
                  items,
                  stackId: stackSettings.id,
                  onEditClick: handleOnEditClick,
                  onDetailsClick: handleOnDetailsClick,
                  canManageStack,
                }}
                virtualizedItem={StackSettingsIntegrationsWebhooksListItemVirtualized}
                itemKey={(index) => items[index].id}
              />
            )}
            initialSortBy="id"
            initialDirection={SearchQueryOrderDirection.Desc}
          />
        )}

        {webhooks.length === 0 && (
          <Box align="center" justify="center" grow="1" fullWidth>
            <CardWrapper variant="filled" direction="column">
              <EmptyState
                padding="large"
                icon={EmptystateWebhooksColored}
                title="You do not have any webhooks yet"
                caption="Set up a webhook for your current stack to send events to a http endpoint of your choice."
              >
                <Box gap="medium">
                  {canManageStack && (
                    <ButtonNew variant="primary" onPress={handleOnCreateClick}>
                      Create webhook
                    </ButtonNew>
                  )}
                  <DocumentationButton
                    to={getDocsUrl("/integrations/webhooks")}
                    label="Documentation"
                  />
                </Box>
              </EmptyState>
            </CardWrapper>
          </Box>
        )}
      </StackSettingsFormWrapper>
    </StackSettingsIntegrationsWrapper>
  );
};

export default StackSettingsIntegrationsWebhooks;
