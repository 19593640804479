import LinkNew from "ds/components/LinkNew";
import useTypedContext from "hooks/useTypedContext";
import { AccountType } from "types/generated";
import { AccountContext } from "views/AccountWrapper";

type InstallationLinkProps = {
  children: React.ReactNode;
};

const InstallationLink = ({ children }: InstallationLinkProps) => {
  const { accountName, accountType, installationId } = useTypedContext(AccountContext);

  const href = [
    "https://github.com",
    accountType === AccountType.Org ? `/organizations/${accountName}` : "",
    `/settings/installations/${installationId}`,
  ].join("");

  return (
    <LinkNew href={href} target="_blank" size="small">
      {children}
    </LinkNew>
  );
};

export default InstallationLink;
