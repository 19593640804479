import { useCallback, useMemo, useState } from "react";

type Callback = (value: string | null) => void;

type BaseCollection = { value: string };

export type ComboBoxCollectionWithInputValueItem = BaseCollection & {
  isInputValueItem?: boolean;
};

type UseComboBoxInputValueItemProps<Collection extends BaseCollection> = {
  onInputValueSelected: (inputValue: string) => void;
  options: Array<Collection>;
  inputValueItemProps: Omit<Collection, "value">;
  shouldIncludeInputValueItem?: (value: string) => boolean;
};

const useComboBoxInputValueItem = <Collection extends BaseCollection>({
  onInputValueSelected,
  options,
  inputValueItemProps,
  shouldIncludeInputValueItem,
}: UseComboBoxInputValueItemProps<Collection>) => {
  const [inputValue, setInputValue] = useState("");

  const onChange = useCallback(
    (formCallback: Callback) => (value: string | null) => {
      if (inputValue && value === inputValue) {
        onInputValueSelected(inputValue.trim());
      }
      return formCallback(value ? value.trim() : value);
    },
    [inputValue, onInputValueSelected]
  );

  const items = useMemo(() => {
    if (shouldIncludeInputValueItem && !shouldIncludeInputValueItem(inputValue)) {
      return options;
    }

    return [
      ...options,
      ...(!inputValue || options.find(({ value }) => value === inputValue)
        ? []
        : [
            {
              value: inputValue,
              isInputValueItem: true,
              ...inputValueItemProps,
            },
          ]),
    ];
  }, [inputValue, inputValueItemProps, options, shouldIncludeInputValueItem]);

  return {
    comboBoxProps: {
      onInputChange: setInputValue,
      onChange,
      items,
    },
  };
};

export default useComboBoxInputValueItem;
