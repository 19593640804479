import {
  create,
  hide,
  NiceModalContext,
  NiceModalHocProps,
  show,
  useModal,
} from "@ebay/nice-modal-react";
import { ComponentProps, ComponentType, FC, useContext, useEffect, useRef } from "react";
import { useLocation, Location } from "react-router-dom";

// It is needed for views with initialy opened drawer where filters automaticaly update search in URL
type CustomProps = {
  shouldCloseOnLocationChange?: (location: { initial: Location; current: Location }) => boolean;
};

export const createDrawer = <Props extends object>(Component: ComponentType<Props>) => {
  return create<Props & NiceModalHocProps & CustomProps>(function DrawerComponent(drawerProps) {
    const modals = useContext(NiceModalContext);
    const didMount = useRef(false);
    const location = useLocation();
    const initialLocation = useRef(location);
    const currentModal = useModal();

    const { shouldCloseOnLocationChange = () => true } = drawerProps;

    useEffect(() => {
      if (didMount.current) {
        return;
      }

      didMount.current = true;

      for (const modal of Object.values(modals)) {
        if (modal.visible && modal.id !== drawerProps.id) {
          hide(modal.id);
        }
      }
    }, [modals, drawerProps.id]);

    if (
      initialLocation.current !== location &&
      currentModal.visible &&
      shouldCloseOnLocationChange({
        initial: initialLocation.current,
        current: location,
      })
    ) {
      currentModal.hide();
    }

    return <Component {...drawerProps} />;
  }) as FC<Props>;
};

/* eslint-disable @typescript-eslint/no-explicit-any*/
export const createDrawerTrigger =
  <T extends React.FC<any>>(Component: T) =>
  (props?: ComponentProps<T> & Partial<NiceModalHocProps> & CustomProps) =>
    show(Component, props);
/* eslint-enable @typescript-eslint/no-explicit-any*/

export const isPathnameDifferent = ({
  current,
  initial,
}: {
  current: Location;
  initial: Location;
}) => {
  return initial.pathname !== current.pathname;
};
