import { AriaRole } from "react";

import Box from "ds/components/Box";
import Tag from "ds/components/Tag";
import Typography from "ds/components/Typography";
import TextEllipsis from "ds/components/TextEllipsis";

type InputNameProps = {
  name: string;
  type: string;
  description: string;
  role?: AriaRole;
};

const InputName = ({ name, type, description, role }: InputNameProps) => {
  return (
    <Box role={role}>
      <Box direction="column" gap="small" fullWidth>
        <Box gap="medium" align="center" fullWidth>
          <TextEllipsis tooltip={name}>
            {(props) => (
              <Typography {...props} tag="span" variant="p-body2">
                {name}
              </Typography>
            )}
          </TextEllipsis>

          <Tag tag={type} />
        </Box>
        {description && (
          <Typography tag="p" variant="p-body3" color="secondary">
            {description}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default InputName;
