export const getDescriptionId = (id: string): string => `${id}-dscr`;
export const getErrorId = (id: string): string => `${id}-err`;
export const getAriaDescribedBy = (id: string, error?: string, helperText?: string) => {
  if (error) {
    return getErrorId(id);
  }
  if (helperText) {
    return getDescriptionId(id);
  }
  return undefined;
};
