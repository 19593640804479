import { useEffect, useRef } from "react";

import useApplyFilter from "components/Filters/useApplyFilter";
import { Space } from "components/icons/generated";
import ListEntitiesItem from "components/ListEntitiesItem";
import ListEntitiesItemDescription from "components/ListEntitiesItem/Description";
import ListEntitiesItemLink from "components/ListEntitiesItem/Link";
import MetaInfoListItem from "components/MetaInfoList/Item";
import TagsListFilterable from "components/TagsList/Filterable";
import Timestamp from "components/time/Timestamp";
import { ContextSuggestions, searchContextSuggestionsDictionary } from "constants/context";
import Box from "ds/components/Box";
import { useDrawerVisibilityForId } from "ds/components/DrawerNew/useDrawerVisibilityForId";
import { Context } from "types/generated";
import { withTestId } from "utils/withTestId";

import { COLUMN_GAP, COLUMN_ORDER, CONTEXTS_LIST_ITEM_TEST_ID } from "../constants";
import ContextListItemDropdown from "../ListItemDropdown";

type ContextListItemProps = {
  item: Context;
  setRowHeight?: (size: number) => void;
  canManageContexts: boolean;
};

function ContextListItem({ item, setRowHeight, canManageContexts }: ContextListItemProps) {
  const rowRef = useRef<HTMLDivElement>(null);

  const handleRowHeight = () => {
    if (setRowHeight && rowRef.current) {
      setRowHeight(rowRef.current.getBoundingClientRect().height);
    }
  };

  const { applyFilter, applySpaceFilter, applyLabelFilter } = useApplyFilter<ContextSuggestions>({
    searchSuggestionsDictionary: searchContextSuggestionsDictionary,
  });

  useEffect(handleRowHeight);

  const isDrawerVisible = useDrawerVisibilityForId(item.id);

  return (
    <ListEntitiesItem
      direction="row"
      align="center"
      justify="between"
      grid
      gridTemplate={COLUMN_ORDER}
      gap={`0 ${COLUMN_GAP}`}
      ref={rowRef}
      isActive={isDrawerVisible}
      {...withTestId(CONTEXTS_LIST_ITEM_TEST_ID)}
    >
      <Box direction="column" justify="center">
        <Box align="center">
          <ListEntitiesItemLink title={item.name} href={`/context/${item.id}`} />
        </Box>

        {item.description && (
          <ListEntitiesItemDescription description={item.description} id={item.id} />
        )}
        <TagsListFilterable
          tags={item.labels}
          applyLabelFilter={applyLabelFilter(ContextSuggestions.Label)}
          applyFolderFilter={applyFilter(ContextSuggestions.Folder)}
          onExpand={handleRowHeight}
        />
      </Box>

      <MetaInfoListItem
        applyFilter={() => applySpaceFilter(ContextSuggestions.Space)(item.spaceDetails.id)}
        icon={Space}
        linkText={item.spaceDetails.name}
        href={`/spaces/${item.spaceDetails.id}`}
        type="space"
      />

      <Box align="center">
        <MetaInfoListItem>
          <Timestamp timestamp={item.createdAt} />
        </MetaInfoListItem>
      </Box>

      <Box shrink="0" grow="0">
        <ContextListItemDropdown context={item} canManageContexts={canManageContexts} />
      </Box>
    </ListEntitiesItem>
  );
}

export default ContextListItem;
