import { ReactNode, useCallback, Key as ReactKey } from "react";
import { ComboBox as AriaComboBox } from "react-aria-components";

import { AnalyticsCommonProps } from "hooks/useAnalytics";
import useEnsureId from "hooks/useEnsureId";

import FormField from "../Form/Field";
import ComboBoxEmptyCollection from "./EmptyCollection";
import ComboBoxInput, { ComboBoxInputProps } from "./Input";
import styles from "./styles.module.css";
import ListBoxPopover from "../ListBox/Popover";
import ListBox from "../ListBox";
import ListBoxScrollableWrapper from "../ListBox/ScrollableWrapper";

type ComboBoxProps<Collection extends object, Key extends string> = {
  id?: string;
  /**
   * label is optional, but if it's not provided you need to connect the label via ariaLabelledBy attribute
   */
  label?: string;
  placeholder?: string;
  helperText?: string;
  error?: string;
  value?: string | null;
  items: Collection[];
  onChange: (value: Key | null) => void;
  inputValue?: string;
  renderInput?: (input: ComboBoxInputProps) => ReactNode;
  onInputChange?: (value: string) => void;
  children: (Collection: Collection) => JSX.Element;
  tooltipInfo?: ReactNode;
  tooltipAnalyticsPage?: AnalyticsCommonProps["analyticsPage"];
  tooltipAnalyticsTitle?: AnalyticsCommonProps["analyticsTitle"];
  tooltipAnalyticsProps?: AnalyticsCommonProps["analyticsProps"];
  isDisabled?: boolean;
  isLoading?: boolean;
};

const ComboBox = <Collection extends object, Key extends string>({
  id: propsId,
  children,
  label,
  helperText,
  placeholder = "Type in or select from the list",
  error,
  value,
  renderInput = (props) => <ComboBoxInput {...props} />,
  onChange,
  inputValue,
  onInputChange,
  items,
  tooltipInfo,
  tooltipAnalyticsPage,
  tooltipAnalyticsTitle,
  tooltipAnalyticsProps,
  isDisabled,
  isLoading,
}: ComboBoxProps<Collection, Key>) => {
  const id = useEnsureId(propsId);

  const handleSelectionChange = useCallback(
    (value: ReactKey | null) => {
      if (value === null || value === undefined) {
        return onChange(value);
      }

      return onChange(String(value) as Key);
    },
    [onChange]
  );

  return (
    <AriaComboBox
      className={styles.comboBox}
      menuTrigger="focus"
      selectedKey={value}
      onSelectionChange={handleSelectionChange}
      inputValue={inputValue}
      onInputChange={onInputChange}
      isInvalid={!!error}
      isDisabled={isDisabled}
      allowsEmptyCollection
    >
      <FormField
        id={id}
        label={label}
        helperText={helperText}
        error={error}
        tooltipInfoVariant="modal"
        tooltipInfo={tooltipInfo}
        tooltipAnalyticsPage={tooltipAnalyticsPage}
        tooltipAnalyticsTitle={tooltipAnalyticsTitle}
        tooltipAnalyticsProps={tooltipAnalyticsProps}
        noMargin
      >
        {renderInput({
          id,
          placeholder,
          isError: !!error,
          isDisabled,
          isLoading,
        })}
      </FormField>

      <ListBoxPopover>
        <ListBoxScrollableWrapper>
          <ListBox<Collection>
            items={items}
            renderEmptyState={() => <ComboBoxEmptyCollection isLoading={isLoading} />}
          >
            {children}
          </ListBox>
        </ListBoxScrollableWrapper>
      </ListBoxPopover>
    </AriaComboBox>
  );
};

ComboBox.displayName = "DS.ComboBox";

export default ComboBox;
