import { useCallback, useEffect, useMemo } from "react";
import { useForm, SubmitHandler, Controller, FormProvider } from "react-hook-form";
import { useMutation } from "@apollo/client";
import { useModal } from "@ebay/nice-modal-react";

import FormField from "ds/components/Form/Field";
import Input from "ds/components/Input";
import Textarea from "ds/components/Textarea";
import Typography from "ds/components/Typography";
import useTypedContext from "hooks/useTypedContext";
import FlashContext from "components/FlashMessages/FlashContext";
import { Space } from "types/generated";
import FormFieldTags from "components/FormFields/Tags";
import SpaceSelect from "components/SpaceSelect";
import DrawerBody from "ds/components/Drawer/Body";
import DrawerFooter from "ds/components/Drawer/Footer";
import DrawerFooterActions from "ds/components/Drawer/FooterActions";
import useAnalytics from "hooks/useAnalytics";
import { AnalyticsPageSpaces } from "hooks/useAnalytics/pages/spaces";
import FormFieldViewText from "components/FormFields/ViewText";
import Toggle from "ds/components/Toggle";
import DrawerForm from "ds/components/DrawerNew/Form";
import DrawerCloseIcon from "ds/components/DrawerNew/CloseIcon";
import ButtonNew from "ds/components/Button/New";
import DrawerCancelButton from "ds/components/DrawerNew/CancelButton";
import DrawerHeader from "ds/components/Drawer/Header";
import DrawerHeaderTitle from "ds/components/DrawerNew/HeaderTitle";

import { SpacesDrawerMode, SpacesLayoutMode } from "../types";
import { showDeleteConfirmation } from "../DeleteConfirmation";
import { getDefaultValues, isOnOutsideClickElementInteractive } from "./helpers";
import { SPACE_CREATE, SPACE_UPDATE } from "../gql";
import styles from "./styles.module.css";

type SpaceDrawerFormFields = {
  name: string;
  description?: string;
  inheritEntities: boolean;
  parentSpace: string;
  labels: Record<"value", string>[];
};

type SpaceDrawerBuilderProps = {
  drawerMode: SpacesDrawerMode;
  space?: Space;
  layoutMode: SpacesLayoutMode;
  onClose: () => void;
};

const SpaceDrawerBuilder = ({
  drawerMode,
  space,
  layoutMode,
  onClose,
}: SpaceDrawerBuilderProps) => {
  const trackSegmentEvent = useAnalytics({
    page: AnalyticsPageSpaces.Spaces,
  });
  const modal = useModal();
  const { onError, reportSuccess } = useTypedContext(FlashContext);

  const isEditMode = drawerMode === "edit";
  const isCreateMode = drawerMode === "create";

  const builderForm = useForm<SpaceDrawerFormFields>({
    defaultValues: getDefaultValues(isEditMode, space),
    mode: "onChange",
  });

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors, isValid, isDirty },
  } = builderForm;

  const [spaceCreate, { loading: isSpaceCreateLoading }] = useMutation<{ spaceCreate: Space }>(
    SPACE_CREATE,
    {
      awaitRefetchQueries: true,
      refetchQueries: ["GetSpaces"],
    }
  );
  const [spaceUpdate, { loading: isSpaceUpdateLoading }] = useMutation<{ spaceUpdate: Space }>(
    SPACE_UPDATE,
    {
      awaitRefetchQueries: true,
      refetchQueries: ["GetSpaces"],
    }
  );

  const hiddenIds = useMemo(() => {
    if (!isCreateMode && space?.id) {
      return [space.id];
    }

    return [];
  }, [space?.id, isCreateMode]);

  const onSubmit: SubmitHandler<SpaceDrawerFormFields> = (data) => {
    if (isEditMode && space?.id) {
      spaceUpdate({
        variables: {
          spaceId: space.id,
          input: {
            name: data.name,
            description: data.description,
            inheritEntities: data.inheritEntities,
            parentSpace: data.parentSpace,
            labels: data.labels.map((item) => item.value),
          },
        },
      })
        .then(({ data }) => {
          if (data?.spaceUpdate?.name) {
            reportSuccess({
              message: `Space "${data.spaceUpdate.name}" is successfully updated`,
            });
            modal.hide();
          }
        })
        .catch(onError);
    } else {
      spaceCreate({
        variables: {
          input: {
            name: data.name,
            description: data.description,
            inheritEntities: data.inheritEntities,
            parentSpace: data.parentSpace,
            labels: data.labels.map((item) => item.value),
          },
        },
      })
        .then(({ data }) => {
          if (data?.spaceCreate?.name) {
            reportSuccess({
              message: `Space "${data.spaceCreate.name}" is successfully created`,
            });
            modal.hide();

            const baseAnalyticsProps = {
              id: data.spaceCreate.id,
              parentSpace: data.spaceCreate.parentSpace ?? undefined,
              inheritEntities: data.spaceCreate.inheritEntities,
              labels: data.spaceCreate.labels,
            };

            if (space) {
              trackSegmentEvent("Child Space Added", {
                ...baseAnalyticsProps,
                view: layoutMode ?? undefined,
              });
            } else {
              trackSegmentEvent("Space Created", baseAnalyticsProps);
            }
          }
        })
        .catch(onError);
    }
  };

  const handleSpaceDeleteConfirmation = () => {
    if (!space?.id) {
      return;
    }

    showDeleteConfirmation({ name: space.name, id: space.id }).then(modal.hide);
  };

  useEffect(() => {
    reset(getDefaultValues(isEditMode, space));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [space, isEditMode]);

  const isPrimaryActionLoading = isEditMode ? isSpaceUpdateLoading : isSpaceCreateLoading;

  const shouldCloseOnInteractOutside = useCallback(
    (el: Element) => {
      // It allows to open leave confirmation
      if (isDirty) {
        return true;
      }

      return isOnOutsideClickElementInteractive(el);
    },
    [isDirty]
  );

  return (
    <DrawerForm
      blockNavigation
      isDirty={isDirty}
      onClose={onClose}
      shouldCloseOnInteractOutside={shouldCloseOnInteractOutside}
    >
      <FormProvider {...builderForm}>
        <DrawerHeader justify="between">
          <DrawerHeaderTitle title={isCreateMode ? "Create a new space" : "Space details"} />
          <DrawerCloseIcon />
        </DrawerHeader>
        <DrawerBody noPadding>
          <div className={styles.viewList}>
            {isEditMode && space?.id && <FormFieldViewText label="ID" value={space.id} withCopy />}

            <FormField label="Name" error={errors?.name?.message}>
              {({ ariaInputProps }) => (
                <Input
                  placeholder="Enter space name here..."
                  error={!!errors?.name}
                  {...register("name", { required: "Name field is required." })}
                  {...ariaInputProps}
                />
              )}
            </FormField>

            <FormField label="Description" isOptional>
              {({ ariaInputProps }) => (
                <Textarea
                  placeholder="Enter space description here..."
                  {...register("description")}
                  {...ariaInputProps}
                />
              )}
            </FormField>

            <FormFieldTags label="Labels" tagName="label" name="labels" />
          </div>

          <div className={styles.viewList}>
            <Typography tag="h3" variant="p-t5" className={styles.title}>
              Inherit entities
            </Typography>

            <Typography tag="p" variant="p-body2" className={styles.info}>
              Should read access and attachable entities be inherited from the parent space.
            </Typography>

            <Controller
              name="inheritEntities"
              control={control}
              render={({ field }) => (
                <Toggle
                  variant="checkbox"
                  id={field.name}
                  onChange={field.onChange}
                  isSelected={field.value}
                  aria-label={field.value ? "Disable inheritance" : "Enable inheritance"}
                >
                  Inheritance enabled
                </Toggle>
              )}
            />
          </div>

          <div className={styles.viewList}>
            <Typography tag="h3" variant="p-t5" className={styles.title}>
              Parent
            </Typography>

            <Typography tag="p" variant="p-body2" className={styles.info}>
              Choose the parent space for this space.
            </Typography>

            <Controller
              name="parentSpace"
              control={control}
              rules={{ required: "Parent space field is required." }}
              render={({ field, fieldState }) => (
                <FormField error={fieldState.error?.message} noMargin>
                  {({ ariaInputProps }) => (
                    <SpaceSelect
                      value={field.value}
                      onChange={field.onChange}
                      error={!!fieldState.error?.message}
                      hiddenIds={hiddenIds}
                      disabled={isCreateMode && !!space}
                      ariaProps={ariaInputProps}
                    />
                  )}
                </FormField>
              )}
            />
          </div>
        </DrawerBody>
        <DrawerFooter>
          {isEditMode && (
            <ButtonNew
              className={styles.deleteSpaceLink}
              variant="dangerSecondary"
              onPress={handleSpaceDeleteConfirmation}
            >
              Delete space
            </ButtonNew>
          )}
          <DrawerFooterActions>
            <DrawerCancelButton />

            <ButtonNew
              variant="primary"
              onPress={() => handleSubmit(onSubmit)()}
              disabled={!isValid || (isEditMode && !isDirty) || isPrimaryActionLoading}
              loading={isPrimaryActionLoading}
            >
              {isEditMode ? "Save" : "Create"}
            </ButtonNew>
          </DrawerFooterActions>
        </DrawerFooter>
      </FormProvider>
    </DrawerForm>
  );
};

export default SpaceDrawerBuilder;
