import FormFieldViewBoolean from "components/FormFields/ViewBoolean";
import FormFieldViewText from "components/FormFields/ViewText";
import DrawerSimple from "ds/components/DrawerNew/Simple";
import DrawerBody from "ds/components/Drawer/Body";
import DrawerHeader from "ds/components/Drawer/Header";
import FormField from "ds/components/Form/Field";
import { WebhooksIntegration } from "types/generated";
import Typography from "ds/components/Typography";
import DrawerCloseIcon from "ds/components/DrawerNew/CloseIcon";
import DrawerHeaderTitle from "ds/components/DrawerNew/HeaderTitle";
import { createDrawer, createDrawerTrigger } from "ds/components/DrawerNew/utils";

type StackSettingsIntegrationsWebhooksDetailsProps = {
  webhook: WebhooksIntegration;
};

const StackSettingsIntegrationsWebhooksDetails = createDrawer(
  ({ webhook }: StackSettingsIntegrationsWebhooksDetailsProps) => {
    return (
      <DrawerSimple>
        <DrawerHeader justify="between">
          <DrawerHeaderTitle title="Details" />
          <DrawerCloseIcon />
        </DrawerHeader>
        <DrawerBody fullHeight>
          <FormFieldViewText label="Endpoint" value={webhook.endpoint} />
          <FormField label="Secret">
            <Typography tag="span" variant="p-body2">
              &bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;
            </Typography>
          </FormField>
          <FormFieldViewBoolean label="Enabled" value={webhook.enabled} />
        </DrawerBody>
      </DrawerSimple>
    );
  }
);

export const showStackSettingsIntegrationsWebhooksDetails = createDrawerTrigger(
  StackSettingsIntegrationsWebhooksDetails
);
