import { useCallback, useEffect, useRef } from "react";

import { Space } from "components/icons/generated";
import ListEntitiesItem from "components/ListEntitiesItem";
import ListEntitiesItemDescription from "components/ListEntitiesItem/Description";
import ListEntitiesItemLink from "components/ListEntitiesItem/Link";
import MetaInfoListItem from "components/MetaInfoList/Item";
import TagsList from "components/TagsList";
import { HUMANIZE_PROVIDER } from "constants/vcs_providers";
import BadgeNext from "ds/components/BadgeNext";
import Box from "ds/components/Box";
import { useDrawerVisibilityForId } from "ds/components/DrawerNew/useDrawerVisibilityForId";
import useTypedContext from "hooks/useTypedContext";
import { VcsIntegration } from "types/generated";
import { hasSpaceManageAccess } from "utils/user";
import VCSIntegrationFeedback from "views/Account/VCS/components/IntegrationFeedback";
import VCSProviderIcon from "views/Account/VCS/components/ProviderIcon";
import { VCS_PROVIDERS_URL_KEYS } from "views/Account/VCS/constants";
import { isTestIntegrationConnectionAvailable } from "views/Account/VCS/helpers";
import useDeleteVcsIntegration from "views/Account/VCS/useDeleteVcsIntegration";
import useTestVCSIntegrationConnection from "views/Account/VCS/useTestVCSIntegrationConnection";

import { COLUMN_GAP, COLUMN_ORDER } from "../constants";
import { VCSIntegrationsListContext } from "../Context";
import { getVCSIntegrationId } from "../helpers";
import VCSIntegrationsListItemDropdownActions from "./DropdownActions";

type VCSIntegrationsListItemProps = {
  item: VcsIntegration;
  onEdit: (item: VcsIntegration) => void;
  setRowHeight?: (size: number) => void;
  onShowDetails: (item: VcsIntegration) => void;
};

const VCSIntegrationsListItem = ({
  item,
  onEdit,
  setRowHeight,
  onShowDetails,
}: VCSIntegrationsListItemProps) => {
  const rowRef = useRef<HTMLDivElement>(null);
  const { refetchDefaultIntegrations } = useTypedContext(VCSIntegrationsListContext);

  const isTestConnectionAvailable = isTestIntegrationConnectionAvailable(item.details);
  const canManageVCSIntegrations = hasSpaceManageAccess(item.space.accessLevel);
  const isActive = useDrawerVisibilityForId(getVCSIntegrationId(item));

  const { deleteVcsIntegration } = useDeleteVcsIntegration();
  const { testIntegrationConnection, loading } = useTestVCSIntegrationConnection({
    id: item.id,
    name: item.name || `Default ${HUMANIZE_PROVIDER[item.provider]}`,
    provider: item.provider,
  });

  const totalUsedByEntitiesQty = item?.searchUsersSuggestions?.filteredCount || 0;

  const handleRowHeight = () => {
    if (setRowHeight && rowRef.current) {
      setRowHeight(rowRef.current.getBoundingClientRect().height);
    }
  };

  const handleShowDetails = useCallback(() => onShowDetails(item), [item, onShowDetails]);

  const handleTestConnection = useCallback(
    () => testIntegrationConnection(),
    [testIntegrationConnection]
  );

  const handleIntegrationDelete = useCallback(() => {
    if (item.id) {
      deleteVcsIntegration(
        item.id,
        item.provider,
        totalUsedByEntitiesQty,
        refetchDefaultIntegrations
      );
    }
  }, [
    deleteVcsIntegration,
    item.id,
    item.provider,
    refetchDefaultIntegrations,
    totalUsedByEntitiesQty,
  ]);

  const handleIntegrationEdit = useCallback(() => {
    onEdit(item);
  }, [item, onEdit]);

  const itemPath = `/vcs/integration/${VCS_PROVIDERS_URL_KEYS[item.provider]}/${item.id}`;

  // on every render, update the row height
  useEffect(handleRowHeight);

  return (
    <ListEntitiesItem
      direction="row"
      align="center"
      justify="between"
      grid
      gridTemplate={COLUMN_ORDER}
      gap={`0 ${COLUMN_GAP}`}
      ref={rowRef}
      isActive={isActive}
      role="row"
    >
      <Box role="rowheader" align="center" gap="large">
        <VCSProviderIcon provider={item.provider} tile isDefault={item.isDefault} />

        <Box grow="1">
          <Box direction="column" gap="medium" fullWidth>
            <Box gap="medium" align="center" fullWidth>
              <ListEntitiesItemLink title={item.name} href={itemPath} />

              {item.isDefault && (
                <Box direction="column">
                  <BadgeNext variant="gray" type="strong" text="DEFAULT" />
                </Box>
              )}
            </Box>

            {item.description && (
              <Box direction="row" align="start" fullWidth>
                <ListEntitiesItemDescription
                  noPadding
                  description={item.description}
                  id={getVCSIntegrationId(item)}
                />
              </Box>
            )}

            <VCSIntegrationFeedback integration={item} />

            <TagsList tags={item.labels} onExpand={handleRowHeight} withTopMargin={false} />
          </Box>
        </Box>
      </Box>

      <Box role="cell" direction="column">
        {item.isDefault ? (
          <MetaInfoListItem>&minus;</MetaInfoListItem>
        ) : (
          <MetaInfoListItem
            icon={Space}
            linkText={item.space.name}
            href={`/spaces/${item.space.id}`}
          />
        )}
      </Box>

      <Box shrink="0" role="cell">
        <VCSIntegrationsListItemDropdownActions
          id={item.id}
          name={item.name}
          onShowDetails={handleShowDetails}
          onIntegrationDelete={handleIntegrationDelete}
          onEdit={handleIntegrationEdit}
          {...(isTestConnectionAvailable && { onTestConnection: handleTestConnection })}
          isTestingConnection={loading}
          canManageVCSIntegrations={canManageVCSIntegrations}
          totalUsedByEntitiesQty={totalUsedByEntitiesQty}
          usedByPath={itemPath}
        />
      </Box>
    </ListEntitiesItem>
  );
};

export default VCSIntegrationsListItem;
