import classNames from "classnames";

import Highlight from "components/text/Highlight";
import LinkNew from "ds/components/LinkNew";

import styles from "./styles.module.css";
import { ListBoxItemLink } from "./types";

const ListBoxLink = (props: ListBoxItemLink) => {
  const {
    headerLink,
    renderAsCode,
    routerLink,
    text,
    url,
    highlightMatches,
    target = "_blank",
  } = props;

  const linkClass = classNames(styles.listBoxLink, {
    [styles.listBoxInfo]: !headerLink,
  });
  const textClass = classNames({ [styles.listBoxCode]: renderAsCode });

  if (routerLink) {
    return (
      <LinkNew variant="secondary" className={linkClass} href={url} inheritFont>
        <span className={textClass}>
          <Highlight text={text} matches={highlightMatches} />
        </span>
      </LinkNew>
    );
  }

  return (
    <LinkNew
      className={linkClass}
      href={url}
      rel="noopener noreferrer"
      target={target}
      inheritFont={headerLink}
      variant={headerLink ? "secondary" : "primary"}
    >
      {text}
    </LinkNew>
  );
};

export default ListBoxLink;
