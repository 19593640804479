import { ListBox as AriaListBox, ListBoxProps } from "react-aria-components";

import styles from "./styles.module.css";

const ListBox = <Collection extends object>(props: ListBoxProps<Collection>) => (
  <AriaListBox<Collection> className={styles.listBox} {...props} />
);

ListBox.displayName = "DS.ListBox";

export default ListBox;
