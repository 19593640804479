import { useEffect, useRef, useState } from "react";
import { Base64 } from "js-base64";

import {
  ServerPublicKeyCredentialCreationOptions,
  ServerPublicKeyCredentialRequestOptions,
} from "types/Credentials";
import { getCookieValue } from "utils/cookies";
import { trackSegmentEvent } from "shared/Analytics";
import { FetchError } from "utils/errors";

const SPACELIFT_MFA_COOKIE = "spacelift-mfa";

type AuthResponse = {
  success: boolean;
  message: string;
  status?: number;
};

const getStatus = (error: unknown) => {
  if (error instanceof FetchError) {
    return error.status;
  }

  return;
};

const useMFA = () => {
  const abortControllerRef = useRef<AbortController | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [canAbort, setCanAbort] = useState(true);
  const [
    isUserVerifyingPlatformAuthenticatorAvailable,
    setIsUserVerifyingPlatformAuthenticatorAvailable,
  ] = useState<boolean | undefined>();

  const abort = () => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
      abortControllerRef.current = null;
    }
  };

  const beginRegistration = async (
    keyName: string
  ): Promise<PublicKeyCredentialCreationOptions> => {
    abortControllerRef.current = new AbortController();
    const response = await fetch("/mfa/begin_registration", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        security_key_name: keyName,
      }),
      signal: abortControllerRef.current?.signal,
    });

    abortControllerRef.current = null;

    if (!response.ok) {
      throw new FetchError(response.status);
    }

    const responseJson = await response.json();

    const serverOptions: ServerPublicKeyCredentialCreationOptions = responseJson.publicKey;

    return {
      ...serverOptions,
      challenge: Base64.toUint8Array(serverOptions.challenge),
      user: {
        ...serverOptions.user,
        id: Base64.toUint8Array(serverOptions.user.id),
      },
      excludeCredentials: serverOptions.excludeCredentials?.map((credential) => ({
        ...credential,
        id: Base64.toUint8Array(credential.id),
      })),
    };
  };

  const finishRegistration = async (credential: PublicKeyCredential) => {
    const response = credential.response as AuthenticatorAttestationResponse;

    const attestationObject = response.attestationObject;
    const clientDataJSON = response.clientDataJSON;
    const rawId = credential.rawId;

    const res = await fetch("/mfa/finish_registration", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: credential.id,
        rawId: Base64.fromUint8Array(new Uint8Array(rawId), true),
        type: credential.type,
        response: {
          attestationObject: Base64.fromUint8Array(new Uint8Array(attestationObject), true),
          clientDataJSON: Base64.fromUint8Array(new Uint8Array(clientDataJSON), true),
        },
      }),
    });

    if (!res.ok) {
      throw new FetchError(res.status);
    }

    return res;
  };

  const beginAuthentication = async (): Promise<PublicKeyCredentialRequestOptions> => {
    abortControllerRef.current = new AbortController();

    const response = await fetch("/mfa/begin_authentication", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      signal: abortControllerRef.current?.signal,
    });

    abortControllerRef.current = null;

    if (!response.ok) {
      throw new FetchError(response.status);
    }

    const responseJson = await response.json();

    const serverOptions: ServerPublicKeyCredentialRequestOptions = responseJson.publicKey;

    return {
      ...serverOptions,
      allowCredentials: serverOptions.allowCredentials?.map((credential) => ({
        ...credential,
        id: Base64.toUint8Array(credential.id),
      })),
      challenge: Base64.toUint8Array(serverOptions.challenge),
    };
  };

  const finishAuthentication = async (assertion: PublicKeyCredential) => {
    const response = assertion.response as AuthenticatorAssertionResponse;
    const authData = response.authenticatorData;
    const clientDataJSON = response.clientDataJSON;
    const rawId = assertion.rawId;
    const sig = response.signature;
    const userHandle = response.userHandle;

    const res = await fetch("/mfa/finish_authentication", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: assertion.id,
        rawId: Base64.fromUint8Array(new Uint8Array(rawId), true),
        type: assertion.type,
        response: {
          authenticatorData: Base64.fromUint8Array(new Uint8Array(authData), true),
          clientDataJSON: Base64.fromUint8Array(new Uint8Array(clientDataJSON), true),
          signature: Base64.fromUint8Array(new Uint8Array(sig), true),
          userHandle: userHandle
            ? Base64.fromUint8Array(new Uint8Array(userHandle), true)
            : userHandle,
        },
      }),
    });

    if (!res.ok) {
      throw new FetchError(res.status);
    }

    return res;
  };

  const handleRegister = async (keyName: string): Promise<AuthResponse> => {
    try {
      setIsLoading(true);
      const publicKeyCredentialCreationOptions = await beginRegistration(keyName);

      setCanAbort(false);

      const credential = (await navigator.credentials.create({
        publicKey: publicKeyCredentialCreationOptions,
      })) as PublicKeyCredential;

      await finishRegistration(credential);

      trackSegmentEvent("Security Key Added");

      return { success: true, message: "New security key registered" };
    } catch (error) {
      const message = "Key registration failed. Please try again.";

      return { success: false, message, status: getStatus(error) };
    } finally {
      setIsLoading(false);
      setCanAbort(true);
    }
  };

  const handleAuthenticate = async (): Promise<AuthResponse> => {
    try {
      setIsLoading(true);

      const publicKeyCredentialRequestOptions = await beginAuthentication();

      setCanAbort(false);

      const assertion = (await navigator.credentials.get({
        publicKey: publicKeyCredentialRequestOptions,
      })) as PublicKeyCredential;

      await finishAuthentication(assertion);

      trackSegmentEvent("Authentication Key Filled");

      return { success: true, message: "Authenticated with security key" };
    } catch (error) {
      const message = "Authentication failed. Please try again.";

      return { success: false, message, status: getStatus(error) };
    } finally {
      setIsLoading(false);
      setCanAbort(true);
    }
  };

  // Using custom cookies function because react-cookie has a bug with updating cookies on XHR requests
  // https://github.com/reactivestack/cookies/issues/304
  const shouldUseMFA = getCookieValue(SPACELIFT_MFA_COOKIE) === "auth-key";
  const shouldRegisterSecurityKey = getCookieValue(SPACELIFT_MFA_COOKIE) === "register-key";

  useEffect(() => {
    const checkIfUserVerifyingPlatformAuthenticatorAvailable = async () => {
      try {
        const isAvailable =
          await PublicKeyCredential?.isUserVerifyingPlatformAuthenticatorAvailable();

        setIsUserVerifyingPlatformAuthenticatorAvailable(isAvailable);
      } catch {
        setIsUserVerifyingPlatformAuthenticatorAvailable(false);
      }
    };

    checkIfUserVerifyingPlatformAuthenticatorAvailable();
  }, []);

  return {
    isLoading,
    isUserVerifyingPlatformAuthenticatorAvailable,
    handleRegister,
    handleAuthenticate,
    canAbort,
    abort,
    shouldUseMFA,
    shouldRegisterSecurityKey,
  };
};

export default useMFA;
