import { runDocument, stackDocument } from "./documents";

const Notification = `package spacelift

# 🦕 Feel free to remove commented code once your policy is ready. 🦕
#
#
# Notification policy allows you to configure notifications received from Spacelift.
# As input, it receives a JSON document which fields differ depending on the notification type.
# By checking the set values you're able to determine the notification type and take appropriate actions.
# The document received is formatted like this:
#
# {
#   "run_updated:": {
#    "state": "string",
#    "username": "string",
#    "note": "string",
#    "run": {
${runDocument(7)}
#     },
#    "stack": {
${stackDocument(7)}
#     }
#   },
#   "webhook_endpoints": [
#     {
#       "id": "custom-hook2",
#       "labels": [
#         "example-label1",
#         "example-label2"
#         ],
#      }
#   ],
#   "internal_error": {
#     "error": "Source code error",
#     "message": "could not send a message to source code",
#     "severity": "ERROR"
#   }
# }
#
# Based on this input, the policy may define routes for notifications of different types.
# For example, you can route a notification to Slack when a run is updated and send
# a webhook when run fails.
#
# Your policy can define an arbitrary number of following rules:
#
#   - slack: route the notification to a slack channel;
#   - inbox: route the message to the Spacelift inbox;
#   - webhook: route the message to a specific webhook endpoint;
#
# Below are a few examples of what you can do:
#
# 1) Send a slack message when a run is updated:
# slack[{"channel_id": "00000000000"}] {
#   input.run_updated != null
# }
#
# 2) Send a webhook when a tracked run finishes:
#
# webhook[{"endpoint_id": "custom-hook2"}] {
#   input.run_updated.run.type == "TRACKED"
#   input.run_updated.run.state == "FINISHED"
# }
#
# 3) Send notifications about finished tracked runs to the Spacelift inbox:
#
# inbox[{
#  "title": "Tracked run finished!",
#  "body": sprintf("http://spc.app.spacelift.tf/stack/%s/run/%s has finished", [stack.id, run.id]),
#  "severity": "INFO",
# }] {
#   stack := input.run_updated.stack
#   run := input.run_updated.run
#   run.type == "TRACKED"
#   run.state == "FINISHED"
# }
#
# Now go ahead and unleash your inner lawgiver. For more information on the rule
# language, please visit https://www.openpolicyagent.org/docs/latest/
#
# NOTE:
# We prepend some boilerplate code to the policy to ensure that the outputs
# are defined. You can't change the type of our predefined rules (e.g. flag,
# sample, inbox, slack, webhook, pull_request) or you'll run into conflicting 
# rule errors.
# For a detailed example and further explanation, refer to our documentation:
# https://docs.spacelift.io/concepts/policy#policy-structure
`;

export default Notification;
