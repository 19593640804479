import { gql, TypedDocumentNode } from "@apollo/client";

import { AuditTrailWebhook, Maybe } from "types/generated";

export const CREATE_AUDIT_TRAIL_WEBHOOK = gql`
  mutation CreateAuditTrailSetWebhook($input: AuditTrailWebhookInput!) {
    auditTrailSetWebhook(input: $input) {
      enabled
      endpoint
      customHeaders
    }
  }
`;

export const AUDIT_TRAIL_HEADERS_SET = gql`
  mutation AuditTrailHeaderSet($input: AuditTrailWebhookSetHeadersInput!) {
    auditTrailWebhookSetHeaders(input: $input) {
      enabled
      endpoint
      customHeaders
    }
  }
`;

export const AUDIT_TRAIL_HEADERS_DELETE = gql`
  mutation AuditTrailHeaderDelete($input: AuditTrailWebhookDeleteHeadersInput!) {
    auditTrailWebhookDeleteHeaders(input: $input) {
      enabled
      endpoint
      customHeaders
    }
  }
`;

export const DELETE_AUDIT_TRAIL_WEBHOOK = gql`
  mutation DeleteAuditTrailSetWebhook {
    auditTrailDeleteWebhook {
      enabled
      endpoint
      customHeaders
    }
  }
`;

export const GET_AUDIT_TRAIL_DELIVERIES = gql`
  query GetAuditTrailDeliveries($nextToken: String) {
    auditTrailWebhook {
      endpoint
      deliveries(token: $nextToken) {
        nodes {
          id
          status
          timestamp
        }
        nextToken
      }
    }
  }
`;

// TODO: Specify the return type more precisely and use it in the related components
export const GET_AUDIT_TRAIL_WEBHOOK: TypedDocumentNode<{
  auditTrailWebhook: Maybe<AuditTrailWebhook>;
}> = gql`
  query GetAuditTrailWebhook {
    auditTrailWebhook {
      enabled
      endpoint
      includeRuns
      customHeaders
      deliveries {
        nodes {
          id
        }
      }
    }
  }
`;
