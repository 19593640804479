import React, { ReactNode, useEffect, useRef } from "react";

type IntersectionInfiniteListProps = {
  onLoadMore: () => Promise<void>;
  children: (anchor: ReactNode) => React.ReactNode;
  hasMore?: boolean;
  loading?: boolean;
};

const IntersectionInfiniteList = ({
  children,
  onLoadMore,
  hasMore,
  loading,
}: IntersectionInfiniteListProps) => {
  const anchorRef = useRef<HTMLSpanElement>(null);

  useEffect(() => {
    const anchor = anchorRef.current;
    if (anchor) {
      const observer = new IntersectionObserver(async ([entry]) => {
        if (entry.isIntersecting && hasMore && !loading) {
          await onLoadMore();
        }
        return Promise.resolve();
      });

      observer.observe(anchor);

      return () => {
        observer.disconnect();
      };
    }

    return () => undefined;
  }, [hasMore, loading, onLoadMore]);

  return <>{children(<span ref={anchorRef} aria-hidden />)}</>;
};

export default IntersectionInfiniteList;
