import ListEntitiesItem from "components/ListEntitiesItem";
import { VcsProvider } from "types/generated";
import Box from "ds/components/Box";
import ListEntitiesItemTitle from "components/ListEntitiesItem/Title";
import ListEntitiesItemDescription from "components/ListEntitiesItem/Description";
import MetaInfoListItem from "components/MetaInfoList/Item";
import useTypedFlags from "hooks/useTypedFlags";
import useTypedContext from "hooks/useTypedContext";
import { AccountContext } from "views/AccountWrapper";
import { useDrawerVisibilityForId } from "ds/components/DrawerNew/useDrawerVisibilityForId";

import {
  BUILT_IN_GITHUB_INTEGRATION_DESCRIPTION,
  BUILT_IN_GITHUB_INTEGRATION_NAME,
  COLUMN_GAP,
  COLUMN_ORDER,
  GITHUB_INTEGRATION_DETAILS_DRAWER_ID,
} from "./constants";
import VCSProviderIcon from "../../components/ProviderIcon";
import VCSIntegrationsBuiltInGitHubListItemDropdownActions from "./Item/BuiltInGitHubDropdownActions";

type VCSIntegrationsBuiltInGitHubListItemProps = {
  onEdit: () => void;
  onShowDetails: () => void;
};

const VCSIntegrationsBuiltInGitHubListItem = ({
  onEdit,
  onShowDetails,
}: VCSIntegrationsBuiltInGitHubListItemProps) => {
  const { aggregatedVcsChecks } = useTypedFlags();
  const isDetailsDrawerVisible = useDrawerVisibilityForId(GITHUB_INTEGRATION_DETAILS_DRAWER_ID);
  const { viewer } = useTypedContext(AccountContext);

  return (
    <ListEntitiesItem
      direction="row"
      align="center"
      justify="between"
      grid
      gridTemplate={COLUMN_ORDER}
      gap={`0 ${COLUMN_GAP}`}
      isActive={isDetailsDrawerVisible}
      role="row"
    >
      <Box gap="large" role="rowheader">
        <VCSProviderIcon provider={VcsProvider.Github} tile />

        <Box grow="1">
          <Box direction="column" fullWidth>
            <ListEntitiesItemTitle
              title={BUILT_IN_GITHUB_INTEGRATION_NAME}
              titleVariant="p-body2"
            />

            <Box direction="row" align="start" fullWidth>
              <ListEntitiesItemDescription
                noPadding
                description={BUILT_IN_GITHUB_INTEGRATION_DESCRIPTION}
                tooltipMode
              />
            </Box>
          </Box>
        </Box>
      </Box>
      <Box role="cell">
        <MetaInfoListItem>&minus;</MetaInfoListItem>
      </Box>

      {aggregatedVcsChecks && (
        <Box shrink="0" role="cell">
          <VCSIntegrationsBuiltInGitHubListItemDropdownActions
            onShowDetails={onShowDetails}
            onEdit={onEdit}
            canManageVCSIntegrations={viewer.admin}
          />
        </Box>
      )}
    </ListEntitiesItem>
  );
};

export default VCSIntegrationsBuiltInGitHubListItem;
