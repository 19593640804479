import LinkNew from "ds/components/LinkNew";
import Typography from "ds/components/Typography";
import useAnalytics from "hooks/useAnalytics";
import { RunIgnoredTrigger, RunIgnoredTriggerReasonType } from "types/generated";

import styles from "./styles.module.css";

type IgnoredRunPoliciesListProps = {
  ignoredRun: RunIgnoredTrigger;
  variant: "p-body2" | "p-body3";
};

const IgnoredRunPoliciesList = ({ ignoredRun, variant }: IgnoredRunPoliciesListProps) => {
  const trackSegmentAnalyticsEvent = useAnalytics();

  if (ignoredRun.reasonType !== RunIgnoredTriggerReasonType.CustomPolicy) {
    return null;
  }

  const handlePolicyClick = () => {
    trackSegmentAnalyticsEvent("Ignored run policy clicked", {
      customPolicyCount: ignoredRun.policyAttachments.length,
    });
  };

  return (
    <ul className={styles.list}>
      {ignoredRun.policyAttachments.map(({ policy, policyName }) => {
        return (
          <li key={policyName}>
            <Typography tag="span" variant={variant}>
              {policy ? (
                <LinkNew
                  variant="secondary"
                  size="inherit"
                  href={`/policy/${policy.id}`}
                  onPress={handlePolicyClick}
                >
                  {policy.name}
                </LinkNew>
              ) : (
                `${policyName} (deleted)`
              )}
            </Typography>
          </li>
        );
      })}
    </ul>
  );
};

export default IgnoredRunPoliciesList;
