import { Moon, Sun } from "components/icons/generated";
import { SyntaxTheme } from "types/SyntaxTheme";
import { Theme } from "types/Theme";
import { ThemePalette } from "views/Theme";

export const getPreviewSyntaxTheme = (syntaxTheme: SyntaxTheme) => {
  return `/images/syntax_themes/${syntaxTheme}.png`;
};

export const getPreviewTheme = (theme: ThemePalette) => {
  return `/images/themes/spacelift-${theme}.png`;
};

export const getSyntaxThemeTitle = (themeMode: ThemePalette) => {
  if ([Theme.Dark, Theme.HighContrastDark].includes(themeMode)) {
    return "for Dark mode";
  }

  return "for Light mode";
};

export const getSyntaxThemeIcon = (themeMode: ThemePalette) => {
  if ([Theme.Dark, Theme.HighContrastDark].includes(themeMode)) {
    return Moon;
  }

  return Sun;
};

export const getSyntaxThemeDescription = (themeMode: ThemePalette) => {
  if ([Theme.Dark, Theme.HighContrastDark].includes(themeMode)) {
    return `This theme will be active when your system is set to “dark mode”`;
  }

  return `This theme will be active when your system is set to “light mode”`;
};
