import Box from "ds/components/Box";
import TextEllipsis from "ds/components/TextEllipsis";
import Typography from "ds/components/Typography";
import ComboBoxHighlightText from "ds/components/ComboBox/HighlightText";
import ComboBoxInputValueItem from "ds/components/ComboBox/InputValueItem";
import ListBoxItem from "ds/components/ListBox/Item";

type OutputReferencesDrawerComboBoxItemProps = {
  /**
   * React-aria required id attribute on the children of the Collection for caching purposes.
   */
  id: string;
  label: string;
  description: string;
  isInputValueItem?: boolean;
};

const OutputReferencesDrawerComboBoxItem = ({
  id,
  label,
  description,
  isInputValueItem,
}: OutputReferencesDrawerComboBoxItemProps) => {
  if (isInputValueItem) {
    return (
      <ComboBoxInputValueItem textValue={id}>
        <Typography tag="span" variant="p-body3" color="primary">
          {label}
        </Typography>
      </ComboBoxInputValueItem>
    );
  }

  return (
    <ListBoxItem id={id} textValue={label} aria-describedby={`${id}-description`}>
      <Box direction="column" gap="small" justify="center" align="start" fullWidth>
        <ComboBoxHighlightText text={label} />

        <Box fullWidth>
          <TextEllipsis tooltipPlacement="left" tooltipWidthMode="maxWidthSm" tooltip={description}>
            {(props) => (
              <Typography
                {...props}
                id={`${id}-description`}
                tag="span"
                variant="p-body3"
                color="secondary"
              >
                {description}
              </Typography>
            )}
          </TextEllipsis>
        </Box>
      </Box>
    </ListBoxItem>
  );
};

export default OutputReferencesDrawerComboBoxItem;
