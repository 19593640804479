import { useEffect, useState } from "react";

import { Moon, Sun } from "components/icons/generated";
import ViewHeader from "components/ViewHeader";
import ViewHeaderTitle from "components/ViewHeader/Title";
import Box from "ds/components/Box";
import FormField from "ds/components/Form/Field";
import Typography from "ds/components/Typography";
import useTypedContext from "hooks/useTypedContext";
import { SyntaxTheme } from "types/SyntaxTheme";
import { ThemeContext, ThemePalette } from "views/Theme";
import { AccountContext } from "views/AccountWrapper";
import useTitle from "hooks/useTitle";
import { updateSegmentUser, updateUserflowUser } from "shared/Analytics";
import Toggle from "ds/components/Toggle";
import { AnalyticsPagePersonal } from "hooks/useAnalytics/pages/personal";
import useAnalytics from "hooks/useAnalytics";
import { Theme } from "types/Theme";

import { AVAILABLE_SYNTAX_THEMES } from "./constants";
import styles from "./styles.module.css";
import ThemeAppearanceSectionTitle from "./SectionTitle";
import {
  getPreviewSyntaxTheme,
  getPreviewTheme,
  getSyntaxThemeDescription,
  getSyntaxThemeIcon,
  getSyntaxThemeTitle,
} from "./utils";
import ThemeAppearanceTile from "./Tile";

const ThemeAppearance = () => {
  const {
    isDarkMode,
    currentTheme,
    changeTheme,
    syncThemeWithSystem,
    setSyncThemeWithSystem,
    currentLightModeSyntaxTheme,
    currentDarkModeSyntaxTheme,
    setCurrentLightModeSyntaxTheme,
    setCurrentDarkModeSyntaxTheme,
  } = useTypedContext(ThemeContext);

  const trackSegmentEvent = useAnalytics({
    page: AnalyticsPagePersonal.PersonalThemeAppearance,
  });

  const { accountName } = useTypedContext(AccountContext);

  useTitle(`Personal Settings · Theme appearance · ${accountName}`);

  const [currentThemeVisibleToConfigure, setCurrentThemeVisibleToConfigure] =
    useState<ThemePalette>(currentTheme);

  const isSyntaxThemeActive = (syntaxTheme: SyntaxTheme) => {
    if ([Theme.HighContrastDark, Theme.Dark].includes(currentThemeVisibleToConfigure)) {
      return currentDarkModeSyntaxTheme === syntaxTheme;
    }

    return currentLightModeSyntaxTheme === syntaxTheme;
  };

  const handleSyntaxThemeChange = (syntaxTheme: SyntaxTheme) => () => {
    trackSegmentEvent("Changed syntax theme", { syntaxTheme });
    if ([Theme.HighContrastDark, Theme.Dark].includes(currentThemeVisibleToConfigure)) {
      setCurrentDarkModeSyntaxTheme(syntaxTheme);
    } else {
      setCurrentLightModeSyntaxTheme(syntaxTheme);
    }
  };

  const handleTheme = (theme: ThemePalette) => () => {
    if (!syncThemeWithSystem) {
      trackSegmentEvent("Changed theme", { theme: theme });
      updateUserflowUser("colorTheme", theme);
      updateSegmentUser("colorTheme", theme);
      changeTheme(theme);
    }

    setCurrentThemeVisibleToConfigure(theme);
  };

  const toggleSystemMode = () => {
    const newValue = !syncThemeWithSystem;
    setSyncThemeWithSystem(!syncThemeWithSystem);
    trackSegmentEvent("Changed system mode", { systemMode: newValue });
    updateUserflowUser("usesSystemTheme", String(newValue));
    updateSegmentUser("usesSystemTheme", String(newValue));
  };

  useEffect(() => {
    // sync the visible and active theme when the system mode is enabled
    if (syncThemeWithSystem && currentThemeVisibleToConfigure !== currentTheme) {
      setCurrentThemeVisibleToConfigure(currentTheme);
    }
  }, [syncThemeWithSystem, currentTheme, currentThemeVisibleToConfigure]);

  return (
    <>
      <ViewHeader firstLevel>
        <ViewHeaderTitle tag="h2">Theme appearance</ViewHeaderTitle>
      </ViewHeader>

      <Box direction="column" grow="1" className={styles.wrapper} padding="large" fullWidth>
        <Box direction="column" fullWidth>
          <Typography variant="p-t5" tag="h2" margin="0 0 small 0">
            Theme mode
          </Typography>

          <Typography variant="p-body2" tag="p" color="secondary">
            Choose how Spacelift looks to you.
          </Typography>

          <FormField helperText="Automatically switch between light, dark and high contrast modes.">
            <Toggle variant="switch" onChange={toggleSystemMode} isSelected={syncThemeWithSystem}>
              Sync with system
            </Toggle>
          </FormField>

          {!syncThemeWithSystem && (
            <Box direction="row" fullWidth gap="large" margin="large 0 0 0" wrap>
              <ThemeAppearanceTile
                selected={currentThemeVisibleToConfigure === Theme.Light}
                onClick={handleTheme(Theme.Light)}
                previewSrc={getPreviewTheme(Theme.Light)}
                title="Light mode"
              />
              <ThemeAppearanceTile
                selected={currentThemeVisibleToConfigure === Theme.Dark}
                onClick={handleTheme(Theme.Dark)}
                previewSrc={getPreviewTheme(Theme.Dark)}
                title="Dark mode"
              />
              <ThemeAppearanceTile
                selected={currentThemeVisibleToConfigure === Theme.HighContrastLight}
                onClick={handleTheme(Theme.HighContrastLight)}
                previewSrc={getPreviewTheme(Theme.HighContrastLight)}
                title="High contrast light"
              />
              <ThemeAppearanceTile
                selected={currentThemeVisibleToConfigure === Theme.HighContrastDark}
                onClick={handleTheme(Theme.HighContrastDark)}
                previewSrc={getPreviewTheme(Theme.HighContrastDark)}
                title="High contrast dark"
              />
            </Box>
          )}
        </Box>

        {!syncThemeWithSystem && (
          <Box direction="column" margin="large 0 0 0" padding="medium 0 0 0">
            <Box direction="column" fullWidth padding="large" className={styles.syntaxThemesList}>
              <ThemeAppearanceSectionTitle
                icon={getSyntaxThemeIcon(currentThemeVisibleToConfigure)}
                title={`Syntax ${getSyntaxThemeTitle(currentThemeVisibleToConfigure)}`}
                description={getSyntaxThemeDescription(currentThemeVisibleToConfigure)}
              />

              <Box direction="row" gap="large" fullWidth wrap className={styles.syntaxWrapper}>
                {AVAILABLE_SYNTAX_THEMES.map((syntaxTheme) => (
                  <ThemeAppearanceTile
                    selected={isSyntaxThemeActive(syntaxTheme.key)}
                    onClick={handleSyntaxThemeChange(syntaxTheme.key)}
                    key={syntaxTheme.key}
                    previewSrc={getPreviewSyntaxTheme(syntaxTheme.key)}
                    title={syntaxTheme.title}
                  />
                ))}
              </Box>
            </Box>
          </Box>
        )}

        {syncThemeWithSystem && (
          <Box margin="large 0 0 0" gap="large" direction="column">
            <Box direction="column" fullWidth>
              <Box direction="column" fullWidth padding="large" className={styles.syntaxThemesList}>
                <ThemeAppearanceSectionTitle
                  icon={Sun}
                  title="Syntax for Day theme"
                  isActive={!isDarkMode}
                  description={getSyntaxThemeDescription(Theme.Light)}
                />

                <Box direction="row" gap="large" fullWidth wrap className={styles.syntaxWrapper}>
                  {AVAILABLE_SYNTAX_THEMES.map((syntaxTheme) => (
                    <ThemeAppearanceTile
                      selected={currentLightModeSyntaxTheme === syntaxTheme.key}
                      onClick={() => setCurrentLightModeSyntaxTheme(syntaxTheme.key)}
                      key={syntaxTheme.key}
                      previewSrc={getPreviewSyntaxTheme(syntaxTheme.key)}
                      title={syntaxTheme.title}
                    />
                  ))}
                </Box>
              </Box>
            </Box>
            <Box direction="column" fullWidth>
              <Box direction="column" fullWidth padding="large" className={styles.syntaxThemesList}>
                <ThemeAppearanceSectionTitle
                  icon={Moon}
                  title="Syntax for Night theme"
                  isActive={isDarkMode}
                  description={getSyntaxThemeDescription(Theme.Dark)}
                />

                <Box direction="row" gap="large" fullWidth wrap className={styles.syntaxWrapper}>
                  {AVAILABLE_SYNTAX_THEMES.map((syntaxTheme) => (
                    <ThemeAppearanceTile
                      selected={currentDarkModeSyntaxTheme === syntaxTheme.key}
                      onClick={() => setCurrentDarkModeSyntaxTheme(syntaxTheme.key)}
                      key={syntaxTheme.key}
                      previewSrc={getPreviewSyntaxTheme(syntaxTheme.key)}
                      title={syntaxTheme.title}
                    />
                  ))}
                </Box>
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
};

export default ThemeAppearance;
