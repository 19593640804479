import { useRef } from "react";

import SkipFocusButton, { SkipFocusButtonProps } from "./index";

type ReducedSkipFocusButtonProps = Omit<SkipFocusButtonProps, "skipTo">;

const useSkipFocusButtons = () => {
  const SkipFocusButtonRef = useRef<HTMLButtonElement>(null);
  const backButtonRef = useRef<HTMLButtonElement>(null);

  return [
    (props: ReducedSkipFocusButtonProps) => (
      <SkipFocusButton
        key="skip"
        ref={SkipFocusButtonRef}
        skipTo={backButtonRef.current}
        {...props}
      />
    ),
    (props: ReducedSkipFocusButtonProps) => (
      <SkipFocusButton
        key="back"
        ref={backButtonRef}
        skipTo={SkipFocusButtonRef.current}
        {...props}
      />
    ),
  ];
};

export default useSkipFocusButtons;
