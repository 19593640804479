import { moduleDocument, runDocument, stackDocument } from "./documents";

const Plan = `package spacelift

# 🦕 Feel free to remove commented code once your policy is ready. 🦕
#
# ⚠️ Plan policies only take effect once attached to a Stack or Module ⚠️
#
# Plan policy can be used to fail a run during a planning phase, or warn human
# reviewer about any suspicious changes. As input, it receives both Spacelift
# and Terraform data in the following form:
#
# {
#   "spacelift": {
#     "commit": {
#       "author": "string",
#       "branch": "string",
#       "created_at": "number (timestamp in nanoseconds)",
#       "message": "string"
#     },
#     "request": {
#       "timestamp_ns": "number - current Unix timestamp in nanoseconds"
#     },
#     "run": {
${runDocument(7)}
#     },
#     "stack": {
${stackDocument(7)}
#     },
#     "module": {
${moduleDocument(7)}
#     }
#   },
#   "terraform": {
#     "resource_changes": [
#       {
#         "address": "string",
#         "type": "string",
#         "name": "string",
#         "provider_name": "string",
#         "change": {
#           "actions": ["string - create, update, delete or no-op"],
#           "before": "optional object",
#           "after": "optional object"
#         }
#       }
#     ],
#     "terraform_version": "string"
#   }
# }
#
# Spacelift also provides a few helpers to make your rules more readable:
#
# - affected_resources: resources that are somehow affected by the plan;
# - created_resources: resources that are created by the plan;
# - deleted_resources: resources that are deleted by the plan;
# - recreated_resources: resources that are recreated (delete + create) by the plan;
# - updated_resources: resources that are updated in place by the plan;
#
# The policy may define "warn" rules (log for human users) or "deny" rules (fail
# the run). Each rule must come with a message - these are shown to the users,
# so please keep civil and informative.
#
# Below are a few examples of what you can do here - note the use of helpers:
#
# 1) Warn if too many changes are made:
# warn[sprintf("too many changes made (%d/%d)", [changes, threshold])] {
#   changes := count(affected_resources)
#   threshold := 10
#
#   changes > threshold
# }
#
# 2) Deny creating AWS IAM users:
# deny[sprintf("must not create AWS IAM users", [resource.address])] {
#   some resource
#   created_resources[resource]
#
#   resource.type == "aws_iam_user"
# }
#
# 3) Deny giving Spacelift static AWS credentials:
# deny[sprintf("Don't set static AWS creds, use integration (%s)", [resource.address])] {
#   some resource
#   created_resources[resource]
#
#   resource.type == "spacelift_environment_variable"
#   resource.change.after.name == "AWS_SECRET_ACCESS_KEY"
# }
#
# Now go ahead and unleash your inner lawgiver. For more information on the rule
# language, please visit https://www.openpolicyagent.org/docs/latest/
#
# NOTE:
# We prepend some boilerplate code to the policy to ensure that the outputs
# are defined. You can't change the type of our predefined rules (e.g. deny,
# flag, warn, sample) or you'll run into conflicting rule errors.
# For a detailed example and further explanation, refer to our documentation:
# https://docs.spacelift.io/concepts/policy#policy-structure
`;

export default Plan;
