import { AttachableVCSIntegrationsOption } from "components/AttachableVCSIntegrationsOptions/types";
import ComboBoxHighlightText from "ds/components/ComboBox/HighlightText";
import ListBoxItem from "ds/components/ListBox/Item";
import Typography from "ds/components/Typography";

type VcsIntegrationFieldComboBoxItemProps = AttachableVCSIntegrationsOption & {
  id: string;
  label: string;
};

const VcsIntegrationFieldComboBoxItem = ({
  id,
  label,
  hasInstallations,
}: VcsIntegrationFieldComboBoxItemProps) => {
  return (
    <ListBoxItem id={id} textValue={label}>
      <ComboBoxHighlightText text={label} />
      {hasInstallations === false && (
        <Typography tag="span" variant="p-body3" color="warning">
          Not installed
        </Typography>
      )}
    </ListBoxItem>
  );
};

export default VcsIntegrationFieldComboBoxItem;
