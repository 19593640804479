import { useEffect, useRef, useState } from "react";

import Box, { BoxProps } from "ds/components/Box";
import Typography from "ds/components/Typography";

import { ReactComponent as LogoAiSummary } from "./saturnHead.svg";
import styles from "./styles.module.css";

type LoaderAiSummaryProps = {
  logoSize?: number;
} & Omit<BoxProps, "children">;

const aiSentences = [
  "Preparing for liftoff...",
  "Warming up the AI engines...",
  "Scanning the galaxy for insights...",
  "AI thrusters are firing up...",
  "Initiating data countdown...",
  "AI systems are aligning...",
  "Engaging AI boosters...",
  "Setting coordinates for AI exploration...",
  "AI systems calibrated and ready!",
  "Charting new AI frontiers...",
  "Launching into the data cosmos.",
  "AI navigation systems online.",
  "AI exploration mission is a GO!",
  "AI data incoming in 3... 2... 1...",
];

const getRandomSentence = () => aiSentences[Math.floor(Math.random() * aiSentences.length)];
const ANIMATION_DURATION = 8000;

export const LoaderAiSummary = ({ logoSize = 130, ...boxProps }: LoaderAiSummaryProps) => {
  const [randomSentence, setRandomSentence] = useState(getRandomSentence());
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const typingAnimation = ref.current
      ?.getAnimations()
      .find((anime) => anime.animationName === styles.typing);

    const interval = setInterval(async () => {
      setRandomSentence(getRandomSentence());

      // restart animation
      typingAnimation?.cancel();
      typingAnimation?.play();
    }, ANIMATION_DURATION);

    return () => clearInterval(interval);
  }, []);

  return (
    <Box {...boxProps} className={styles.wrapper} justify="center">
      <div ref={ref} className={styles.typingContainer}>
        <Typography variant="p-body3" tag="span" align="center" color="tertiary">
          {randomSentence}
        </Typography>
      </div>
      <LogoAiSummary width={logoSize} />
    </Box>
  );
};
