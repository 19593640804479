import { create, show, useModal } from "@ebay/nice-modal-react";

import Box from "ds/components/Box";
import { ConfirmationModalMessage } from "ds/components/ConfirmationModal/Message";
import DeleteConfirmationModal from "ds/components/DeleteConfirmationModal";
import { StackDependencyDetail } from "types/generated";

import { DependencyLinkItem } from "./DependencyLink";
import styles from "./styles.module.css";

type DependenciesDeleteConfirmationModalProps = {
  onConfirm: () => void;
  itemStack: StackDependencyDetail;
  relatedStack: StackDependencyDetail;
};

const DependenciesDeleteConfirmationModal = create(function DependenciesDeleteConfirmationModal({
  onConfirm,
  itemStack,
  relatedStack,
}: DependenciesDeleteConfirmationModalProps) {
  const modal = useModal();

  const handleOnConfirm = () => {
    onConfirm();
    modal.hide();
  };

  return (
    <DeleteConfirmationModal
      title="Delete dependency"
      confirmationButtonLabel="Delete dependency"
      onConfirm={handleOnConfirm}
    >
      <ConfirmationModalMessage>
        <Box direction="column" fullWidth gap="medium">
          <span>Are you sure you want to delete the dependency between:</span>
          <ul className={styles.resourceList}>
            <DependencyLinkItem
              href={`/stack/${itemStack.id}/dependencies`}
              stackName={itemStack.name}
              onPress={modal.hide}
            />
            <DependencyLinkItem
              href={`/stack/${itemStack.id}/dependencies`}
              stackName={relatedStack.name}
              onPress={modal.hide}
            />
          </ul>
          <span>Removing the dependency will have irreversible consequences.</span>
        </Box>
      </ConfirmationModalMessage>
    </DeleteConfirmationModal>
  );
});

export const showDependenciesDeleteConfirmationModal = (
  props: DependenciesDeleteConfirmationModalProps
) => show(DependenciesDeleteConfirmationModal, props);
