import { memo, useEffect, useRef } from "react";

import useApplyFilter from "components/Filters/useApplyFilter";
import { Cross, Space, Tick } from "components/icons/generated";
import ListEntitiesItem from "components/ListEntitiesItem";
import ListEntitiesItemLink from "components/ListEntitiesItem/Link";
import MetaInfoListItem from "components/MetaInfoList/Item";
import TagsListFilterable from "components/TagsList/Filterable";
import Timestamp from "components/time/Timestamp";
import { WebhookSuggestions, searchWebhookSuggestionsDictionary } from "constants/webhook";
import Box from "ds/components/Box";
import Icon from "ds/components/Icon";
import Typography from "ds/components/Typography";
import { NamedWebhooksIntegration } from "types/generated";

import { COLUMN_GAP, COLUMN_ORDER } from "../constants";
import { showDeleteConfirmation } from "../DeleteConfirmation";
import WebhookOptionDropdown from "../OptionsDropdown";
import styles from "./styles.module.css";

type WebhookListItemProps = {
  item: NamedWebhooksIntegration;
  setRowHeight?: (size: number) => void;
};

const WebhookListItem = (props: WebhookListItemProps) => {
  const { item, setRowHeight } = props;

  const { applySpaceFilter, applyLabelFilter } = useApplyFilter<WebhookSuggestions>({
    searchSuggestionsDictionary: searchWebhookSuggestionsDictionary,
  });

  const rowRef = useRef<HTMLDivElement>(null);

  const handleRowHeight = () => {
    if (setRowHeight && rowRef.current) {
      setRowHeight(rowRef.current.getBoundingClientRect().height);
    }
  };

  // on every render, update the row height
  useEffect(handleRowHeight);

  return (
    <ListEntitiesItem
      direction="row"
      align="center"
      justify="between"
      grid
      gridTemplate={COLUMN_ORDER}
      gap={`0 ${COLUMN_GAP}`}
      ref={rowRef}
      role="row"
    >
      <Box direction="column" role="rowheader" align="stretch" justify="center">
        <ListEntitiesItemLink href={`/webhook/${item.id}`} title={item.name} />
        {/* TODO add applyFolderFilter once backend add filter */}
        <TagsListFilterable
          tags={item.labels}
          applyLabelFilter={applyLabelFilter(WebhookSuggestions.Label)}
          onExpand={handleRowHeight}
        />
      </Box>
      <Box direction="row" align="center" role="cell">
        {item.space && (
          <MetaInfoListItem
            applyFilter={() => applySpaceFilter(WebhookSuggestions.Space)(item.space.id)}
            className={styles.metaInfo}
            icon={Space}
            linkText={item.space.name}
            href={`/spaces/${item.space.id}`}
            type="space"
          />
        )}
      </Box>
      <Box direction="row" align="center" gap="small" role="cell">
        {item.enabled ? (
          <>
            <Icon src={Tick} color="success" />
            <Typography tag="span" variant="p-body2" color="success">
              Enabled
            </Typography>
          </>
        ) : (
          <>
            <Icon src={Cross} color="danger" />
            <Typography tag="span" variant="p-body2" color="danger">
              Disabled
            </Typography>
          </>
        )}
      </Box>
      <Box direction="row" align="center" role="cell">
        <Typography tag="span" variant="p-body2">
          <Timestamp timestamp={item.createdAt} />
        </Typography>
      </Box>
      <Box direction="row" align="center" role="cell">
        <WebhookOptionDropdown
          id={item.id}
          space={item.space}
          onDeleteClick={() => showDeleteConfirmation({ name: item.name, id: item.id })}
        />
      </Box>
    </ListEntitiesItem>
  );
};

export default memo(WebhookListItem);
