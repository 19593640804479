import React, { ReactNode } from "react";

import Box from "ds/components/Box";
import TooltipInfo from "ds/components/TooltipInfo";
import Typography from "ds/components/Typography";

import styles from "./styles.module.css";

type DependenciesColumnHeaderProps = {
  title: string;
  tooltip: ReactNode;
  children?: React.ReactNode;
};

const DependenciesColumnHeader = ({ title, tooltip, children }: DependenciesColumnHeaderProps) => {
  return (
    <Box direction="row" justify="between" className={styles.wrapper}>
      <Box direction="row" align="center" gap="medium">
        <Typography variant="p-t6" tag="h3" color="secondary">
          {title}
        </Typography>

        <TooltipInfo variant="modal" widthMode="maxWidthSm">
          {tooltip}
        </TooltipInfo>
      </Box>

      {children}
    </Box>
  );
};

export default DependenciesColumnHeader;
