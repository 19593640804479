import {
  AuditTrailWebhookDeleteHeadersInput,
  AuditTrailWebhookInput,
  AuditTrailWebhookSetHeadersInput,
} from "types/generated";
import { getHeadersDiff } from "components/Forms/HeadersField/getHeadersDiff";

import { AuditTrailFormFields } from "./types";

export type AuditTrailWebhookSubmit = {
  createAuditTrailSetWebhookInput: Omit<AuditTrailWebhookInput, "customHeaders">;
  auditTrailSetHeadersInput: AuditTrailWebhookSetHeadersInput;
  auditTrailDeleteHeadersInput: AuditTrailWebhookDeleteHeadersInput;
};
type ParseAuditTrailFormFieldsProps = {
  formFields: AuditTrailFormFields;
  customHeaderKeys: string[];
  isEdit: boolean;
};

const parseAuditTrailFormFields = ({
  formFields,
  customHeaderKeys,
  isEdit,
}: ParseAuditTrailFormFieldsProps): AuditTrailWebhookSubmit => {
  const { headersToSet, headersKeysToRemove } = getHeadersDiff(
    formFields.headers,
    customHeaderKeys,
    formFields.enabledHeaders
  );

  return {
    createAuditTrailSetWebhookInput: {
      endpoint: formFields.endpoint.trim(),
      secret: isEdit ? null : formFields.secret,
      enabled: formFields.enabled,
      includeRuns: formFields.includeRuns,
      // Don't send headers here, we set them in the headers mutations
    },
    auditTrailSetHeadersInput: {
      customHeaders: {
        entries: headersToSet,
      },
    },
    auditTrailDeleteHeadersInput: {
      customHeaderKeys: headersKeysToRemove,
    },
  };
};

export default parseAuditTrailFormFields;
