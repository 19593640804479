import { Space } from "types/generated";
import { createDrawer, createDrawerTrigger } from "ds/components/DrawerNew/utils";

import { SpacesDrawerMode, SpacesLayoutMode } from "../types";
import SpaceDrawerBuilder from "./Builder";
import SpaceDrawerView from "./View";

type SpaceDrawerProps = {
  drawerMode?: SpacesDrawerMode;
  space?: Space;
  layoutMode: SpacesLayoutMode;
  onClose: () => void;
  isRootAdmin: boolean;
};

const SpaceDrawer = createDrawer(
  ({ drawerMode, space, layoutMode, onClose, isRootAdmin }: SpaceDrawerProps) => {
    return drawerMode ? (
      <SpaceDrawerBuilder
        drawerMode={drawerMode}
        space={space}
        layoutMode={layoutMode}
        onClose={onClose}
      />
    ) : (
      <SpaceDrawerView space={space} isRootAdmin={isRootAdmin} onClose={onClose} />
    );
  }
);

export const showSpaceDrawer = createDrawerTrigger(SpaceDrawer);
