import { Controller, useForm } from "react-hook-form";
import differenceWith from "lodash-es/differenceWith";
import { useCallback, useMemo } from "react";

import Box from "ds/components/Box";
import FormField from "ds/components/Form/Field";
import Input from "ds/components/Input";
import FlashContext from "components/FlashMessages/FlashContext";
import useTypedContext from "hooks/useTypedContext";
import Button from "ds/components/Button";
import Banner from "ds/components/Banner";
import { TooltipModalTitle } from "ds/components/TooltipModal/Title";
import TooltipModalBody from "ds/components/TooltipModal/Body";
import { useObserveForWarning } from "components/WarningContext/useObserveForWarning";
import { getDocsUrl } from "utils/getDocsUrl";
import ReadMoreDocsLink from "components/ReadMoreDocsLink";
import ComboBox from "ds/components/ComboBox";
import ComboBoxDefaultItem from "ds/components/ComboBox/DefaultItem";

import { AttachContextFormFields, AttachContextFormProps } from "./types";
import styles from "./styles.module.css";
import { useNewStackAnalyticsSegementEvent } from "../../useNewStackAnalyticsSegementEvent";

const priorityFieldOptions = {
  valueAsNumber: true,
  min: 0,
  required: true,
  validate: (value: number) => /^[0-9]+$/.test(value.toString()),
};

const AttachContextForm = ({
  spaceContexts,
  attachedContextIds,
  onAttachContext,
  children,
}: AttachContextFormProps) => {
  const { onError, reportSuccess } = useTypedContext(FlashContext);

  const trackSegmentEvent = useNewStackAnalyticsSegementEvent();

  const form = useForm<AttachContextFormFields>({
    defaultValues: {
      contextId: "",
      priority: 0,
    },
    mode: "onChange",
  });

  const { control, register, handleSubmit, formState, reset, watch } = form;

  const contextId = watch("contextId");

  useObserveForWarning(
    !!contextId,
    <>
      You have not attached the <strong>selected context</strong>. Do you want to continue without
      attaching it?
    </>
  );

  const contexts = useMemo(
    () =>
      differenceWith(
        spaceContexts.map((context) => ({
          value: context.id,
          label: context.name,
        })),
        attachedContextIds,
        (a, b) => a.value === b
      ),
    [spaceContexts, attachedContextIds]
  );

  const onSave = useCallback(
    async (formData: AttachContextFormFields) => {
      try {
        const contextAttachId = await onAttachContext(formData.contextId, formData.priority);

        if (contextAttachId) {
          reportSuccess({ message: "Context successfully attached" });
          trackSegmentEvent("Context attached");

          reset();
        }
      } catch (error) {
        onError(error);
      }
    },
    [onAttachContext, reportSuccess, reset, onError, trackSegmentEvent]
  );

  return (
    <>
      <Banner variant="info">
        You can only attach contexts from the current space and parent spaces that you inherit from.
      </Banner>
      <Box gap="medium" padding="large" direction="column" className={styles.formWrapper}>
        <Box gap="medium" grid gridTemplate="1fr 6.5rem" align="end">
          <Controller
            name="contextId"
            control={control}
            rules={{ required: "Context is required." }}
            render={({ field, fieldState }) => (
              <ComboBox
                label="Select context"
                error={fieldState.error?.message}
                items={contexts}
                value={field.value}
                onChange={field.onChange}
                tooltipInfo={
                  <>
                    <TooltipModalTitle>Context</TooltipModalTitle>
                    <TooltipModalBody align="start">
                      Context is a bundle of configuration elements (environment variables and
                      mounted files) independent of any stack that can be managed separately and
                      attached to as many or as few stacks as necessary.
                      <ReadMoreDocsLink
                        // TODO: update docs
                        docsUrl={getDocsUrl("/concepts/configuration/context")}
                      />
                    </TooltipModalBody>
                  </>
                }
              >
                {(item) => <ComboBoxDefaultItem id={item.value} label={item.label} />}
              </ComboBox>
            )}
          />

          <FormField
            noMargin
            label="Priority"
            tooltipInfoVariant="modal"
            tooltipInfo={
              <>
                <TooltipModalTitle>Priority</TooltipModalTitle>
                <TooltipModalBody align="start">
                  By default contexts attached to stack will be executed from lowest (0) value. You
                  can change the ordering by hovering over Priority tag and clicking “edit priority”
                  from the menu. Edited values don't need to be unique.
                  <br />
                  <br />
                  Ordering can be also changed later from contexts tab within stack view. Please
                  note that auto-attached contexts prioritisation can not be changed and is based on
                  alphabetical order.
                  <ReadMoreDocsLink
                    // TODO: update docs
                    docsUrl={getDocsUrl("/concepts/configuration/context#a-note-on-priority")}
                  />
                </TooltipModalBody>
              </>
            }
          >
            {({ ariaInputProps }) => (
              <Input
                error={!!formState.errors.priority}
                aria-label="Priority"
                {...register("priority", priorityFieldOptions)}
                {...ariaInputProps}
              />
            )}
          </FormField>
        </Box>
        <Box justify="end" margin="medium 0 0 0">
          <Button
            variant="contrast"
            type="submit"
            onClick={handleSubmit(onSave)}
            loading={formState.isSubmitting}
            disabled={!formState.isValid || formState.isSubmitting}
          >
            Attach
          </Button>
        </Box>

        {children}
      </Box>
    </>
  );
};

export default AttachContextForm;
