import { Collection, Header, Section } from "react-aria-components";

import Typography from "../Typography";
import styles from "./styles.module.css";

type ListBoxSectionProps<Collection extends object> = {
  id: string;
  label?: string;
  items: Array<Collection>;
  children: (Collection: Collection) => JSX.Element;
};

const ListBoxSection = <Collection extends object>({
  id,
  label,
  items,
  children,
}: ListBoxSectionProps<Collection>) => {
  return (
    <Section className={styles.listBoxSection} id={id}>
      {label && (
        <Header className={styles.listBoxSectionHeader}>
          <Typography tag="span" variant="p-t7" color="secondary" transform="uppercase">
            {label}
          </Typography>
        </Header>
      )}
      <Collection items={items}>{children}</Collection>
    </Section>
  );
};

ListBoxSection.displayName = "DS.ListBox.Section";

export default ListBoxSection;
