import ListEntitiesItem from "components/ListEntitiesItem";
import ListEntitiesItemLink from "components/ListEntitiesItem/Link";
import Box from "ds/components/Box";
import ButtonNew from "ds/components/Button/New";
import { useDrawerVisibilityForId } from "ds/components/DrawerNew/useDrawerVisibilityForId";
import { ModuleMetadata } from "types/generated";

import { COLUMN_ORDER } from "./constants";

type ModuleVersionSubmodulesItemProps = {
  canShowInstructions: boolean;
  item: ModuleMetadata;
  handleOpenInstructions: (submodule: ModuleMetadata) => () => void;
};
const ModuleVersionSubmodulesItem = ({
  canShowInstructions,
  item,
  handleOpenInstructions,
}: ModuleVersionSubmodulesItemProps) => {
  const isActive = useDrawerVisibilityForId(item.name);
  return (
    <ListEntitiesItem
      role="row"
      key={item.name}
      justify="between"
      direction="row"
      isActive={isActive}
      grid
      gridTemplate={COLUMN_ORDER}
      gap="medium"
    >
      <Box role="rowheader">
        <ListEntitiesItemLink
          title={item.name}
          href={`../submodule/${encodeURIComponent(item.name)}`}
        />
      </Box>
      {canShowInstructions && item.inputs && (
        <Box role="cell">
          <ButtonNew
            variant="secondary"
            size="small"
            aria-label="Show instructions"
            onPress={handleOpenInstructions(item)}
          >
            Instructions
          </ButtonNew>
        </Box>
      )}
    </ListEntitiesItem>
  );
};

export default ModuleVersionSubmodulesItem;
