import InfiniteLoader from "react-window-infinite-loader";
import { useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { NetworkStatus, useQuery } from "@apollo/client";
import getUnixTime from "date-fns/getUnixTime";
import subDays from "date-fns/subDays";

import useTitle from "hooks/useTitle";
import useTypedContext from "hooks/useTypedContext";
import { AccountContext } from "views/AccountWrapper";
import PageInfo from "components/PageWrapper/Info";
import ListContentWrapper from "components/ListContentWrapper";
import EmptyState from "ds/components/EmptyState";
import { EmptystateSearchNoResultsColored } from "components/icons/generated";
import ListEntitiesNew from "components/ListEntitiesNew";
import FlashContext from "components/FlashMessages/FlashContext";
import {
  getFiltersPredicationFromURI,
  getSortOptionFromURI,
  makeConstraintByType,
} from "components/Filters/helpers";
import { getSearchQuery } from "components/SearchInput/helpers";
import useTierFeature from "views/Account/hooks/useTierFeature";
import {
  BillingTierFeature,
  SearchAuditTrailEntriesOutput,
  SearchQueryFieldConstraintTimeInLast,
  SearchSuggestionsFieldType,
} from "types/generated";
import useErrorHandle from "hooks/useErrorHandle";
import PageLoading from "components/loading/PageLoading";
import NotFoundPage from "components/error/NotFoundPage";
import { uniqByKey } from "utils/uniq";
import { SavedFilterView } from "components/Filters/types";
import DatepickerRange from "ds/components/Datepicker/Range";
import useDatepickerRange from "ds/components/Datepicker/useDatepickerRange";
import { AnalyticsPageAuditTrail } from "hooks/useAnalytics/pages/auditTrail";
import useAnalytics from "hooks/useAnalytics";
import { DateRange } from "ds/components/Datepicker/types";

import { SEARCH_AUDIT_TRAIL_ENTRIES } from "./gql";
import {
  AUDIT_TRAIL_ENTRIES_PER_PAGE,
  INITIAL_PERIOD,
  INITIAL_SORT_DIRECTION,
  INITIAL_SORT_OPTION,
  PERIODS,
  POLL_INTERVAL,
} from "./constants";
import AuditTrailEntriesTableHeader from "./components/TableHeader";
import AuditTrialEntriesFiltersLayout from "./components/FiltersLayout";
import AuditTrailListItemVirtualized from "./components/ListItem/Virtualized";

const maxDate = new Date();

const AuditTrailEntries = () => {
  const [currentSavedView, setCurrentSavedView] = useState<SavedFilterView | undefined>(undefined);
  const { accountName } = useTypedContext(AccountContext);
  const { onError } = useTypedContext(FlashContext);
  const [urlParams] = useSearchParams();
  const trackSegmentAnalyticsEvent = useAnalytics({
    page: AnalyticsPageAuditTrail.Logs,
  });

  const { dateRange, datePeriod, handleSetDateRange } = useDatepickerRange({
    initialPeriod: INITIAL_PERIOD,
    periods: PERIODS,
  });

  const onSetDateRange = (range: DateRange, period?: SearchQueryFieldConstraintTimeInLast) => {
    handleSetDateRange(range, period);
    trackSegmentAnalyticsEvent("Timerange changed", {
      period,
      range,
    });
  };

  const sortOptionFields = useMemo(
    () => getSortOptionFromURI(urlParams, INITIAL_SORT_OPTION, INITIAL_SORT_DIRECTION),
    [urlParams]
  );

  const predicates = useMemo(() => {
    const predicatesMap = getFiltersPredicationFromURI(urlParams, true);

    if (!predicatesMap) {
      return [];
    }

    if (dateRange) {
      predicatesMap?.set("createdAt", {
        field: "createdAt",
        exclude: null,
        constraint: makeConstraintByType(
          SearchSuggestionsFieldType.Time,
          typeof dateRange === "string"
            ? [dateRange]
            : [getUnixTime(dateRange.start), getUnixTime(dateRange.end)]
        ),
      });
    }

    return Array.from(predicatesMap.values());
  }, [dateRange, urlParams]);

  const searchInput = getSearchQuery(urlParams);

  const isActive = useTierFeature(BillingTierFeature.Auditing);

  const { data, error, fetchMore, stopPolling, networkStatus, previousData } = useQuery<{
    searchAuditTrailEntries: SearchAuditTrailEntriesOutput;
    auditTrailRetentionDays: number;
  }>(SEARCH_AUDIT_TRAIL_ENTRIES, {
    onError,
    nextFetchPolicy: "cache-first",
    variables: {
      input: {
        first: AUDIT_TRAIL_ENTRIES_PER_PAGE,
        after: null,
        fullTextSearch: searchInput,
        orderBy: sortOptionFields,
        predicates,
      },
    },
    pollInterval: POLL_INTERVAL,
    skip: !isActive,
  });

  const minDate = useMemo(
    () => subDays(maxDate, data?.auditTrailRetentionDays || 0),
    [data?.auditTrailRetentionDays]
  );

  const auditTrailEntries = useMemo(
    () =>
      (data?.searchAuditTrailEntries || previousData?.searchAuditTrailEntries)?.edges.map(
        (edge) => edge.node
      ) || [],
    [data?.searchAuditTrailEntries, previousData?.searchAuditTrailEntries]
  );

  useTitle(`Audit Trail · ${accountName}`);

  const ErrorContent = useErrorHandle(error);

  if (ErrorContent) {
    stopPolling();
    return ErrorContent;
  }

  if (!isActive) {
    return (
      <EmptyState
        icon={EmptystateSearchNoResultsColored}
        title="No results"
        caption="Audit trail is not available for this account."
      />
    );
  }

  const isLoading = networkStatus === NetworkStatus.loading;

  if (isLoading && auditTrailEntries.length === 0) {
    return <PageLoading />;
  }

  if (!data?.searchAuditTrailEntries && !previousData?.searchAuditTrailEntries) {
    return <NotFoundPage />;
  }

  const isItemLoaded = (value: number) => value < auditTrailEntries.length;
  const isPageEmpty = !isLoading && !error && auditTrailEntries.length === 0;
  const hasNoResultsWithFiltering = searchInput.length > 0 || predicates.length > 0;

  const loadMoreItems = async () => {
    try {
      if (
        data?.searchAuditTrailEntries?.pageInfo.endCursor &&
        data?.searchAuditTrailEntries?.pageInfo.hasNextPage
      ) {
        await fetchMore({
          updateQuery: (prev, { fetchMoreResult }) => {
            const previousData = prev?.searchAuditTrailEntries ? prev : data;

            if (!fetchMoreResult || !fetchMoreResult?.searchAuditTrailEntries) return previousData;

            const result = fetchMoreResult?.searchAuditTrailEntries;

            if (!result || !result?.edges?.length) return previousData;

            const edges = uniqByKey(
              [...(previousData?.searchAuditTrailEntries?.edges || []), ...result.edges],
              "cursor"
            );

            return {
              auditTrailRetentionDays: previousData.auditTrailRetentionDays,
              searchAuditTrailEntries: {
                ...result,
                edges,
              },
            };
          },
          variables: {
            input: {
              first: AUDIT_TRAIL_ENTRIES_PER_PAGE,
              after: data.searchAuditTrailEntries.pageInfo.endCursor,
              fullTextSearch: searchInput,
              predicates,
              orderBy: sortOptionFields,
            },
          },
        });
      }
    } catch (error) {
      console.log("Error loading more audit trail entries", error);
      onError(error);
    }
  };

  return (
    <>
      <PageInfo title="Logs">
        <DatepickerRange
          isButton
          withTime
          periods={PERIODS}
          initialPeriod={datePeriod}
          endDate={dateRange.end}
          startDate={dateRange.start}
          onChange={onSetDateRange}
          minDate={minDate}
          maxDate={maxDate}
        />
      </PageInfo>
      <ListContentWrapper>
        <AuditTrialEntriesFiltersLayout
          currentSavedView={currentSavedView}
          setCurrentSavedView={setCurrentSavedView}
          predicates={predicates}
        >
          {auditTrailEntries.length > 0 && <AuditTrailEntriesTableHeader />}

          {isLoading && auditTrailEntries.length === 0 && <PageLoading />}

          {isPageEmpty && hasNoResultsWithFiltering && (
            <EmptyState
              title="No results"
              caption="Try different filters."
              icon={EmptystateSearchNoResultsColored}
              announce
            />
          )}

          {isPageEmpty && !hasNoResultsWithFiltering && (
            <EmptyState title="There are no audit trail entries yet." />
          )}

          {!isPageEmpty && (
            <InfiniteLoader
              isItemLoaded={isItemLoaded}
              itemCount={auditTrailEntries.length + AUDIT_TRAIL_ENTRIES_PER_PAGE}
              loadMoreItems={loadMoreItems}
            >
              {({ onItemsRendered }) => (
                <ListEntitiesNew
                  itemCount={auditTrailEntries.length}
                  itemProps={{
                    items: auditTrailEntries,
                  }}
                  virtualizedItem={AuditTrailListItemVirtualized}
                  itemKey={(index) => auditTrailEntries[index].id}
                  onItemsRendered={onItemsRendered}
                />
              )}
            </InfiniteLoader>
          )}
        </AuditTrialEntriesFiltersLayout>
      </ListContentWrapper>
    </>
  );
};

export default AuditTrailEntries;
