import { ApolloClient, ApolloProvider } from "@apollo/client";
import { createBrowserRouter, Outlet, RouterProvider } from "react-router-dom";
import { CookiesProvider } from "react-cookie";
import NiceModal from "@ebay/nice-modal-react";
import { ComponentType, ReactNode } from "react";

import FlashMessages from "components/FlashMessages";
import { FlashContextProvider } from "components/FlashMessages/FlashContext";
import AriaRouterProvider from "components/AriaRouterProvider";
import SelfHostedInstanceProvider from "views/SelfHosted/InstanceProvider";
import Theme from "views/Theme";

import ErrorBoundaryFallback from "./ErrorBoundaryFallback";
import Routes from "./Routes";

type AppProps = {
  client: ApolloClient<unknown>;
  ErrorBoundary?: ComponentType<{ children: ReactNode }>;
};

type AppProvidersProps = AppProps & {
  children: ReactNode;
};

const AppProviders = ({ client, children }: AppProvidersProps) => (
  <AriaRouterProvider>
    <FlashContextProvider>
      <FlashMessages />
      <ApolloProvider client={client}>
        <CookiesProvider>
          <SelfHostedInstanceProvider>
            {/*
              FYI: we should use 2 NiceModal.Providers to provide the context to the whole app -
              one for the AccountWrapper and one for the rest of the app (pages on which users are not
              logged in) 
            */}
            <NiceModal.Provider>
              <Theme>{children}</Theme>
            </NiceModal.Provider>
          </SelfHostedInstanceProvider>
        </CookiesProvider>
      </ApolloProvider>
    </FlashContextProvider>
  </AriaRouterProvider>
);

const App = ({ client, ErrorBoundary = ({ children }) => children }: AppProps) => {
  const router = createBrowserRouter(
    [
      {
        path: "/*",
        errorElement: <ErrorBoundaryFallback />,
        element: (
          /**
           * We wrap both the root route and the entire App in a Bugsnag ErrorBoundary,
           * because React Router’s built-in error boundary hides errors from external boundaries.
           * We can’t disable or bypass it, so this approach ensures that errors are reported to Bugsnag
           * and our fallback UI is shown.
           * Reference: https://github.com/remix-run/react-router/discussions/10166#discussion-4937920
           */
          <ErrorBoundary>
            <AppProviders client={client}>
              <Outlet />
            </AppProviders>
          </ErrorBoundary>
        ),
        children: [
          {
            path: "*",
            element: <Routes />,
          },
        ],
      },
    ],
    {
      future: {
        v7_relativeSplatPath: true,
      },
    }
  );

  return (
    <RouterProvider
      router={router}
      future={{
        v7_startTransition: false,
      }}
    />
  );
};

export default App;
