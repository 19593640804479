import { ForwardedRef, forwardRef, ReactNode } from "react";
import { Button } from "react-aria-components";
import cx from "classnames";

import styles from "./styles.module.css";

export type AriaButtonInputProps = {
  id?: string;
  className?: string;
  color?: "default" | "inversed";
  size?: "small" | "regular";
  error?: boolean;
  disabled?: boolean;
  active?: boolean;
  children?: ReactNode;
};

/**
 * This component is a Button styled as an input field, used in aria Select component.
 */
const AriaButtonInput = forwardRef(function AriaButtonInput(
  props: AriaButtonInputProps,
  ref: ForwardedRef<HTMLButtonElement>
) {
  const {
    className,
    size = "regular",
    color = "default",
    disabled,
    error = false,
    active,
    ...restProps
  } = props;

  return (
    <Button
      ref={ref}
      className={cx(
        styles.input,
        styles.buttonInput,
        styles[size],
        styles[color],
        {
          [styles.error]: error,
          [styles.disabled]: disabled,
          [styles.active]: active,
        },
        className
      )}
      isDisabled={disabled}
      {...restProps}
    />
  );
});

AriaButtonInput.displayName = "DS.AriaButtonInput";

export default AriaButtonInput;
