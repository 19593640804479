import { forwardRef, ReactElement, ReactNode, useCallback, useMemo, useRef, useState } from "react";
import cx from "classnames";

import DropdownWrapper from "ds/components/Dropdown/DropdownWrapper";
import useOutsideClick from "hooks/useOutsideClick";
import { ChevronDown } from "components/icons/generated";
import useEscapeKeypress from "hooks/useEscapeKeyPress";
import { AriaInputProps } from "ds/components/Form/useAriaInputProps";

import DropdownList from "../Dropdown/List";
import { DropdownPosition } from "../Dropdown/types";
import BaseAction from "../BaseAction";
import SelectOption from "./Option";
import { renderOptionLabel } from "./helpers";
import TextEllipsis from "../TextEllipsis";
import { SelectOption as SelectOptionType, SelectOptionBase } from "./types";
import styles from "./styles.module.css";
import SelectEmptyPlaceholder from "./EmptyPlaceholder";
import Box from "../Box";
import Icon from "../Icon";
import Typography from "../Typography";
import createSelectOptionRef from "./createSelectOptionRef";
import DropdownSection from "../Dropdown/Section";

const DEFAULT_PLACEHOLDER = "Please select";

type SelectRenderOption = SelectOptionBase & {
  onChange: (value: string, option?: SelectOptionType) => void;
  checked: boolean;
  closeSelect?: () => void;
  key: string;
};

type SelectProps<T extends SelectOptionBase> = {
  value?: T["value"];
  options: T[];
  renderValueNode?: (option: T) => ReactNode;
  renderOption?: (option: T & SelectRenderOption) => ReactNode;
  onChange: (value: T["value"], option?: SelectOptionType) => void;
  size?: "small" | "regular";
  error?: boolean;
  dropdownPosition?: DropdownPosition;
  disabled?: boolean;
  selectedValueClassName?: string;
  ariaInputProps?: AriaInputProps;
  placeholder?: string;
};

// eslint-disable-next-line spacelift/display-name
const DefaultRenderOption = ({
  value,
  label,
  postfix,
  checked,
  onChange,
  closeSelect,
}: SelectRenderOption) => {
  return (
    <SelectOption
      innerRef={createSelectOptionRef(checked)}
      key={value}
      value={value}
      postfix={postfix}
      label={label || value}
      checked={checked}
      onChange={onChange}
      closeSelect={closeSelect}
      highlightSelected
    />
  );
};

const Select = forwardRef(function Select<T extends SelectOptionBase>(
  props: SelectProps<T>,
  externalRef: React.ForwardedRef<HTMLInputElement>
) {
  const {
    onChange,
    value,
    renderValueNode,
    options,
    renderOption = DefaultRenderOption,
    size = "regular",
    error,
    dropdownPosition = "bottomLeft",
    disabled,
    selectedValueClassName,
    // placeholder, TODO: Seems unused in the select option, but used in instances of usage
    ariaInputProps,
  } = props;

  const wrapperRef = useRef(null);

  const [isVisible, setIsVisible] = useState(false);

  const handleToggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  const closeSelect = useCallback(() => {
    setIsVisible(false);
  }, []);

  const checkedOption = useMemo(() => {
    return options.find((option) => value === option.value);
  }, [options, value]);

  const currentSelected = useMemo(() => {
    if (renderValueNode && checkedOption) {
      return renderValueNode(checkedOption);
    }

    if (!checkedOption) {
      return DEFAULT_PLACEHOLDER;
    }

    return renderOptionLabel(checkedOption);
  }, [checkedOption, renderValueNode]);

  const filteredOptions = useMemo(() => {
    return options;
  }, [options]);

  useOutsideClick(wrapperRef, closeSelect);

  useEscapeKeypress(isVisible, closeSelect);

  const processOptions = useCallback(
    (optionsToProcess: T[]) =>
      optionsToProcess.map((option, index) =>
        renderOption({
          ...option,
          onChange,
          checked: option.value === value,
          closeSelect,
          index,
          key: option.value,
        })
      ),
    [renderOption, onChange, value, closeSelect]
  );

  return (
    <DropdownWrapper
      ref={wrapperRef}
      className={cx(styles.dropdown, styles[size], { [styles.error]: error })}
    >
      <div className={styles.selectActionWrapper}>
        <BaseAction
          id={ariaInputProps?.id}
          onClick={handleToggleVisibility}
          className={cx(styles.selectAction, selectedValueClassName)}
          disabled={disabled}
          ref={externalRef}
        >
          <TextEllipsis tooltip={currentSelected} tooltipWidthMode="maxWidthSm" delay={400}>
            {(ellipsisProps) => (
              <Typography
                {...ellipsisProps}
                variant="p-body3"
                tag="span"
                color={!checkedOption ? "placeholder" : undefined}
              >
                {currentSelected}
              </Typography>
            )}
          </TextEllipsis>
          <Box gap="small" align="center" className={styles.icons}>
            <Icon src={ChevronDown} />
          </Box>
        </BaseAction>
      </div>

      <DropdownList
        wrapperRef={wrapperRef}
        className={cx(styles.dropdownList)}
        active={isVisible}
        position={dropdownPosition}
      >
        {/* render option when visible to init tooltip properly */}
        {isVisible && (
          <div className={styles.dropdownInnerList}>
            {!!filteredOptions.length && (
              <DropdownSection>{processOptions(filteredOptions)}</DropdownSection>
            )}

            {/* render the empty placeholder when the result is empty */}
            {filteredOptions.length === 0 && (
              <DropdownSection>
                <SelectEmptyPlaceholder />
              </DropdownSection>
            )}
          </div>
        )}
      </DropdownList>
    </DropdownWrapper>
  );
});

Select.displayName = "DS.Select";

// casting to handle forwardRef with correct generic types
// https://stackoverflow.com/questions/58469229/react-with-typescript-generics-while-using-react-forwardref
export default Select as <T extends SelectOptionBase>(
  props: SelectProps<T> & { ref?: React.Ref<HTMLDivElement> }
) => ReactElement;
