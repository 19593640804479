import cx from "classnames";

import styles from "./styles.module.css";
import Box, { BoxProps } from "../Box";

const ListBoxScrollableWrapper = ({ className, ...props }: BoxProps) => (
  <Box className={cx(className, styles.scrollableWrapper)} direction="column" {...props} />
);

ListBoxScrollableWrapper.displayName = "DS.ListBox.ScrollableWrapper";

export default ListBoxScrollableWrapper;
